import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  CustomAlert,
  CustomButton,
  CustomCheckBox,
  Text,
} from "../../../shared/uiComponents";
import { ButtonWrapper } from "../../../components/StyledComponents";
import { AdminTypes } from "../../../components/Menu";

import { useSelector } from "../../../redux/store";

const ForcedOperation = ({ submit }: { submit: () => void }) => {
  const [errorList, setErrorList] = useState<Array<string>>([]);
  const [params, setParams] = useSearchParams();
  const error = useSelector((state) => state.soapNote.error);
  const role = useSelector((state) => state.account.role);

  const onClose = () => {
    params.delete("forcedOperation");
    setParams(params);
    setErrorList([]);
  };

  useEffect(() => {
    if (!error.errors) return;
    const errorList = Object.values(error.errors) as Array<string>;
    setErrorList(errorList);
  }, [error]);

  return (
    <CustomAlert
      open={!!errorList.length && AdminTypes.admin === role?.section.id}
      onClose={onClose}
      title="Are you sure?"
      Content={() => (
        <div>
          {errorList.map((x) => (
            <Text title={x} size="smallBold" className="marginBottom8" />
          ))}
          <CustomCheckBox
            item={{
              id: "0",
              label: "Ignore required fields",
              checked: params.get("forcedOperation") === "true" ? true : false,
            }}
            onChange={(event) => {
              const { checked } = event.target;
              params.set("forcedOperation", `${checked}`);
              setParams(params);
            }}
          />
          <ButtonWrapper>
            <CustomButton title="Cancel" onClick={onClose} />
            <CustomButton
              title="Confirm"
              secondaryButton
              onClick={submit}
              disabled={params.get("forcedOperation") === "true" ? false : true}
            />
          </ButtonWrapper>
        </div>
      )}
    />
  );
};

export default ForcedOperation;
