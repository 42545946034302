import { AddClientProperties, InfoClientProperties, UpdateClientInfoProperties } from "../../../redux/API/ClientAPIHelpers/clientProperties";

export const initialAddClientState: AddClientProperties = {
    firstName: "",
    lastName: "",
    nickName: "",
    dateOfBirthday: "",
    gender: "",
    email: "",
    secondaryEmail: "",
    secondaryNumber: "",
    parentPhoneNumber: "",
    address: "",
    city: "",
    zip: "",
    patronymic: "",
    status: 0,
    diagnosisCodeId: "",
    caseCoordinatorId: "",
    mbhId: "",
    insurances: [],
};

export const initialInfoClientState: InfoClientProperties = {
    firstName: "",
    lastName: "",
    nickName: "",
    dateOfBirthday: "",
    gender: "",
    email: "",
    secondaryEmail: "",
    secondaryNumber: "",
    parentPhoneNumber: "",
    address: "",
    city: "",
    zip: "",
    patronymic: "",
    status: {
        id: 0,
        name: "",
    },
    fullName: "",
    id: "",
    defaultBcba: {
        id: "",
        email: "",
        fullName: "",
        firstName: "",
        lastName: "",
        role: {
            id: '',
            name: "",
            section: {
                id: 0,
                name: ""
            }
        },
        authorizationType: {
            id: 0,
            name: "",
        },
    },
    caseCoordinator: {
        id: "",
        email: "",
        fullName: "",
        firstName: "",
        lastName: "",
        role: {
            id: '',
            name: "",
            section: {
                id: 0,
                name: ""
            }
        },
        authorizationType: {
            id: 0,
            name: "",
        },
    },
    insurances: [],
    diagnosisCode: {
        id: "",
        name: "",
    },
    numberOfTherapist: 0,
    mbhId: "",
};

export const initialUpdateClientState: UpdateClientInfoProperties = {
    id: "",
    firstName: "",
    lastName: "",
    nickName: "",
    dateOfBirthday: "",
    gender: "",
    email: "",
    secondaryEmail: "",
    secondaryNumber: "",
    parentPhoneNumber: "",
    address: "",
    city: "",
    zip: "",
    patronymic: "",
    status: 0,
    diagnosisCodeId: "",
    caseCoordinatorId: "",
    mbhId: "",
    insurances: [],
};
