import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { errorNormalizer } from "../../../shared/Helpers/functions";
import {
  ACTIONS,
  initialTreatmentPlaningState,
  treatmentPlaningReducer,
} from "./treatmentPlanningReducer";
import TreatmentPlanningData from "./treatmentPlanningData";
import { useResetCalendarData } from "../../Calendar/Helpers/hooks";
import SoapNoteButtons from "../components/soapNoteButtons";

import {
  getSoapNote,
  updateTreatmentPlanning,
} from "../../../redux/State/clientSlice/soapNoteSlice";
import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  setDate,
  setEndTime,
  setStartTime,
} from "../../../redux/State/clientSlice/sessionSlice";
import {
  TreatmentPlanningProperties,
  UpdateTreatmentPlanningProperties,
} from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const TreatmentPlanning = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const [data, setData] = useReducer(
    treatmentPlaningReducer,
    initialTreatmentPlaningState
  );

  const sessionInfo = useSelector((state) => state.session.sessionInfo);

  useEffect(() => {
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    const sessionId = params.get("sessionId");

    if (!reportId || !clientId || !sessionId) return;

    dispatch(getSoapNote({ reportId, clientId }))
      .then(unwrapResult)
      .then((response) => {
        const {
          participantResponses,
          treatmentUpdateResponses,
          location,
          date,
          startTime,
          endTime,
          userTelehealthLocation,
          clientTelehealthLocation,
        } = response as TreatmentPlanningProperties;
        const participants = participantResponses?.map((x) => x.id);
        const treatmentUpdates = treatmentUpdateResponses?.map((x) => x.id);

        dispatch(setDate(dayjs(date).format()));
        dispatch(setStartTime(dayjs(startTime).format()));
        dispatch(setEndTime(dayjs(endTime).format()));

        setData({
          type: ACTIONS.setState,
          payload: response,
        });

        setData({
          type: ACTIONS.setTreatmentUpdateResponses,
          payload: treatmentUpdates,
        });

        setData({
          type: ACTIONS.setParticipants,
          payload: participants,
        });

        if (!!location) {
          setData({
            type: ACTIONS.setLocationId,
            payload: location.id,
          });
        }
        if (!!clientTelehealthLocation) {
          setData({
            type: ACTIONS.setClientTelehealthLocationId,
            payload: clientTelehealthLocation.id,
          });
        }
        if (!!userTelehealthLocation) {
          setData({
            type: ACTIONS.setUserTelehealthLocationId,
            payload: userTelehealthLocation.id,
          });
        }
      })
      .catch(errorNormalizer);
  }, [dispatch, params]);

  const updateSoapNoteHandler = (submit: boolean) => {
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    const forcedOperation =
      params.get("forcedOperation") === "true" ? true : false;
    if (!reportId || !clientId || !data) return;
    const { date, startTime, endTime } = sessionInfo;
    const allData: UpdateTreatmentPlanningProperties = {
      ...data,
      date,
      startTime,
      endTime,
      reportId,
      submit,
      forcedOperation,
      clientId,
      sessionSummary: data.descriptionOfSession,
    };
    dispatch(updateTreatmentPlanning(allData))
      .then(unwrapResult)
      .then(() => {
        if (!submit) return;
        resetData();
        toast("Success");
        navigate("/calendar");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <TreatmentPlanningData
        setData={setData}
        data={data}
        save={() => updateSoapNoteHandler(false)}
      />
      <SoapNoteButtons
        save={() => updateSoapNoteHandler(false)}
        submit={() => updateSoapNoteHandler(true)}
      />
    </>
  );
};

export default TreatmentPlanning;
