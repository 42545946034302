import { ChangeEvent, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { CustomInput } from "../../shared/uiComponents";

const SearchBar = ({ label }: { label?: string }) => {
  const [params, setParams] = useSearchParams();
  const [startSearch, setStartSearch] = useState<string | null>(null);

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setStartSearch(value);
  };

  useEffect(() => {
    if (typeof startSearch !== "string") return;
    const timeout = setTimeout(() => {
      const previousSearchValue = params.get("search");
      if (previousSearchValue === startSearch) return;
      params.set("search", startSearch);
      params.set("page", "1");
      setParams(params);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [params, setParams, startSearch]);

  return (
    <CustomInput
      label={label}
      value={startSearch}
      setValue={onSearchChange}
      placeholder={"Search"}
    />
  );
};

export default SearchBar;
