import { styled } from "@mui/material";

import { Text } from "../../shared/uiComponents";
import { white100 } from "../../shared/Helpers/colors";
import Illuminate from "../../shared/Assets/logos/IlluminateLogoMain.png";
import { IMG } from "../Menu/styledComponents";
import { Loader } from "../StyledComponents";
import BCBAAuthorizationInfo from "./authorizationsInfoBCBA";
import AdminAuthorizationInfo from "./authorizationsInfoAdmin";
import { AdminTypes } from "../Menu";

import {  useSelector } from "../../redux/store";
// import PendingApproval from "../../pages/SOAPNotes/PendingApproval";

export const Container = styled("div")(() => ({
  backgroundColor: white100,
  width: "100%",
  padding: "24px",
  borderRadius: "12px",
  minWidth: "720px",
  maxWidth: "1440px",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  ">div": {
    width: "max-content",
  },
  "@media (max-width: 768px)": {
    minWidth: "220px",
    padding: "8px",
  },
}));

const Dashboard = () => {
  const loadingAccount = useSelector((state) => state.account.loading);
  const role = useSelector((state) => state.account.role);

  return (
    <Container>
      <Text title={"Welcome"} size="largeBold" className="marginTop16" />
      <IMG src={Illuminate} style={{ width: "40%" }} />
      {AdminTypes.bcba === role?.section.id && <BCBAAuthorizationInfo />}
      {AdminTypes.admin === role?.section.id && <AdminAuthorizationInfo />}
      {loadingAccount && <Loader />}
    </Container>
  );
};

export default Dashboard;
