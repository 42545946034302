import { Dispatch, ChangeEvent, useEffect, useReducer } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";

import {
  CustomInput,
  CustomSelect,
  MultipleSelect,
  Text,
} from "../../../shared/uiComponents";
import { validateName } from "../../../shared/Helpers/functions";
import { useIsTelehealth, useTreatmentPlanningOptions } from "../hook";
import {
  ACTIONS,
  ActionProperties,
  ERRORS,
  initialTreatmentPlaningErrorState,
  treatmentPlaningErrorReducer,
} from "./treatmentPlanningReducer";
import {
  SignatureInput,
  Targets,
  Behaviors,
  TimeInfo,
  TelehealthLocations,
} from "../components";
import { isActionAllowed } from "../helpers";

import { useSelector } from "../../../redux/store";
import { UpdateTreatmentPlanningProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const TreatmentPlanningData = ({
  data,
  setData,
  save,
}: {
  data: UpdateTreatmentPlanningProperties;
  setData: Dispatch<ActionProperties>;
  save: () => void;
}) => {
  const today = new Date();
  const todayString = dayjs(today).format("MM/DD/YYYY");

  const { participantsList, treatmentUpdatesList } =
    useTreatmentPlanningOptions();

  const isTelehealth = useIsTelehealth(data.locationId);

  const soapNoteStatus = useSelector(
    (state) => state.soapNote.soapNote?.status?.status
  );
  const disabled = !isActionAllowed(soapNoteStatus);

  const [errors, setErrors] = useReducer(
    treatmentPlaningErrorReducer,
    initialTreatmentPlaningErrorState
  );

  const locationList = useSelector((state) => state.soapNote.locations);
  const { user } = useSelector((state) => state.session.sessionInfo);
  const errorList = useSelector((state) => state.soapNote.error.errors);

  useEffect(() => {
    if (!!data.participantResponses.length) return;
    const payload = participantsList
      .filter((x) => x.isDefault)
      .map((y) => y.id);
    setData({ type: ACTIONS.setParticipants, payload });
  }, [participantsList, setData, data]);

  useEffect(() => {
    if (!errorList) return;
    const list = Object.keys(errorList);
    list.forEach((id) => setErrors({ type: ERRORS[id], payload: true }));
  }, [errorList]);

  const participantsHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setParticipants, payload: value });
    if (!errors.ParticipantResponses) return;
    setErrors({ payload: false, type: ERRORS.ParticipantResponses });
  };

  const locationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLocationId });
    if (!errors.LocationId) return;
    setErrors({ payload: false, type: ERRORS.LocationId });
  };

  const masteredGoalsAndObjectivesHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setMasteredGoalsAndObjectives, payload: value });
    if (!errors.MasteredGoalsAndObjectives) return;
    setErrors({ payload: false, type: ERRORS.MasteredGoalsAndObjectives });
  };

  const newGoalsAndObjectivesHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setNewGoalsAndObjectives, payload: value });
    if (!errors.NewGoalsAndObjectives) return;
    setErrors({ payload: false, type: ERRORS.NewGoalsAndObjectives });
  };

  const treatmentUpdateResponsesHandler = (
    event: SelectChangeEvent<string[]>
  ) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setTreatmentUpdateResponses, payload: value });
    if (!errors.TreatmentUpdateResponses) return;
    setErrors({ payload: false, type: ERRORS.TreatmentUpdateResponses });
  };

  const analysisOfGoalsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setAnalysisOfGoals, payload: value });
    if (!errors.AnalysisOfGoals) return;
    setErrors({ payload: false, type: ERRORS.AnalysisOfGoals });
  };

  const goalUpdatesHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setGoalUpdates, payload: value });
    if (!errors.GoalUpdates) return;
    setErrors({ payload: false, type: ERRORS.GoalUpdates });
  };

  const coordinationAndMaterialsPreppedHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({
      type: ACTIONS.setCoordinationAndMaterialsPrepped,
      payload: value,
    });
    if (!errors.CoordinationAndMaterialsPrepped) return;
    setErrors({ payload: false, type: ERRORS.CoordinationAndMaterialsPrepped });
  };

  const descriptionOfSessionHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({
      type: ACTIONS.setDescriptionOfSession,
      payload: value,
    });
    if (!errors.DescriptionOfSession) return;
    setErrors({ payload: false, type: ERRORS.DescriptionOfSession });
  };

  const signatureHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({
      type: ACTIONS.setSignature,
      payload: value.toUpperCase(),
    });
    if (!errors.Signature) return;
    setErrors({ payload: false, type: ERRORS.Signature });
  };

  const validateSignatureHandler = () => {
    if (validateName(data.signature)) {
      save();
      return;
    }
    setErrors({ payload: true, type: ERRORS.Signature });
  };

  const participantsOtherHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setParticipantsOther });
    if (!errors.TreatmentPlanningParticipantOther) return;
    setErrors({ payload: false, type: ERRORS.TreatmentPlanningParticipantOther });
  };

  const isOtherSelectedForParticipants = data.participantResponses.find(
    (x) => participantsList.find((y) => y.id === x)?.text === "Other"
  );

  const treatmentUpdateOtherHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setTreatmentUpdateOther });
    if (!errors.TreatmentUpdateOther) return;
    setErrors({ payload: false, type: ERRORS.TreatmentUpdateOther });
  };

  const isOtherSelectedForTreatmentUpdate = data.treatmentUpdateResponses.find(
    (x) => treatmentUpdatesList.find((y) => y.id === x)?.text === "Other"
  );

  return (
    <div>
      <TimeInfo disabled={disabled} />
      <CustomSelect
        label="Location:"
        data={locationList}
        value={!!data.locationId ? data.locationId : ""}
        setValue={locationHandler}
        className={"marginBottom16"}
        error={errors.LocationId}
        disabled={disabled}
        onBlur={save}
      />
      {isTelehealth && (
        <TelehealthLocations
          setData={setData}
          data={data}
          disabled={disabled}
          save={save}
        />
      )}
      <MultipleSelect
        label="Session Participants:"
        data={participantsList}
        initialValue={data.participantResponses}
        setValue={participantsHandler}
        error={errors.ParticipantResponses}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      {isOtherSelectedForParticipants && (
        <CustomInput
          label="If other, please describe: "
          value={data.treatmentPlanningParticipantOther}
          setValue={participantsOtherHandler}
          className="marginBottom16"
          multiline={{
            multiline: true,
            rowCount: 3,
          }}
          disabled={disabled}
          onBlur={save}
          error={errors.TreatmentPlanningParticipantOther}
        />
      )}
      <CustomInput
        label="Mastered goals and objectives:"
        value={data.masteredGoalsAndObjectives}
        setValue={masteredGoalsAndObjectivesHandler}
        error={errors.MasteredGoalsAndObjectives}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="New goals and objectives:"
        value={data.newGoalsAndObjectives}
        setValue={newGoalsAndObjectivesHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.NewGoalsAndObjectives}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      <MultipleSelect
        label="Updates to Treatment:"
        data={treatmentUpdatesList}
        initialValue={data.treatmentUpdateResponses}
        setValue={treatmentUpdateResponsesHandler}
        error={errors.TreatmentUpdateResponses}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      {isOtherSelectedForTreatmentUpdate && (
        <CustomInput
          label="If other, please describe: "
          value={data.treatmentUpdateOther}
          setValue={treatmentUpdateOtherHandler}
          className="marginBottom16"
          multiline={{
            multiline: true,
            rowCount: 3,
          }}
          disabled={disabled}
          onBlur={save}
          error={errors.TreatmentUpdateOther}
        />
      )}
      <CustomInput
        label="Analysis of goals reviewed:"
        value={data.analysisOfGoals}
        setValue={analysisOfGoalsHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.AnalysisOfGoals}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="Updates to goals:"
        value={data.goalUpdates}
        setValue={goalUpdatesHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        error={errors.GoalUpdates}
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="Coordination and materials prepped:"
        value={data.coordinationAndMaterialsPrepped}
        setValue={coordinationAndMaterialsPreppedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.CoordinationAndMaterialsPrepped}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      <Text
        title="Session Details"
        size="smallBold"
        className="marginBottom16 title"
      />
      <Targets />
      <Behaviors />
      <CustomInput
        label="Description of Summary: "
        value={data.descriptionOfSession}
        setValue={descriptionOfSessionHandler}
        error={errors.DescriptionOfSession}
        className="marginBottom8"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        disabled={disabled}
        onBlur={save}
      />
      <div style={{ width: "250px" }}>
        <CustomInput
          label="Printed Name:"
          value={`${user.fullName}`.toUpperCase()}
          setValue={() => {}}
          disabled={true}
          className="marginBottom8 signature"
        />
        <SignatureInput
          value={data.signature}
          setValue={signatureHandler}
          error={errors.Signature}
          disabled={disabled}
          onBlur={validateSignatureHandler}
        />
      </div>
      <div>
        <Text title="Date of signature: " size="smallBold" />
        <Text title={todayString} />
      </div>
    </div>
  );
};

export default TreatmentPlanningData;
