import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ImportExportOutlined as ImportExport,
  CloseOutlined as Close,
} from "@mui/icons-material/";

import Text from "../Text";
import { HeaderProperties } from "./tableProperties";

export const ColumnHeader = ({ item }: { item: HeaderProperties }) => {
  const { name, orderBy } = item;

  const [params, setParams] = useSearchParams();
  const [order, setOrder] = useState<boolean>(false);

  const handleSort = (orderBy: string) => {
    const direction = !order ? "asc" : "desc";
    params.set("direction", direction);
    params.set("orderBy", orderBy);
    setOrder((prev) => !prev);
    setParams(params);
  };

  return (
    <div className="nameTitle">
      <Text title={name} size="smallBold" style={{ wordBreak: "normal" }} />
      {!!orderBy ? (
        <ImportExport onClick={() => handleSort(orderBy)} />
      ) : (
        <div style={{ width: "24px" }} />
      )}
    </div>
  );
};

export const FilterColumnHeader = ({ item }: { item: HeaderProperties }) => {
  const { name, orderBy, titles, titlesHeader, statusKey = "status" } = item;

  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [params, setParams] = useSearchParams();
  const [order, setOrder] = useState<boolean>(false);

  const handleSort = (orderBy: string) => {
    const direction = !order ? "asc" : "desc";
    params.set("direction", direction);
    params.set("orderBy", orderBy);
    setOrder((prev) => !prev);
    setParams(params);
  };

  const selectStatusHandler = (name: string, id: number) => {
    params.set("page", "1");
    if (name.toLowerCase() === "all") {
      params.delete(statusKey);
      setTitle("");
      setParams(params);
      setOpen(false);
      return;
    }
    params.set(statusKey, `${id}`);
    setTitle(name);
    setOpen(false);
    setParams(params);
  };

  useEffect(() => {
    if (!params.get(statusKey)) setTitle("");
  }, [params, statusKey]);

  return (
    <div className="nameTitleFilter">
      <Text
        title={title || name}
        size="smallBold"
        onClick={() => setOpen(true)}
      />
      {!!orderBy ? (
        <ImportExport onClick={() => handleSort(orderBy)} />
      ) : (
        <div style={{ width: "24px" }} />
      )}
      <div
        className="filterWrapper"
        style={{
          display: open ? "block" : "none",
        }}
        onMouseLeave={() => setOpen(false)}
      >
        <div
          className="title marginBottom8"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {!!titlesHeader && <Text title={titlesHeader} size="smallBold" />}
          <Close onClick={() => setOpen(false)} />
        </div>
        {!!titles &&
          titles.map((name, key) => (
            <div key={key}>
              <Text
                title={name}
                size="smallBold"
                onClick={() => selectStatusHandler(name, key)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};
