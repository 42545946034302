import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  CustomButton,
  Text,
  CustomTable,
  CustomSelect,
} from "../../shared/uiComponents";
import { useCheckPermission } from "../../shared/Helpers/hooks";
import SearchBar from "../../components/SearchBar";
import { Container, Header } from "../../components/StyledComponents";
import { AdminTypes } from "../../components/Menu";
import { PERMISSIONS } from "../../App/constants";
import { TableBody, Headers } from "./tableInfo";
import MobileView from "./mobileView";

import { DispatchProperties, useSelector } from "../../redux/store";
import { getBTs } from "../../redux/State/userSlice";
import { SelectChangeEvent } from "@mui/material";
import { getCaseCoordinators } from "../../redux/State/clientSlice/userClientSlice";

const BT = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();
  const { permissionGranted } = useCheckPermission(PERMISSIONS.USER.CREATE);
  const [params, setParams] = useSearchParams();

  const usersData = useSelector((state) => state.user.bts);
  const loading = useSelector((state) => state.user.loading);
  const role = useSelector((state) => state.account.role);
  const caseCoordinators = useSelector(
    (state) => state.userClient.caseCoordinators
  );

  const data = !!caseCoordinators
    ? caseCoordinators.map((x) => ({ id: x.id, name: x.fullName }))
    : [];

  useEffect(() => {
    dispatch(getCaseCoordinators());
  }, [dispatch]);

  useEffect(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const caseCoordinatorId = params.get("caseCoordinatorId") || "";

    dispatch(
      getBTs({
        page,
        pageSize,
        orderBy,
        direction,
        searchString,
        caseCoordinatorId,
      })
    );
  }, [params, dispatch]);

  const addAdminHandler = () => {
    window.localStorage.setItem("formType", AdminTypes[2]);
    navigate("/bt/new");
  };

  const onCaseCoordinatorChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    params.set("caseCoordinatorId", value);
    setParams(params);
  };

  return (
    <Container>
      <Header>
        <Text title={"Behavior Technician"} size="mediumBold" />
      </Header>
      <Header>
        {role?.section.id === AdminTypes.admin && (
          <div
            style={{ maxWidth: "400px", width: "100%", marginBottom: "24px" }}
          >
            <CustomSelect
              label="Case Coordinator: "
              setValue={onCaseCoordinatorChange}
              value={params.get("caseCoordinatorId") ?? ""}
              data={[{ id: "", name: "None" }, ...data]}
            />
          </div>
        )}
        <Header>
          {permissionGranted && (
            <CustomButton
              title={"Add BT"}
              className={"marginRight16"}
              onClick={addAdminHandler}
            />
          )}
          <SearchBar />
        </Header>
      </Header>
      <CustomTable
        headers={Headers}
        data={usersData}
        TableBody={TableBody}
        loading={loading}
      />
      <MobileView {...usersData} />
    </Container>
  );
};

export default BT;
