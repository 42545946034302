import { FC, useState } from "react";
import { InfoOutlined as MoreInfo } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { CustomAlert } from "../../../../shared/uiComponents";
import { Info } from "../../User/tableInfo";

import { SoapNotesFullProperties } from "../../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const Details: FC<{ data: SoapNotesFullProperties }> = ({ data }) => {
  const [openInfo, setOpenInfo] = useState<boolean>(false);

  return (
    <>
      <Tooltip title="More Information">
        <MoreInfo fontSize="small" onClick={() => setOpenInfo(true)} />
      </Tooltip>

      <CustomAlert
        open={openInfo}
        onClose={() => setOpenInfo(false)}
        title={"More Information"}
        Content={() => <Info note={data} />}
      />
    </>
  );
};

export default Details;
