import dayjs from "dayjs";

import { CustomAccordion, Text } from "../../shared/uiComponents";
import { InfoRow as Row } from "../StyledComponents";

import { UserProperties } from "../../redux/API/userAPIProperties";
import { UserRegistrationInfoProperties } from "../../redux/API/identityAPIProperties";
import { CheckOutlined, CloseOutlined } from "@mui/icons-material";
import { AdminTypes } from "../Menu";

const UserInfo = ({
  accountInfo,
  userInfo,
}: {
  accountInfo: UserRegistrationInfoProperties;
  userInfo: UserProperties;
}) => {
  const {
    email,
    previousEmail,
    lastConfirmationEmailSendDate,
    lastEmailChangedDate,
    lastPasswordChangedDate,
  } = accountInfo;

  const {
    fullName,
    phoneNumber,
    address,
    authorizationType,
    createdBy,
    role,
    isCaseCoordinator,
    mbhId,
  } = userInfo;

  return (
    <CustomAccordion
      headerStyle={{ padding: 0 }}
      HeaderContent={() => (
        <Row>
          <Text size={"smallBold"} title={`Name:`} />
          <Text title={fullName} />
        </Row>
      )}
      Content={() => (
        <>
          {!!phoneNumber && (
            <Row>
              <Text title={"Phone Number: "} size={"smallBold"} />
              <Text title={phoneNumber} />
            </Row>
          )}
          <Row>
            <Text title={"Email: "} size={"smallBold"} />
            <Text title={email} />
          </Row>
          {!!previousEmail && (
            <Row>
              <Text title={"Previous Email: "} size={"smallBold"} />
              <Text title={previousEmail} />
            </Row>
          )}
          {!!lastEmailChangedDate && (
            <Row>
              <Text title={"Last Email Change Date: "} size={"smallBold"} />
              <Text
                title={dayjs(lastEmailChangedDate).format("MM/DD/YYYY hh:mm A")}
              />
            </Row>
          )}
          <Row>
            <Text title={"Address: "} size={"smallBold"} />
            <Text title={address} />
          </Row>
          {authorizationType?.id === 1 && (
            <Row>
              <Text title={"Authorization Type: "} size={"smallBold"} />
              <Text title={authorizationType?.name ?? "-"} />
            </Row>
          )}
          <Row>
            <Text title={"Role: "} size={"smallBold"} />
            <Text title={role.name} />
          </Row>
          <Row>
            <Text title={"Section: "} size={"smallBold"} />
            <Text title={role.section.name} />
          </Row>
          <Row>
            <Text title={"Created by: "} size={"smallBold"} />
            <Text title={createdBy.fullName} />
          </Row>
          {lastConfirmationEmailSendDate && (
            <Row>
              <Text title={"Recent Resend Date:"} size={"smallBold"} />
              <Text
                title={dayjs(lastConfirmationEmailSendDate).format(
                  "MM/DD/YYYY hh:mm A"
                )}
              />
            </Row>
          )}
          {lastPasswordChangedDate && (
            <Row>
              <Text title={"Last Password Change Date:"} size={"smallBold"} />
              <Text
                title={dayjs(lastPasswordChangedDate).format("MM/DD/YYYY")}
              />
            </Row>
          )}
          {role?.section.id === AdminTypes.admin && (
            <Row>
              <Text title={"Case Coordinator:"} size={"smallBold"} />
              <div>
                {isCaseCoordinator ? (
                  <CheckOutlined fontSize="small" />
                ) : (
                  <CloseOutlined fontSize="small" />
                )}
              </div>
            </Row>
          )}
          <Row>
            <Text title={"MBH Id: "} size={"smallBold"} />
            <Text title={mbhId} />
          </Row>
        </>
      )}
    />
  );
};

export default UserInfo;
