import { useEffect, useReducer } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { errorNormalizer } from "../../../shared/Helpers/functions";
import AssessmentData from "./assessmentData";
import {
  ACTIONS,
  assessmentReducer,
  initialAssessmentState,
} from "./assessmentReducer";
import { useResetCalendarData } from "../../Calendar/Helpers/hooks";
import SoapNoteButtons from "../components/soapNoteButtons";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getSoapNote,
  updateAssessment,
} from "../../../redux/State/clientSlice/soapNoteSlice";
import {
  setDate,
  setEndTime,
  setStartTime,
} from "../../../redux/State/clientSlice/sessionSlice";
import {
  AssessmentProperties,
  UpdateAssessmentProperties,
} from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const Assessment = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const sessionInfo = useSelector((state) => state.session.sessionInfo);

  const [data, setData] = useReducer(assessmentReducer, initialAssessmentState);

  useEffect(() => {
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    const sessionId = params.get("sessionId");
    if (!reportId || !clientId || !sessionId) return;

    dispatch(getSoapNote({ reportId, clientId }))
      .then(unwrapResult)
      .then((response) => {
        const {
          assessmentToolResponses,
          participantResponses,
          treatmentPlanResponses,
          assessmentActivityResponses,
          location,
          date,
          startTime,
          endTime,
          clientTelehealthLocation,
          userTelehealthLocation,
        } = response as AssessmentProperties;
        const assessmentTools = assessmentToolResponses?.map((x) => x.id);
        const assessmentActivities = assessmentActivityResponses?.map(
          (x) => x.id
        );
        const participants = participantResponses?.map((x) => x.id);
        const treatmentPlans = treatmentPlanResponses?.map((x) => x.id);

        dispatch(setDate(dayjs(date).format()));
        dispatch(setStartTime(dayjs(startTime).format()));
        dispatch(setEndTime(dayjs(endTime).format()));

        setData({
          type: ACTIONS.setState,
          payload: response,
        });

        setData({
          type: ACTIONS.setAssessmentTools,
          payload: assessmentTools,
        });

        setData({
          type: ACTIONS.setAssessmentActivities,
          payload: assessmentActivities,
        });

        setData({
          type: ACTIONS.setParticipants,
          payload: participants,
        });

        setData({
          type: ACTIONS.setTreatmentPlans,
          payload: treatmentPlans,
        });

        if (!!location) {
          setData({
            type: ACTIONS.setLocationId,
            payload: location.id,
          });
        }
        if (!!clientTelehealthLocation) {
          setData({
            type: ACTIONS.setClientTelehealthLocationId,
            payload: clientTelehealthLocation.id,
          });
        }
        if (!!userTelehealthLocation) {
          setData({
            type: ACTIONS.setUserTelehealthLocationId,
            payload: userTelehealthLocation.id,
          });
        }
      })
      .catch(errorNormalizer);
  }, [dispatch, params]);

  const updateSoapNoteHandler = (submit: boolean) => {
    const forcedOperation =
      params.get("forcedOperation") === "true" ? true : false;
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");

    if (!reportId || !clientId || !data) return;
    const { date, startTime, endTime } = sessionInfo;
    const allData: UpdateAssessmentProperties = {
      ...data,
      date,
      startTime,
      endTime,
      reportId,
      clientId,
      submit,
      forcedOperation,
    };
    dispatch(updateAssessment(allData))
      .then(unwrapResult)
      .then(() => {
        if (!submit) return;
        resetData();
        toast("Success");
        navigate("/calendar");
      })
      .catch(errorNormalizer);
  };

  return (
    <>
      <AssessmentData
        setData={setData}
        data={data}
        save={() => updateSoapNoteHandler(false)}
      />
      <SoapNoteButtons
        save={() => updateSoapNoteHandler(false)}
        submit={() => updateSoapNoteHandler(true)}
      />
    </>
  );
};

export default Assessment;
