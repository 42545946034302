import { FetchAPI } from "../Main/fetchAPI";
import {
    AdminCreateEventProperties,
    CopyEventsProperties,
    CreateEventProperties,
    DeleteEventsProperties,
    DeleteUpcomingEventsProperties,
    DeleteUserEventProperties,
    EventProperties,
    GetClientEventsProperties,
    GetUserEventsProperties,
} from "./calendarProperties";
import { CALENDAR, CALENDAR_EVENTS, CLIENTS, CLIENT_USERS } from "../constants";

export const Calendar = {
    createEvent: (data: CreateEventProperties) =>
        FetchAPI.postJson({
            urlStr: `${CALENDAR_EVENTS}`,
            data,
        }),
    adminCreateEvent: (data: AdminCreateEventProperties) => {
        const {
            clientId,
            date,
            endTime,
            sessionTypeId,
            startTime,
            userId,
            expirationDate,
        } = data;
        const query = new URLSearchParams();
        if (!!clientId) query.set('clientId', clientId);
        if (!!userId) query.set('userId', userId);
        if (!!date) query.set('date', date);
        if (!!sessionTypeId) query.set('sessionTypeId', `${sessionTypeId}`);
        if (!!startTime) query.set('startTime', startTime);
        if (!!endTime) query.set('endTime', endTime);
        if (!!expirationDate) query.set('expirationDate', expirationDate);

        return FetchAPI.postJson({
            urlStr: `${CALENDAR}/backdate-event?${query.toString()}`,
        })
    },
    copyEvents: (data: CopyEventsProperties) =>
        FetchAPI.postJson({
            urlStr: `${CALENDAR_EVENTS}/${data.startDate}/${data.endDate}/copy`,
            data,
        }),
    getAllEvents: ({ startDate, endDate, includeBTEvents = false }: EventProperties) => {
        if (!startDate || !endDate)
            return FetchAPI.getJson({
                urlStr: `${CALENDAR_EVENTS}`,
            });

        const query = new URLSearchParams();
        query.set('startDate', startDate);
        query.set('endDate', endDate);
        query.set('includeRbtEvents', `${includeBTEvents}`);
        return FetchAPI.getJson({
            urlStr: `${CALENDAR_EVENTS}?${query.toString()}`,
        });
    },
    getUserEvents: ({ userId, startDate, endDate }: GetUserEventsProperties) =>
        FetchAPI.getJson({
            urlStr: `${CLIENT_USERS}/${userId}/calendar/events?startDate=${startDate}&endDate=${endDate}`,
        }),
    getClientEvents: ({
        clientId,
        startDate,
        endDate,
    }: GetClientEventsProperties) =>
        FetchAPI.getJson({
            urlStr: `${CLIENTS}/${clientId}/calendar/events?startDate=${startDate}&endDate=${endDate}`,
        }),
    deleteEvent: (eventId: string) =>
        FetchAPI.deleteJson({
            urlStr: `${CALENDAR_EVENTS}/${eventId}`,
        }),
    deleteUpcomingEvents: ({
        clientId,
        startDate,
    }: DeleteUpcomingEventsProperties) =>
        FetchAPI.deleteJson({
            urlStr: `${CLIENTS}/${clientId}/calendar/events/${startDate}`,
        }),
    deleteEvents: ({ startDate, endDate }: DeleteEventsProperties) =>
        FetchAPI.deleteJson({
            urlStr: `${CALENDAR_EVENTS}/${startDate}/${endDate}`,
        }),
    deleteUserEvent: ({ userId, eventId }: DeleteUserEventProperties) =>
        FetchAPI.deleteJson({
            urlStr: `${CLIENT_USERS}/${userId}/calendar/events/${eventId}`,
        }),
}