import { ChangeEvent, useEffect, useState, FC, Dispatch } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material/Select";
import dayjs, { Dayjs } from "dayjs";
import { Value } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import {
  Text,
  CustomInput,
  CustomButton,
  CustomSelect,
  Picker,
  CustomRadio,
  CustomPhoneInput,
} from "../../../shared/uiComponents";
import {
  capitalizer,
  dateNormalizer,
  validateEmail,
  validateName,
} from "../../../shared/Helpers/functions";
import AddressInput from "../../../shared/uiComponents/Input/addressInput";
import {
  AdminContainer as Container,
  InputWrapper,
  Loader,
} from "../../../components/StyledComponents";
import { ACTIONS, ActionProperties } from "./clientReducer";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getDiagnosisCodes,
  getInsurances,
  getStatuses,
} from "../../../redux/State/clientSlice/clientSlice";
import {
  AddClientProperties,
  UpdateClientInfoProperties,
} from "../../../redux/API/ClientAPIHelpers/clientProperties";
import { getCaseCoordinators } from "../../../redux/State/clientSlice/userClientSlice";
import { InsuranceAuthorizationProperties } from "../../../redux/API/ClientAPIHelpers/insuranceAuthorizationProperties";

const genderOptions = [
  { id: "male", name: "Male" },
  { id: "female", name: "Female" },
  { id: "other", name: "Other" },
];

interface ClientFormProperties {
  clientInfo: AddClientProperties | UpdateClientInfoProperties;
  setClientInfo: Dispatch<ActionProperties>;
  saveClientHandler: () => void;
}

interface ErrorProperties {
  firstName: string;
  lastName: string;
  email: string;
  parentPhoneNumber: string;
  dateOfBirthday: string;
  gender: string;
  insurance: string;
}

interface ValidationErrorProperties {
  nickName: string;
  secondaryEmail: string;
  secondaryNumber: string;
}

const ClientForm: FC<ClientFormProperties> = ({
  clientInfo,
  setClientInfo,
  saveClientHandler,
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const [dateValue, setDateValue] = useState<Dayjs | null>(null);
  const [error, setError] = useState<ErrorProperties>({
    firstName: "",
    lastName: "",
    email: "",
    parentPhoneNumber: "",
    dateOfBirthday: "",
    gender: "",
    insurance: "",
  });

  const [validationError, setValidationError] =
    useState<ValidationErrorProperties>({
      nickName: "",
      secondaryEmail: "",
      secondaryNumber: "",
    });

  const [disableSave, setDisableSave] = useState<boolean>(true);

  const loading = useSelector((state) => state.client.loading);
  const insurances = useSelector((state) => state.client.insurances);
  const statuses = useSelector((state) => state.client.statuses);
  const diagnosisCodes = useSelector((state) => state.client.diagnosisCodes);
  const caseCoordinators = useSelector(
    (state) => state.userClient.caseCoordinators
  );

  useEffect(() => {
    dispatch(getInsurances());
    dispatch(getStatuses());
    dispatch(getDiagnosisCodes());
    dispatch(getCaseCoordinators());
  }, [dispatch]);

  useEffect(() => {
    if (!!clientInfo.email && !validateEmail(clientInfo.email)) {
      setError((prev) => ({ ...prev, email: "Invalid email" }));
    } else {
      setError((prev) => ({ ...prev, email: "" }));
    }
    if (
      !!clientInfo.secondaryEmail &&
      !validateEmail(clientInfo.secondaryEmail)
    ) {
      setValidationError((prev) => ({
        ...prev,
        secondaryEmail: "Invalid email",
      }));
    } else {
      setValidationError((prev) => ({ ...prev, secondaryEmail: "" }));
    }
  }, [clientInfo]);

  useEffect(() => {
    if (!diagnosisCodes) return;
    const defaultCode = diagnosisCodes.find((x) => x.isDefault);
    if (!defaultCode) return;
    setClientInfo({
      type: ACTIONS.setDiagnosisCodeId,
      payload: defaultCode.id,
    });
  }, [diagnosisCodes, setClientInfo]);

  useEffect(() => {
    if (!clientInfo.dateOfBirthday) return;
    setDateValue(dayjs(clientInfo.dateOfBirthday));
  }, [clientInfo.dateOfBirthday]);

  useEffect(() => {
    if (!clientInfo.dateOfBirthday) return;
    setError((prev) => ({ ...prev, dateOfBirthday: "" }));
  }, [clientInfo.dateOfBirthday]);

  useEffect(() => {
    const {
      firstName,
      lastName,
      dateOfBirthday,
      gender,
      email,
      parentPhoneNumber,
    } = clientInfo;

    const values = [
      firstName,
      lastName,
      dateOfBirthday,
      gender,
      email,
      parentPhoneNumber,
    ];
    setDisableSave(
      Object.values(error).some((x) => !!x) || values.some((x) => !x)
    );
  }, [error, clientInfo]);

  const onFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setClientInfo({
      type: ACTIONS.setFirstName,
      payload: capitalizer(value),
    });
    setError((prev) => ({ ...prev, firstName: "" }));
  };

  const onLastNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setClientInfo({
      type: ACTIONS.setLastName,
      payload: capitalizer(value),
    });
    setError((prev) => ({ ...prev, lastName: "" }));
  };

  const onNickNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setClientInfo({
      type: ACTIONS.setNickName,
      payload: capitalizer(value),
    });
    setValidationError((prev) => ({ ...prev, nickName: "" }));
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setClientInfo({ type: ACTIONS.setEmail, payload: value });
    setError((prev) => ({ ...prev, email: "" }));
  };

  const onSecondaryEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setClientInfo({ type: ACTIONS.setSecondaryEmail, payload: value });
    setValidationError((prev) => ({ ...prev, secondaryEmail: "" }));
  };

  const onPhoneNumberChange = (value: Value) => {
    setClientInfo({ type: ACTIONS.setParentPhoneNumber, payload: value });
    setError((prev) => ({ ...prev, parentPhoneNumber: "" }));
  };

  const onSecondaryPhoneNumberChange = (value: Value) => {
    setClientInfo({ type: ACTIONS.setSecondaryPhoneNumber, payload: value });
    setValidationError((prev) => ({ ...prev, secondaryNumber: "" }));
  };

  const onDateOfBirthChange = (value: Dayjs | null) => {
    if (!value) return;
    setDateValue(value);
    if (!dayjs(value).isValid()) return;
    const payload = dateNormalizer(value);
    setClientInfo({ type: ACTIONS.setDateOfBirth, payload });
    setError((prev) => ({ ...prev, dateOfBirthday: "" }));
  };

  const onGenderChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setClientInfo({ type: ACTIONS.setGender, payload: value });
    setError((prev) => ({ ...prev, gender: "" }));
  };

  const onAddressChange = ({
    fullAddress,
    city,
    zipcode,
  }: {
    fullAddress?: string;
    zipcode?: string;
    city?: string;
  }) => {
    setClientInfo({ type: ACTIONS.setAddress, payload: fullAddress });
    setClientInfo({ type: ACTIONS.setCity, payload: city });
    setClientInfo({ type: ACTIONS.setZip, payload: zipcode });
    setError((prev) => ({ ...prev, address: "" }));
  };

  const onCityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setClientInfo({ type: ACTIONS.setCity, payload: value });
  };

  const onZipChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setClientInfo({ type: ACTIONS.setZip, payload: value });
  };

  const onStatusChange = (event: SelectChangeEvent<string>) => {
    const value = parseInt(event.target.value);
    setClientInfo({ type: ACTIONS.setStatus, payload: value });
  };

  const onPrimaryInsuranceChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    if (!value) return;
    const payload = !!clientInfo.insurances?.length
      ? clientInfo.insurances.map((insurance) => {
          if (insurance.type === 2) return insurance;
          return {
            ...insurance,
            insuranceId: value,
          };
        })
      : [
          {
            insuranceId: value,
            type: 1,
            insuranceNumber: "",
          } as InsuranceAuthorizationProperties,
        ];
    setClientInfo({ type: ACTIONS.setInsurances, payload });
    setError((prev) => ({ ...prev, insuranceId: "" }));
  };

  const onPrimaryInsuranceNumberChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const payload = clientInfo.insurances.map((insurance) => {
      if (insurance.type === 2) return insurance;
      return {
        ...insurance,
        insuranceNumber: value,
      };
    });
    setClientInfo({ type: ACTIONS.setInsurances, payload });
  };

  const onSecondaryInsuranceChange = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    const insurances = clientInfo.insurances;

    if (!value) {
      setClientInfo({
        type: ACTIONS.setInsurances,
        payload: insurances.filter((x) => x.type === 1),
      });
      return;
    }
    if (!insurances.length) return;

    const payload =
      insurances.length > 1
        ? insurances.map((insurance) => {
            if (insurance.type === 1) return insurance;
            return {
              ...insurance,
              insuranceId: value,
            };
          })
        : [
            ...insurances,
            {
              insuranceId: value,
              type: 2,
              insuranceNumber: "",
            } as InsuranceAuthorizationProperties,
          ];
    setClientInfo({ type: ACTIONS.setInsurances, payload });
    // setError((prev) => ({ ...prev, insuranceId: "" }));
  };

  const onSecondaryInsuranceNumberChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    const payload = clientInfo.insurances.map((insurance) => {
      if (insurance.type === 1) return insurance;
      return {
        ...insurance,
        insuranceNumber: value,
      };
    });
    setClientInfo({ type: ACTIONS.setInsurances, payload });
  };

  const onDiagnosisCodeIdChange = (event: SelectChangeEvent<string>) => {
    const payload = event.target.value;
    if (!payload) return;
    setClientInfo({ type: ACTIONS.setDiagnosisCodeId, payload });
  };

  const onCaseCoordinatorChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    if (!value) return;
    setClientInfo({ type: ACTIONS.setCaseCoordinatorId, payload: value });
  };

  const onMBHIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setClientInfo({ type: ACTIONS.setMBHId, payload: value });
  };

  const onFirstNameBlurHandler = () => {
    if (!!clientInfo.firstName && validateName(clientInfo.firstName)) return;
    if (!clientInfo.firstName) {
      setError((prev) => ({ ...prev, firstName: "Required" }));
      return;
    }
    setError((prev) => ({ ...prev, firstName: "Invalid name" }));
  };

  const onLastNameBlurHandler = () => {
    if (!!clientInfo.lastName && validateName(clientInfo.lastName)) return;
    if (!clientInfo.lastName) {
      setError((prev) => ({ ...prev, lastName: "Required" }));
      return;
    }
    setError((prev) => ({ ...prev, lastName: "Invalid name" }));
  };

  const onNickNameBlurHandler = () => {
    if (validateName(clientInfo.firstName)) return;
    setValidationError((prev) => ({ ...prev, nickName: "Invalid name" }));
  };

  const onEmailBlurHandler = () => {
    if (!!clientInfo.email) return;
    setError((prev) => ({ ...prev, email: "Required" }));
  };

  const onPhoneNumberBlurHandler = () => {
    const { parentPhoneNumber } = clientInfo;
    if (!parentPhoneNumber) {
      setError((prev) => ({ ...prev, parentPhoneNumber: "Required" }));
      return;
    }
    if (!isPossiblePhoneNumber(parentPhoneNumber)) {
      setError((prev) => ({ ...prev, phoneNumber: "Invalid phone number" }));
      return;
    }
  };

  const onSecondaryNumberBlurHandler = () => {
    const { secondaryNumber } = clientInfo;
    if (!isPossiblePhoneNumber(secondaryNumber))
      setValidationError((prev) => ({
        ...prev,
        secondaryNumber: "Invalid phone number",
      }));
  };

  const onDateOfBirthdayBlurHandler = () => {
    if (!!clientInfo.dateOfBirthday) return;
    setError((prev) => ({ ...prev, dateOfBirthday: "Required" }));
  };

  const onGenderBlurHandler = () => {
    if (!!clientInfo.gender) return;
    setError((prev) => ({ ...prev, gender: "Required" }));
  };

  const onInsuranceBlurHandler = () => {
    if (!!clientInfo.insurances?.find((x) => x.type === 1)) return;
    setError((prev) => ({ ...prev, insurance: "Required" }));
  };

  const primaryInsurance = clientInfo.insurances?.find((x) => x.type === 1);
  const primaryInsuranceId = !!primaryInsurance
    ? primaryInsurance.insuranceId
    : "";
  const primaryInsuranceNumber = !!primaryInsurance
    ? primaryInsurance.insuranceNumber
    : "";

  const secondaryInsurance = clientInfo.insurances?.find((x) => x.type === 2);
  const secondaryInsuranceId = !!secondaryInsurance
    ? secondaryInsurance.insuranceId
    : "";
  const secondaryInsuranceNumber = !!secondaryInsurance
    ? secondaryInsurance.insuranceNumber
    : "";

  return !loading ? (
    <Container>
      <Text
        title={"Client Information"}
        size={"mediumBold"}
        className={"marginBottom16"}
      />
      <InputWrapper>
        <CustomInput
          label={"First Name"}
          value={clientInfo.firstName}
          setValue={onFirstNameChange}
          onBlur={onFirstNameBlurHandler}
          error={!!error.firstName}
          errorMessage={error.firstName}
        />
        <CustomInput
          label={"Last Name"}
          value={clientInfo.lastName}
          setValue={onLastNameChange}
          onBlur={onLastNameBlurHandler}
          error={!!error.lastName}
          errorMessage={error.lastName}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomInput
          label={"Nickname"}
          value={clientInfo.nickName ?? ""}
          setValue={onNickNameChange}
          onBlur={onNickNameBlurHandler}
          error={!!validationError.nickName}
          errorMessage={validationError.nickName}
        />
        <CustomSelect
          data={genderOptions}
          label={"Gender"}
          value={clientInfo.gender}
          setValue={onGenderChange}
          onBlur={onGenderBlurHandler}
          error={!!error.gender}
          errorMessage={error.gender}
        />
      </InputWrapper>

      <InputWrapper>
        <CustomInput
          label={"Email"}
          value={clientInfo.email}
          setValue={onEmailChange}
          onBlur={onEmailBlurHandler}
          error={!!error.email}
          errorMessage={error.email}
        />
        <CustomInput
          label={"Secondary Email"}
          value={clientInfo.secondaryEmail}
          setValue={onSecondaryEmailChange}
          error={!!validationError.secondaryEmail}
          errorMessage={validationError.secondaryEmail}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomPhoneInput
          label={"Parent's Phone"}
          value={clientInfo.parentPhoneNumber}
          setValue={onPhoneNumberChange}
          onBlur={onPhoneNumberBlurHandler}
          error={!!error.parentPhoneNumber}
          errorMessage={error.parentPhoneNumber}
        />
        <CustomPhoneInput
          label={"Secondary Phone"}
          value={clientInfo.secondaryNumber}
          setValue={onSecondaryPhoneNumberChange}
          onBlur={onSecondaryNumberBlurHandler}
          error={!!validationError.secondaryNumber}
          errorMessage={validationError.secondaryNumber}
        />
      </InputWrapper>
      <InputWrapper>
        <Picker.CustomDate
          label={"Date of birth"}
          value={dateValue}
          onChange={onDateOfBirthChange}
          onBlur={onDateOfBirthdayBlurHandler}
          disableFuture={true}
          error={!!error.dateOfBirthday}
          errorMessage={error.dateOfBirthday}
        />
        <CustomSelect
          label={"Case Coordinator"}
          className={"marginBottom16"}
          data={
            !!caseCoordinators
              ? caseCoordinators.map((x) => ({ id: x.id, name: x.fullName }))
              : []
          }
          value={clientInfo.caseCoordinatorId}
          setValue={onCaseCoordinatorChange}
        />
      </InputWrapper>
      <Text
        title={"Address"}
        size={"mediumBold"}
        className={"marginBottom16 marginTop16"}
      />
      <AddressInput
        setAddressDetails={onAddressChange}
        value={clientInfo.address}
      />
      <InputWrapper>
        <CustomInput
          label={"City"}
          value={clientInfo.city}
          setValue={onCityChange}
        />
        <CustomInput
          label={"ZIP"}
          value={clientInfo.zip}
          setValue={onZipChange}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomSelect
          label={"Primary Insurance"}
          className={"marginBottom16"}
          data={insurances}
          value={primaryInsuranceId}
          setValue={onPrimaryInsuranceChange}
          onBlur={onInsuranceBlurHandler}
          error={!!error.insurance}
          errorMessage={error.insurance}
        />
        <CustomInput
          label={"Primary Member ID"}
          value={primaryInsuranceNumber}
          setValue={onPrimaryInsuranceNumberChange}
          className={"marginBottom16"}
          disabled={!primaryInsuranceId}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomSelect
          label={"Secondary Insurance"}
          className={"marginBottom16"}
          data={[...insurances, { id: "", name: "Clear Insurance" }]}
          value={secondaryInsuranceId}
          setValue={onSecondaryInsuranceChange}
          disabled={!primaryInsuranceId}
        />
        <CustomInput
          label={"Secondary Member ID"}
          value={secondaryInsuranceNumber}
          setValue={onSecondaryInsuranceNumberChange}
          className={"marginBottom16"}
          disabled={!secondaryInsuranceId}
        />
      </InputWrapper>
      <InputWrapper>
        <CustomInput
          label={"MBH ID"}
          value={clientInfo.mbhId}
          setValue={onMBHIdChange}
          className={"marginBottom16"}
        />
      </InputWrapper>
      {!!clientId && (
        <CustomSelect
          label={"Status"}
          className={"marginBottom16"}
          data={statuses}
          value={clientInfo.status.toString()}
          setValue={onStatusChange}
        />
      )}
      <div
        style={{
          display: "flex",
          gap: "16px",
          flexWrap: "wrap",
          marginBottom: "16px",
        }}
      >
        {!!diagnosisCodes &&
          diagnosisCodes.map((code) => (
            <CustomRadio
              id={code.id}
              value={clientInfo.diagnosisCodeId}
              label={code.name}
              onChange={onDiagnosisCodeIdChange}
            />
          ))}
      </div>
      <CustomButton
        title={"Save"}
        onClick={saveClientHandler}
        disabled={disableSave}
        className={"marginBottom16"}
      />
    </Container>
  ) : (
    <Loader />
  );
};

export default ClientForm;
