import { FC } from "react";
import { useDispatch } from "react-redux";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import {
  Text,
  TableNoData,
  UserStatusBadge,
  UserEmailStatusBadge,
} from "../../shared/uiComponents";
import {
  HeaderProperties,
  TableDataProperties,
} from "../../shared/uiComponents/Table/tableProperties";
import UserAction from "../../components/UserAction";

import { UserProperties } from "../../redux/API/userAPIProperties";
import { DispatchProperties } from "../../redux/store";
import { getBTs } from "../../redux/State/userSlice";

export interface AdminDataProperties extends TableDataProperties {
  query: Array<UserProperties> | null;
}

interface RowRendererProperties {
  data: UserProperties[];
}

export const Headers: HeaderProperties[] = [
  { id: "0", name: "Full Name", orderBy: "fullName" },
  {
    id: "1",
    name: "Number of Clients",
    orderBy: "numberOfClients",
    width: "200px",
  },
  { id: "2", name: "Email Status", orderBy: "emailConfirmed", width: "150px" },
  { id: "3", name: "Status", orderBy: "emailConfirmed", width: "100px" },
  { id: "4", name: "Role" },
  { id: "5", name: "Case Coordinator" },
  { id: "6", name: "Actions", width: "120px" },
];

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const fetchUsers = () => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";

    dispatch(getBTs({ page, pageSize, orderBy, direction, searchString }));
  };

  if (!data || !data.length) {
    return <TableNoData spanScope={Headers.length} />;
  }

  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Text title={row.fullName} size={"tiny"} />
          </TableCell>
          <TableCell>
            <Text title={row.numberOfClients.toString()} size={"tiny"} />
          </TableCell>
          <TableCell>
            <UserEmailStatusBadge confirmed={row.emailConfirmed} />
          </TableCell>
          <TableCell>
            <UserStatusBadge active={!row.isDeactivated} />
          </TableCell>
          <TableCell>
            <Text title={row.role.name} size={"tiny"} />
          </TableCell>
          <TableCell>
            {!!row.caseCoordinators && !!row.caseCoordinators.length
              ? row.caseCoordinators.map((x) => (
                  <Text title={x?.fullName} size={"tiny"} />
                ))
              : "-"}
          </TableCell>
          <TableCell>
            <UserAction item={row} fetchUsers={fetchUsers} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
