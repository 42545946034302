import { ChangeEvent, Dispatch, useEffect, useReducer } from "react";
import { SelectChangeEvent } from "@mui/material/Select";
import dayjs from "dayjs";

import {
  CustomSelect,
  Text,
  CustomCheckBox,
  MultipleSelect,
  CustomInput,
} from "../../../shared/uiComponents";
import { validateName } from "../../../shared/Helpers/functions";
import { useIsTelehealth, useParentTrainingOptions } from "../hook";
import {
  ACTIONS,
  ActionProperties,
  ERRORS,
  initialParentTrainingErrorState,
  parentTrainingErrorReducer,
} from "./parentTrainingReducer";
import {
  SignatureInput,
  Targets,
  Behaviors,
  TimeInfo,
  TelehealthLocations,
} from "../components";
import { isActionAllowed } from "../helpers";

import { useSelector } from "../../../redux/store";
import { UpdateParentTrainingProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const ParentTrainingData = ({
  data,
  setData,
  save,
}: {
  data: UpdateParentTrainingProperties;
  setData: Dispatch<ActionProperties>;
  save: () => void;
}) => {
  const today = new Date();
  const todayString = dayjs(today).format("MM/DD/YYYY");

  const { directParentTrainingList, peopleInAttendanceList } =
    useParentTrainingOptions();

  const { user } = useSelector((state) => state.session.sessionInfo);
  const isTelehealth = useIsTelehealth(data.locationId);

  const soapNoteStatus = useSelector(
    (state) => state.soapNote.soapNote?.status?.status
  );
  const disabled = !isActionAllowed(soapNoteStatus);

  const [errors, setErrors] = useReducer(
    parentTrainingErrorReducer,
    initialParentTrainingErrorState
  );

  const locationList = useSelector((state) => state.soapNote.locations);
  const errorList = useSelector((state) => state.soapNote.error.errors);

  useEffect(() => {
    if (!!data.participantResponses.length) return;
    const payload = peopleInAttendanceList
      .filter((x) => x.isDefault)
      .map((y) => y.id);
    setData({ type: ACTIONS.setAttendance, payload });
  }, [peopleInAttendanceList, setData, data]);

  useEffect(() => {
    if (!errorList) return;
    const list = Object.keys(errorList);
    list.forEach((id) => setErrors({ type: ERRORS[id], payload: true }));
  }, [errorList]);

  const attendanceHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setAttendance, payload: value });
    if (!errors.Attendance) return;
    setErrors({ payload: false, type: ERRORS.Attendance });
  };

  const directParentTraningHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setDirectParentTraining, payload: value });
    if (!errors.DirectParentTraining) return;
    setErrors({ payload: false, type: ERRORS.DirectParentTraining });
  };

  const parentFollowingPlanHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setData({
      type: ACTIONS.setParentFollowingPlan,
      payload: checked,
    });
    if (!errors.ParentFollowingPlan) return;
    setErrors({ payload: false, type: ERRORS.ParentFollowingPlan });
  };

  const antecedentBehaviorAddressedHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setAntecedentBehaviorAddressed, payload: value });
    if (!errors.AntecedentBehaviorAddressed) return;
    setErrors({ payload: false, type: ERRORS.AntecedentBehaviorAddressed });
  };

  const behaviorAddressedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setBehaviorAddressed, payload: value });
    if (!errors.BehaviorAddressed) return;
    setErrors({ payload: false, type: ERRORS.BehaviorAddressed });
  };

  const consequenceBehaviorAddressedHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setConsequenceBehaviorAddressed, payload: value });
    if (!errors.ConsequenceBehaviorAddressed) return;
    setErrors({ payload: false, type: ERRORS.ConsequenceBehaviorAddressed });
  };

  const behavioralMethodsUsedHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setBehavioralMethodsUsed, payload: value });
    if (!errors.BehavioralMethodsUsed) return;
    setErrors({ payload: false, type: ERRORS.BehavioralMethodsUsed });
  };

  const goalsAddressedHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setGoalsAddressed, payload: value });
    if (!errors.GoalsAddressed) return;
    setErrors({ payload: false, type: ERRORS.GoalsAddressed });
  };

  const barriersToTreatmentHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setBarriers, payload: value });
    if (!errors.Barriers) return;
    setErrors({ payload: false, type: ERRORS.Barriers });
  };

  const parentResponseToInterventionHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setParentResponseToIntervention, payload: value });
    if (!errors.ParentResponseToIntervention) return;
    setErrors({ payload: false, type: ERRORS.ParentResponseToIntervention });
  };

  const sessionSummaryHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setSessionSummary, payload: value });
    if (!errors.SessionSummary) return;
    setErrors({ payload: false, type: ERRORS.SessionSummary });
  };

  const signatureHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ type: ACTIONS.setSignature, payload: value.toUpperCase() });
    if (!errors.Signature) return;
    setErrors({ payload: false, type: ERRORS.Signature });
  };

  const locationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLocationId });
    if (!errors.LocationId) return;
    setErrors({ payload: false, type: ERRORS.LocationId });
  };

  const validateSignatureHandler = () => {
    if (validateName(data.signature)) {
      save();
      return;
    }
    setErrors({ payload: true, type: ERRORS.Signature });
  };

  return (
    <div>
      <TimeInfo disabled={disabled} />
      <CustomSelect
        label="Location:"
        data={locationList}
        value={!!data.locationId ? data.locationId : ""}
        setValue={locationHandler}
        className={"marginBottom16"}
        error={errors.LocationId}
        disabled={disabled}
        onBlur={save}
      />
      {isTelehealth && (
        <TelehealthLocations
          setData={setData}
          data={data}
          disabled={disabled}
          save={save}
        />
      )}
      <MultipleSelect
        label="Session Participants:"
        data={peopleInAttendanceList}
        initialValue={data.participantResponses}
        setValue={attendanceHandler}
        className={"marginBottom16"}
        error={errors.Attendance}
        disabled={disabled}
        onBlur={save}
      />
      <MultipleSelect
        label="Direct parent training:"
        data={directParentTrainingList}
        initialValue={data.directParentTrainingResponses}
        setValue={directParentTraningHandler}
        className={"marginBottom16"}
        error={errors.DirectParentTraining}
        disabled={disabled}
        onBlur={save}
      />
      <CustomCheckBox
        item={{
          label: `Was parent following
              behavior plan?:`,
          checked: data.parentFollowing,
          id: "0",
          disabled,
        }}
        onChange={parentFollowingPlanHandler}
        onBlur={save}
      />
      <CustomInput
        label="Antecedent to behavior addressed:"
        value={data.antecedent}
        setValue={antecedentBehaviorAddressedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        error={errors.AntecedentBehaviorAddressed}
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="Behavior addressed:"
        value={data.behavior}
        setValue={behaviorAddressedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.BehaviorAddressed}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="Consequence to behavior addressed:"
        value={data.consequence}
        setValue={consequenceBehaviorAddressedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        error={errors.ConsequenceBehaviorAddressed}
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="Behavioral methods used:"
        value={data.behavioralMethodsUsed}
        setValue={behavioralMethodsUsedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        disabled={disabled}
        error={errors.BehavioralMethodsUsed}
        onBlur={save}
      />
      <CustomInput
        label="Goal addressed:"
        value={data.goalsAddressed}
        setValue={goalsAddressedHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        disabled={disabled}
        error={errors.GoalsAddressed}
        onBlur={save}
      />
      <CustomInput
        label="Barriers to treatment:"
        value={data.barriersToTreatment}
        setValue={barriersToTreatmentHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        className="marginBottom16"
        error={errors.Barriers}
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="Parent response to intervention:"
        value={data.parentResponseToIntervention}
        setValue={parentResponseToInterventionHandler}
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        error={errors.ParentResponseToIntervention}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      <Text
        title="Session Details"
        size="smallBold"
        className="marginBottom16 title"
      />
      <Targets />
      <Behaviors />
      <CustomInput
        label="Session Summary: "
        value={data.sessionSummary}
        error={errors.SessionSummary}
        setValue={sessionSummaryHandler}
        className="marginBottom8"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        disabled={disabled}
        onBlur={save}
      />
      <div style={{ width: "250px" }}>
        <CustomInput
          label="Printed Name:"
          value={`${user.fullName}`.toUpperCase()}
          setValue={() => {}}
          disabled={true}
          className="marginBottom8 signature"
        />
        <SignatureInput
          value={data.signature}
          setValue={signatureHandler}
          disabled={disabled}
          error={errors.Signature}
          onBlur={validateSignatureHandler}
        />
      </div>
      <div>
        <Text title="Date of signature: " size="smallBold" />
        <Text title={todayString} />
      </div>
    </div>
  );
};

export default ParentTrainingData;
