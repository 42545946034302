import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { CustomButton } from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { downloadFile, ReportFilters } from "../helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getExpiredSessions } from "../../../redux/State/clientSlice/excelReport";

const ExpiredSessions = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const filterData = useSelector((state) => state.billing.billingFilter);
  const loading = useSelector((state) => state.excelReport.loading);

  const downloadHandler = useCallback(() => {
    const { clientId, endDate, startDate, userId } = filterData;

    dispatch(
      getExpiredSessions({
        userId,
        clientId,
        startDate,
        endDate,
      })
    )
      .then(unwrapResult)
      .then((response) => downloadFile(response))
      .catch(errorNormalizer);
  }, [dispatch, filterData]);

  return (
    <>
      <ReportFilters showUsers={false} />
      <div style={{ width: "max-content" }} className="marginTop16">
        <CustomButton
          title="Download"
          onClick={downloadHandler}
          loading={loading}
        />
      </div>
    </>
  );
};

export default ExpiredSessions;
