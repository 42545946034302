import { useEffect, ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import {
  CustomAlert,
  CustomButton,
  CustomCheckBox,
  CustomTable,
  Picker,
  Text,
} from "../../../shared/uiComponents";
import SearchBar from "../../../components/SearchBar";
import { Headers, TableBody } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getAvailableNotCredentialedBCBAs,
  getBillableUsers,
  getClientBillableUser,
  setBillingUserId,
} from "../../../redux/State/clientSlice/userClientSlice";
import dayjs, { Dayjs } from "dayjs";
import { ButtonWrapper } from "../../../components/StyledComponents";
import { dateNormalizer } from "../../../shared/Helpers/functions";

const today = dayjs();

const NotCredentialedBCBA = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const { clientId } = useParams();

  const bcbas = useSelector((state) => state.userClient.notCredentialedBCBAs);
  const loading = useSelector(
    (state) => state.userClient.loadingGetAvailableBCBAs
  );

  useEffect(() => {
    if (!clientId) return;
    params.set("credentialed", "false");
    setParams(params);
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const status = params.get("status") || "";

    dispatch(
      getAvailableNotCredentialedBCBAs({
        clientId,
        page,
        pageSize,
        orderBy,
        direction,
        searchString,
        status,
      })
    );
  }, [params, setParams, clientId, dispatch]);

  return (
    <div className="marginTop16">
      <div
        className="marginLeft8 billableWrapper"
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <BillingUsers />
        <SearchBar label="Find" />
      </div>
      <CustomTable
        headers={Headers}
        data={bcbas}
        TableBody={TableBody}
        loading={loading}
        hasMobile={false}
      />
    </div>
  );
};

const BillingUsers = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const { clientId } = useParams();

  const [open, setOpen] = useState<boolean>(false);
  const [startDateValue, setStartDateValue] = useState<Dayjs>(today);

  const billableUsers = useSelector((state) => state.userClient.billableUsers);
  const billingUserId = useSelector((state) => state.userClient.billableUserId);
  const therapistsListForAssign = useSelector(
    (state) => state.client.therapistsListForAssign
  );
  const loading = useSelector(
    (state) => state.userClient.loadingGetAvailableBCBAs
  );

  useEffect(() => {
    if (
      !billingUserId &&
      therapistsListForAssign.some(
        (x) => x.credentialed === "false" && x.isAssigned
      )
    ) {
      const id = billableUsers.find((x) => x.defaultBillingBCBA)?.id;
      if (!id) return;
      dispatch(setBillingUserId(id));
    }
  }, [billingUserId, therapistsListForAssign, billableUsers, dispatch]);

  useEffect(() => {
    dispatch(getBillableUsers());
  }, [dispatch]);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getClientBillableUser(clientId));
  }, [dispatch, clientId]);

  const startDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setStartDateValue(date);
  };

  const onBillableUserChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    if (!name) return;
    if (name === billingUserId) {
      dispatch(setBillingUserId(null));
      return;
    }
    dispatch(setBillingUserId(name));
  };

  const setDateRangeHandler = () => {
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(today);
    params.set("startDate", startDate);
    params.set("endDate", endDate);
    setParams(params);
    setOpen(false);
  };

  return (
    <>
      <div className="billableUserWrapper" onClick={() => setOpen(true)}>
        <Text title={"Choose billable BCBA: "} size="tinyBold" />
        <div>
          {billableUsers.map((user) => (
            <CustomCheckBox
              key={user.id}
              item={{
                id: user.id,
                label: user.fullName,
                checked: user.id === billingUserId,
              }}
              onChange={onBillableUserChange}
            />
          ))}
        </div>
      </div>
      <CustomAlert
        open={open}
        onClose={() => setOpen(false)}
        Content={() => (
          <>
            <Picker.CustomDate
              label="Start Date"
              value={startDateValue}
              onChange={startDateHandler}
              className="marginBottom16"
            />
            <ButtonWrapper>
              <CustomButton
                title="Set"
                onClick={setDateRangeHandler}
                loading={loading}
              />
              <CustomButton
                title="Close"
                secondaryButton
                onClick={() => setOpen(false)}
                loading={loading}
              />
            </ButtonWrapper>
          </>
        )}
      />
    </>
  );
};

export default NotCredentialedBCBA;
