import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CustomAlert, CustomButton, Text } from "../../../shared/uiComponents";
import { useCheckPermission } from "../../../shared/Helpers/hooks";
import { ButtonWrapper } from "../../../components/StyledComponents";
import { AdminTypes } from "../../../components/Menu";
import { PERMISSIONS } from "../../../App/constants";

import { useSelector } from "../../../redux/store";
import { isActionAllowed } from "../helpers";
import ForcedOperation from "./forcedOperation";
import { toast } from "react-toastify";

const END_SESSION_BT_NOTE =
  "*Note: Please ensure you have taken data on at least 3 goals per hour and recorded any behaviors observed";

const END_SESSION_NOTE =
  "The related session will be automatically concluded. Please ensure all necessary data has been gathered.";

const SoapNoteButtons = ({
  save,
  submit,
}: {
  save: () => void;
  submit: () => void;
}) => {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const CREATE = useCheckPermission(PERMISSIONS.SOAPNOTE.CREATE);
  const EDIT = useCheckPermission(PERMISSIONS.SOAPNOTE.EDIT);

  const [open, setOpen] = useState<boolean>(false);

  const status = useSelector(
    (state) => state.soapNote.soapNote?.status?.status
  );
  const soapNoteLoading = useSelector((state) => state.soapNote.loading);
  const sessionLoading = useSelector((state) => state.session.loading);
  const role = useSelector((state) => state.account.role);
  const { date, startTime, endTime } = useSelector(
    (state) => state.session.sessionInfo
  );

  if (!isActionAllowed(status)) return null;

  const collectDataHandler = () => {
    const sessionId = params.get("sessionId");
    const clientId = params.get("clientId");
    const reportId = params.get("reportId");
    setOpen(false);
    navigate(`/calendar/session/${sessionId}/${clientId}`, {
      state: {
        reportId,
        date,
        startTime,
        endTime,
      },
    });
  };

  return (
    <>
      <ButtonWrapper>
        {EDIT.permissionGranted && (
          <CustomButton
            title="Save as draft"
            onClick={() => {
              toast("Saved");
              save();
              navigate("/calendar");
            }}
            className="marginTop8"
            secondaryButton
            loading={soapNoteLoading || sessionLoading}
          />
        )}
        {CREATE.permissionGranted && (
          <CustomButton
            title="Submit"
            className="marginTop8"
            onClick={() => setOpen(true)}
            loading={soapNoteLoading}
          />
        )}
      </ButtonWrapper>
      <ButtonWrapper>
        {(CREATE.permissionGranted || EDIT.permissionGranted) && (
          <CustomButton
            title="Collect Data"
            onClick={collectDataHandler}
            loading={soapNoteLoading || sessionLoading}
          />
        )}
      </ButtonWrapper>
      <ForcedOperation submit={submit} />

      <CustomAlert
        open={open}
        title="Attention"
        onClose={() => setOpen(false)}
        Content={() => (
          <div style={{ textAlign: "center" }}>
            <Text
              title={END_SESSION_NOTE}
              className="padding16"
              size="mediumBold"
            />
            {!!role && role.section.id === AdminTypes.bt && (
              <Text
                title={END_SESSION_BT_NOTE}
                className="padding16"
                size="mediumBold"
              />
            )}
            <CustomButton
              title="Edit Session Data"
              onClick={collectDataHandler}
              className="marginBottom16"
            />
            <CustomButton
              title="Cancel"
              secondaryButton
              onClick={() => setOpen(false)}
              className="marginBottom16"
            />
            <CustomButton
              title="Submit"
              onClick={submit}
              loading={soapNoteLoading || sessionLoading}
            />
          </div>
        )}
      />
    </>
  );
};

export default SoapNoteButtons;
