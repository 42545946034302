import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { styled } from "@mui/material";

import { black500, blue400 } from "../../Helpers/colors";
import Text, { sizes } from "../Text";

const CustomTab = styled(Tab)(() => ({
  color: black500,
  "&.Mui-selected": {
    color: blue400,
  },
}));

const CustomTabs: FC<{
  headers: Array<string>;
  panels: Array<FC>;
  headerFontSize?: sizes;
}> = ({ headers, panels, headerFontSize = "smallBold" }) => {
  const [tab, setTab] = useState<string>("0");
  const [, setParams] = useSearchParams();

  const setTabType = (value: string) => {
    setTab(value);
    window.localStorage.removeItem("tab");
    const params = new URLSearchParams();
    setParams(params);
  };

  useEffect(() => {
    const tab = window.localStorage.getItem("tab");
    if (!tab || !headers || !headers.length) return;
    setTab(tab);
  }, [headers]);

  return (
    <div>
      <Tabs value={tab}>
        {!!headers &&
          headers.map((header, index) => (
            <CustomTab
              key={index}
              label={
                <Text
                  title={header}
                  size={headerFontSize}
                  onClick={() => setTabType(`${index}`)}
                  textColor={tab === `${index}` ? blue400 : black500}
                />
              }
              id={`${index}`}
            />
          ))}
      </Tabs>
      {!!panels &&
        panels.map((Panel, index) => (
          <div key={index} hidden={tab !== `${index}`} id={`${index}`}>
            {tab === `${index}` && <Panel />}
          </div>
        ))}
    </div>
  );
};

export default CustomTabs;
