import { useEffect, Dispatch } from "react";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material";

import { CustomSelect } from "../../../shared/uiComponents";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getTelehealthLocations } from "../../../redux/State/clientSlice/soapNoteSlice";
import { TelehealthLocationTypes } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

interface ActionProperties {
  type: keyof typeof ACTIONS;
  payload: any;
}

enum ACTIONS {
  setClientTelehealthLocationId = "setClientTelehealthLocationId",
  setUserTelehealthLocationId = "setUserTelehealthLocationId",
}

const TelehealthLocations = ({
  setData,
  data,
  disabled,
  save,
}: {
  setData: Dispatch<ActionProperties>;
  data: {
    clientTelehealthLocationId: string;
    userTelehealthLocationId: string;
  };
  disabled: boolean;
  save?: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const locations = useSelector((state) => state.soapNote.telehealtLocations);

  const selectClientLocationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setClientTelehealthLocationId });
  };

  const selectUserLocationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setUserTelehealthLocationId });
  };

  useEffect(() => {
    dispatch(getTelehealthLocations());
  }, [dispatch]);

  return (
    <>
      <CustomSelect
        label="Client's location:"
        data={locations.filter(
          (x) => x.type === TelehealthLocationTypes.Client
        )}
        value={
          !!data.clientTelehealthLocationId
            ? data.clientTelehealthLocationId
            : ""
        }
        setValue={selectClientLocationHandler}
        className={"marginBottom16"}
        disabled={disabled}
        onBlur={save}
      />
      <CustomSelect
        label="Provider's location:"
        data={locations.filter((x) => x.type === TelehealthLocationTypes.User)}
        value={
          !!data.userTelehealthLocationId ? data.userTelehealthLocationId : ""
        }
        setValue={selectUserLocationHandler}
        className={"marginBottom16"}
        disabled={disabled}
        onBlur={save}
      />
    </>
  );
};

export default TelehealthLocations;
