import { FC } from "react";

import { useCheckPermission } from "../../../../shared/Helpers/hooks";
import { AdminTypes } from "../../../../components/Menu";
import { PERMISSIONS } from "../../../../App/constants";
import Details from "./details";
import DownloadSOAPNote from "./download";
import Edit from "./edit";
import View from "./view";
import Unlock from "./unlock";

import { useSelector } from "../../../../redux/store";
import { SoapNotesFullProperties } from "../../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import { SoapNotesStatuses } from "../../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

const Actions: FC<{ data: SoapNotesFullProperties }> = ({ data }) => {
  const DOWNLOAD = useCheckPermission(PERMISSIONS.SOAPNOTE.PDF);
  const UNLOCK = useCheckPermission(PERMISSIONS.SOAPNOTE.UNLOCK);
  const EDIT = useCheckPermission(PERMISSIONS.SOAPNOTE.EDIT);

  const role = useSelector((state) => state.account.role);

  const { id, status } = data;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer",
      }}
    >
      <Details data={data} />
      {(status.status === SoapNotesStatuses.expiredWeek ||
        status.status === SoapNotesStatuses.expired48) &&
        role?.section.id !== AdminTypes.bt && (
          <>
            {UNLOCK.permissionGranted && <Unlock reportId={id} />}
            <Edit data={data} />
          </>
        )}
      {(status.status === SoapNotesStatuses.draft ||
        status.status === SoapNotesStatuses.unlocked) &&
        EDIT.permissionGranted && <Edit data={data} />}
      {status.status === SoapNotesStatuses.submitted && (
        <>
          <View reportId={data.id} />
          {DOWNLOAD.permissionGranted && role?.section.id !== AdminTypes.bt && (
            <DownloadSOAPNote data={data} />
          )}
        </>
      )}
      {status.status === SoapNotesStatuses.pendingBT && (
        <View reportId={data.id} />
      )}
      {status.status === SoapNotesStatuses.timeMismatch && (
        <>
          <View reportId={data.id} />
          {EDIT.permissionGranted && <Edit data={data} />}
        </>
      )}
    </div>
  );
};

export default Actions;
