import {
  ChangeEvent,
  Dispatch,
  FocusEventHandler,
  MouseEvent,
  SetStateAction,
  useRef,
  useState,
} from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import { CloseOutlined as Close } from "@mui/icons-material";
import { FormControl, MenuItem, Paper, Popover, styled } from "@mui/material";

import Text from "../Text";
import { black500, black900, white100 } from "../../Helpers/colors";

const CustomizedTextField = styled(TextField)<TextFieldProps>(() => ({
  border: "1px solid",
  borderColor: black500,
  borderRadius: "10px",
  background: white100,
  color: black900,
  width: "100%",
  height: "40px",
  div: {
    height: "40px",
    paddingLeft: "16px",
    "&:before": {
      display: "none",
    },
    "&:after": {
      display: "none",
    },
  },
}));

export interface SelectDataProperties {
  id: string;
  label: string;
}

interface SelectProperties {
  data: Array<SelectDataProperties>;
  setValue: (value: string) => void;
  searchString: string;
  setSearchString: Dispatch<SetStateAction<string>>;
  disabled?: boolean;
  label: string;
  placeholder?: string;
  description?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  loadMore?: {
    activate: boolean;
    setSize: Dispatch<SetStateAction<number>>;
  };
  onBlur?: FocusEventHandler<HTMLInputElement>;
  loading: boolean;
  clearData?: () => void;
}

const SearchSelect: React.FC<SelectProperties> = ({
  label,
  className,
  data,
  setValue,
  loadMore = {
    activate: false,
  },
  loading,
  searchString,
  setSearchString,
  clearData = () => {},
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    // if (!value) {
    //   setValue("");
    // }
    if (loadMore.activate) {
      loadMore.setSize(8);
    }
    if (!anchorEl && !!inputRef.current) {
      inputRef.current.click();
    }
    setSearchString(value);
  };

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoadMore = () => {
    if (!loadMore.activate) return;
    loadMore.setSize((prev) => prev + 8);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleChoose = (item: SelectDataProperties) => {
    setSearchString(item.label);
    setValue(item.id);
    handleClose();
  };

  const handleClearSearch = () => {
    setSearchString("");
    setValue("");
    handleClose();
    clearData();
  };

  return (
    <FormControl style={{ width: "100%" }}>
      <Text
        title={label}
        className={`inputLabel ${!!label ? "active" : ""}`}
        size={"tinyBold"}
      />
      <CustomizedTextField
        variant="standard"
        placeholder={loading ? "Loading..." : ""}
        value={searchString}
        onChange={handleInputChange}
        onClick={handleOpen}
        className={className}
        disabled={loading}
        InputProps={{
          endAdornment: (
            <>
              {!loading && (
                <Close
                  fontSize="small"
                  onClick={handleClearSearch}
                  style={{ cursor: "pointer", marginRight: "8px" }}
                />
              )}
            </>
          ),
        }}
        inputRef={inputRef}
      />

      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableAutoFocus
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper
          style={{
            maxHeight: "350px",
            overflowY: "auto",
            paddingRight: "24px",
          }}
        >
          {anchorEl && (
            <MenuItem
              style={{ position: "absolute", top: 0, right: 0, zIndex: 1500 }}
            >
              <Close fontSize="small" onClick={handleClose} />
            </MenuItem>
          )}
          {!!data && !!data.length ? (
            data.map((item, index) => (
              <MenuItem key={index} onClick={() => handleChoose(item)}>
                {item.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem style={{ justifyContent: "center" }}>
              <Text title="No Data" size="smallBold" />
            </MenuItem>
          )}
          {loadMore.activate && (
            <Text
              title={"Load more"}
              size="smallBold"
              onClick={handleLoadMore}
              className="marginLeft16 marginBottom8 marginTop8"
            />
          )}
        </Paper>
      </Popover>
    </FormControl>
  );
};

export default SearchSelect;
