import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { CustomTable } from "../../../shared/uiComponents";
import SearchBar from "../../../components/SearchBar";
import { Headers, TableBody } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getAvailableCredentialedBCBAs,
  getSupervisors,
} from "../../../redux/State/clientSlice/userClientSlice";

const CredentialedBCBA = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params, setParams] = useSearchParams();
  const { clientId } = useParams();

  const bcbas = useSelector((state) => state.userClient.credentialedBCBAs);
  const loading = useSelector(
    (state) => state.userClient.loadingGetAvailableBCBAs
  );

  useEffect(() => {
    if (!clientId) return;
    params.set("credentialed", "true");
    setParams(params);
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const orderBy = params.get("orderBy") || "";
    const direction = params.get("direction") || "";
    const searchString = params.get("search") || "";
    const status = params.get("status") || "";

    dispatch(
      getAvailableCredentialedBCBAs({
        clientId,
        page,
        pageSize,
        orderBy,
        direction,
        searchString,
        status,
      })
    );
  }, [params, setParams, clientId, dispatch]);

  useEffect(() => {
    dispatch(getSupervisors());
  }, [dispatch]);

  return (
    <div>
      <div style={{ float: "right" }}>
        <SearchBar label="Find" />
      </div>
      <CustomTable
        headers={Headers}
        data={bcbas}
        TableBody={TableBody}
        loading={loading}
        hasMobile={false}
      />
    </div>
  );
};

export default CredentialedBCBA;
