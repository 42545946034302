import { useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { errorNormalizer } from "../../../shared/Helpers/functions";
import ParentTrainingData from "./parentTrainingData";
import {
  ACTIONS,
  initialParentTrainingState,
  parentTrainingReducer,
} from "./parentTrainingReducer";
import SoapNoteButtons from "../components/soapNoteButtons";
import { useResetCalendarData } from "../../Calendar/Helpers/hooks";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  getSoapNote,
  updateParentTraining,
} from "../../../redux/State/clientSlice/soapNoteSlice";
import {
  setDate,
  setEndTime,
  setStartTime,
} from "../../../redux/State/clientSlice/sessionSlice";
import {
  ParentTrainingProperties,
  UpdateParentTrainingProperties,
} from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const ParentTraining = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const [data, setData] = useReducer(
    parentTrainingReducer,
    initialParentTrainingState
  );

  const sessionInfo = useSelector((state) => state.session.sessionInfo);

  useEffect(() => {
    const reportId = params.get("reportId");
    const clientId = params.get("clientId");
    const sessionId = params.get("sessionId");

    if (!reportId || !clientId || !sessionId) return;

    dispatch(getSoapNote({ reportId, clientId }))
      .then(unwrapResult)
      .then((response) => {
        const {
          directParentTrainingResponses,
          participantResponses,
          location,
          date,
          startTime,
          endTime,
          clientTelehealthLocation,
          userTelehealthLocation,
        } = response as ParentTrainingProperties;
        const directParentTrainings = directParentTrainingResponses?.map(
          (x) => x.id
        );
        const participants = participantResponses?.map((x) => x.id);

        dispatch(setDate(dayjs(date).format()));
        dispatch(setStartTime(dayjs(startTime).format()));
        dispatch(setEndTime(dayjs(endTime).format()));

        setData({
          type: ACTIONS.setState,
          payload: response,
        });

        setData({
          type: ACTIONS.setDirectParentTraining,
          payload: directParentTrainings,
        });

        setData({
          type: ACTIONS.setAttendance,
          payload: participants,
        });

        if (!!location) {
          setData({
            type: ACTIONS.setLocationId,
            payload: location.id,
          });
        }
        if (!!clientTelehealthLocation) {
          setData({
            type: ACTIONS.setClientTelehealthLocationId,
            payload: clientTelehealthLocation.id,
          });
        }
        if (!!userTelehealthLocation) {
          setData({
            type: ACTIONS.setUserTelehealthLocationId,
            payload: userTelehealthLocation.id,
          });
        }
      })
      .catch(errorNormalizer);
  }, [dispatch, params]);

  const updateSoapNoteHandler = (submit: boolean) => {
    const ms = submit ? 0 : 10000;
    const timeout = setTimeout(() => {
      const reportId = params.get("reportId");
      const clientId = params.get("clientId");
      const forcedOperation =
        params.get("forcedOperation") === "true" ? true : false;
      if (!reportId || !clientId || !data) return;
      const { date, startTime, endTime } = sessionInfo;
      const allData: UpdateParentTrainingProperties = {
        ...data,
        date,
        startTime,
        endTime,
        reportId,
        submit,
        forcedOperation,
        clientId,
      };
      dispatch(updateParentTraining(allData))
        .then(unwrapResult)
        .then(() => {
          if (!submit) return;
          resetData();
          toast("Success");
          navigate("/calendar");
        })
        .catch(errorNormalizer);
    }, ms);
    return () => clearTimeout(timeout);
  };

  return (
    <>
      <ParentTrainingData
        setData={setData}
        data={data}
        save={() => updateSoapNoteHandler(false)}
      />
      <SoapNoteButtons
        save={() => updateSoapNoteHandler(false)}
        submit={() => updateSoapNoteHandler(true)}
      />
    </>
  );
};

export default ParentTraining;
