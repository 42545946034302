import { useSelector } from "../../../redux/store";
import { Text } from "../../../shared/uiComponents";

const AssignInfo = () => {
  const therapists = useSelector((state) => state.userClient.therapists);
  const selectedUsers = useSelector(
    (state) => state.client.therapistsListForAssign
  );

  const assignedUsers = selectedUsers.filter((x) => x.isAssigned);
  const unAssignedUsers = selectedUsers.filter(
    (x) => !x.isAssigned && therapists?.find((y) => y.id === x.id)
  );
  const supervisorsUpdated = assignedUsers.filter((x) =>
    therapists?.find((z) => z.id === x.id)
  );

  return (
    <div style={{ width: "400px" }} className="padding16">
      <Text
        title={`Assigned Providers (${assignedUsers.length}):`}
        size="mediumBold"
      />
      {assignedUsers
        .filter((x) => !therapists?.find((z) => z.id === x.id))
        .map((y) => (
          <Text title={y.fullName} />
        ))}
      <Text title={`Supervisor updated:`} size="tinyBold" />
      {supervisorsUpdated.map((y) => (
        <Text title={y.fullName} size="tiny" />
      ))}
      <Text
        title={`Unassigned Providers (${unAssignedUsers.length}): `}
        size="mediumBold"
      />
      {unAssignedUsers.map((y) => (
        <Text title={y.fullName} />
      ))}
    </div>
  );
};

export default AssignInfo;
