import { CustomButton, Text } from "../../../shared/uiComponents";
import { ButtonWrapper, Loader } from "../../../components/StyledComponents";

import { useSelector } from "../../../redux/store";

const RemoveTherapistsContent = ({
  onClose,
  confirmHandler,
}: {
  onClose: () => void;
  confirmHandler: () => void;
}) => {
  const data = useSelector((state) => state.authorization.allocationData);
  const loading = useSelector((state) => state.authorization.loading);
  const clientInfo = useSelector((state) => state.client.client);

  return !loading ? (
    <div>
      {data.map(({ totalHours, user }) => (
        <Text
          size="tiny"
          className="marginBottom16"
          title={`Are you sure you want to remove ${user.fullName} from ${clientInfo.fullName}?
  By removing this provider, ${totalHours} hours will be taken from their assigned hours and will become available for reassignment to another provider.`}
        />
      ))}
      <ButtonWrapper>
        <CustomButton title="Cancel" secondaryButton onClick={onClose} />
        <CustomButton title="Confirm" onClick={confirmHandler} />
      </ButtonWrapper>
    </div>
  ) : (
    <Loader />
  );
};

export default RemoveTherapistsContent;
