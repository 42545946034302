import API from "./API/Main/API";
import { login, refresh } from "./State/identitySlice/accountSlice";
import { resetState } from "./resetState";

const localStorageMiddleware = (store: any) => (next: any) => (action: any) => {

    const { dispatch } = store;
    const ls = window.localStorage;

    if (action.type === login.fulfilled.type) {
        if (!!action.payload.token) {
            ls.setItem('token', JSON.stringify(action.payload.token));
            ls.setItem('refresh-token', action.payload.token.refresh_token);
            API.setToken(action.payload.token.access_token);
        }
    }

    if (action.type === refresh.fulfilled.type) {
        if (!!action.payload.token) {
            ls.setItem('token', JSON.stringify(action.payload.token));
            ls.setItem('refresh-token', action.payload.token.refresh_token);
            API.setToken(action.payload.token.access_token);
        }
    }

    if (action.type === refresh.rejected.type) {
        dispatch(resetState());
    }

    if (action.type === resetState.type) {
        const appVersion = ls.getItem('appVersion');
        ls.clear();
        ls.setItem('appVersion', appVersion as string);
        API.setToken('');
        window.location.reload();
    }

    next(action);

}

export { localStorageMiddleware }
