import { FC } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { DispatchProperties } from "../../../../redux/store";
import { clearErrors } from "../../../../redux/State/clientSlice/soapNoteSlice";
import { SoapNotesFullProperties } from "../../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import { SoapNotesStatuses } from "../../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

const Edit: FC<{
  data: SoapNotesFullProperties;
}> = ({ data }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<DispatchProperties>();

  const { id, client, status, sessionType, sessionId } = data;

  const navigateSoapNoteHandler = () => {
    dispatch(clearErrors());
    const query = new URLSearchParams();
    query.set("type", `${sessionType.type}`);
    query.set("soapNoteStatus", `${status.status}`);
    query.set("clientName", client.fullName);
    query.set("reportId", id);
    query.set("sessionId", sessionId);
    query.set("clientId", client.id);
    navigate(`/soap-notes/edit?${query.toString()}`);
  };

  const isExpired =
    status.status === SoapNotesStatuses.expiredWeek ||
    status.status === SoapNotesStatuses.expired48;

  const Icon = isExpired ? RemoveRedEyeOutlined : EditOutlined;

  return (
    <Tooltip title={isExpired ? "View" : "Edit"}>
      <Icon fontSize="small" onClick={navigateSoapNoteHandler} />
    </Tooltip>
  );
};

export default Edit;
