import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";
import dayjs from "dayjs";

import {
  CustomButton,
  ClientStatusBadge,
  Text,
} from "../../../shared/uiComponents";
import { AdminTypes } from "../../../components/Menu";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { clearActiveAuthorization } from "../../../redux/State/clientSlice/insuranceAuthorizationSlice";
import {
  clearClients,
  clearTherapists,
} from "../../../redux/State/clientSlice/clientSlice";
import { Row } from "../../../components/StyledComponents";

const Wrapper = styled("div")(() => ({
  maxWidth: "360px",
  paddingRight: "16px",
  paddingBottom: "16px",
}));

const ClientInfo = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const {
    id,
    fullName,
    dateOfBirthday,
    address,
    insurances,
    status,
    caseCoordinator,
  } = useSelector((state) => state.client.client);
  const role = useSelector((state) => state.account.role);

  const navigate = useNavigate();

  const navigateAssignTherapistHandler = () => {
    if (!id) return;
    navigate(`/clients/client/assign/${id}`);
    dispatch(clearActiveAuthorization());
    dispatch(clearTherapists());
    dispatch(clearClients());
  };

  const navigateAllocateHours = () => {
    dispatch(clearActiveAuthorization());
    navigate(`/clients/client/allocate-authorization/${id}`);
  };

  return (
    <Wrapper>
      <Text title={fullName} size="largeBold" className="marginBottom8" />
      <Text
        title={`DOB: ${dayjs(dateOfBirthday).format("MM-DD-YYYY")}`}
        className="marginBottom8"
      />
      <Text
        title={`Address: ${!!address ? address : "-"}`}
        className="marginBottom8"
      />
      {!!insurances?.length &&
        insurances.map(({ name, insuranceNumber, type }) => (
          <div className="marginBottom8">
            <Text
              title={`${type === 1 ? "Primary" : "Secondary"} Insurance: ${
                !!name ? name : "-"
              }`}
            />
            <Text
              title={`Member ID: ${!!insuranceNumber ? insuranceNumber : "-"}`}
            />
          </div>
        ))}
      {caseCoordinator && (
        <Row>
          <Text title={"Case Coordinator: "} className="marginBottom8" />
          <Text title={caseCoordinator.fullName} className="marginBottom8" />
        </Row>
      )}
      <div className="marginLeft16 marginBottom16">
        <ClientStatusBadge statusCode={status.id} />
      </div>
      {role?.section.id === AdminTypes.admin && (
        <>
          <CustomButton
            title="Assign Provider"
            onClick={navigateAssignTherapistHandler}
            className="marginTop16"
            disabled={!id}
          />
          <CustomButton
            title="Allocate hours"
            secondaryButton
            onClick={navigateAllocateHours}
            className="marginTop16"
            disabled={!id}
          />
        </>
      )}
    </Wrapper>
  );
};

export default ClientInfo;
