import { FC } from "react";
import dayjs from "dayjs";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";

import { HeaderProperties } from "../../../shared/uiComponents/Table/tableProperties";

import { SoapNotesFullProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import Actions from "./Actions";

export interface RowRendererProperties {
  data: Array<SoapNotesFullProperties>;
}

export const Headers: Array<HeaderProperties> = [
  { id: "0", name: "Provider Name", orderBy: "user.fullName" },
  { id: "1", name: "Client Name", orderBy: "client.fullName" },
  { id: "2", name: "Type", orderBy: "sessionType.name" },
  { id: "3", name: "Session Date", orderBy: "date" },
  { id: "4", name: "SOAP Note Created", orderBy: "createdAt" },
  { id: "5", name: "Created by", orderBy: "createdBy.fullName" },
  {
    id: "6",
    name: "Status",
    statusKey: "status",
    titles: [
      "Draft",
      "Submitted",
      "Expired BT",
      "Expired BCBA",
      "Unlocked",
      "Pending BT",
      "Time/Location Conflict",
      "All",
    ], //All keyword is for reseting column
    width: "200px",
    titlesHeader: "Select Status:",
  },
  { id: "7", name: "Actions", width: "120px" },
];

export const TableBody: FC<RowRendererProperties> = ({ data }) => (
  <Body>
    {data.map((row, index) => (
      <TableRow key={index}>
        <TableCell>{row.user.fullName}</TableCell>
        <TableCell>{row.client.fullName}</TableCell>
        <TableCell>{row.sessionType.name}</TableCell>
        <TableCell>{dayjs(row.date).format("MM/DD/YYYY")}</TableCell>
        <TableCell>{dayjs(row.createdAt).format("MM/DD/YYYY")}</TableCell>
        <TableCell>{row.createdBy.fullName}</TableCell>
        <TableCell>{row.status.name}</TableCell>
        <TableCell>
          <Actions data={row} />
        </TableCell>
      </TableRow>
    ))}
  </Body>
);
