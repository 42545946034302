import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Text } from "../../../shared/uiComponents";
import { InfoRow } from "../../../components/StyledComponents";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getSessionBehaviors } from "../../../redux/State/clientSlice/dataCollectionSlice";
import { getSessionTargets } from "../../../redux/State/clientSlice/sessionSlice";

const Targets = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch<DispatchProperties>();

  const sessionTargets = useSelector((state) => state.session.sessionTargets);

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;
    dispatch(getSessionTargets(sessionId));
  }, [params, dispatch]);

  return (
    <>
      <Text
        title="Session targets:"
        size="smallBold"
        className="marginBottom16"
      />

      {sessionTargets.map((target, index) => (
        <div
          key={index}
          className="marginBottom16"
          style={{
            border: "1px solid black",
            borderRadius: "12px",
            padding: "8px",
            width: "100%",
          }}
        >
          <InfoRow className="marginBottom8">
            <Text title={"Skill area name:"} size="tinyBold" />
            <Text title={target.skillArea.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Program name:"} size="tinyBold" />
            <Text title={target.program.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Target name:"} size="tinyBold" />
            <Text title={target.target.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Independent:"} size="tinyBold" />
            <Text title={`${target.correctPercentage}%`} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Prompted:"} size="tinyBold" />
            <Text
              title={`${100 - target.correctPercentage}%`}
              size="tinyBold"
            />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Trials:"} size="tinyBold" />
            <Text title={target.trials} size="tinyBold" />
          </InfoRow>
        </div>
      ))}
    </>
  );
};

const Behaviors = () => {
  const [params] = useSearchParams();
  const dispatch = useDispatch<DispatchProperties>();

  const sessionBehaviors = useSelector(
    (state) => state.dataCollection.sessionBehaviors
  );

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;
    dispatch(getSessionBehaviors(sessionId));
  }, [params, dispatch]);

  return (
    <>
      <Text title="Behaviors:" size="smallBold" className="marginBottom16" />

      {sessionBehaviors.map((behavior, index) => (
        <div
          key={index}
          className="marginBottom16"
          style={{
            border: "1px solid black",
            borderRadius: "12px",
            padding: "8px",
            width: "100%",
          }}
        >
          <InfoRow className="marginBottom8">
            <Text title={"Behavior name:"} size="tinyBold" />
            <Text title={behavior.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Category name:"} size="tinyBold" />
            <Text title={behavior.category.name} size="tinyBold" />
          </InfoRow>
          <InfoRow className="marginBottom8">
            <Text title={"Recording type:"} size="tinyBold" />
            <Text title={behavior.recordingType.text} size="tinyBold" />
          </InfoRow>
          {behavior.count !== null && (
            <InfoRow className="marginBottom8">
              <Text title={"Count:"} size="tinyBold" />
              <Text title={`${behavior.count}`} size="tinyBold" />
            </InfoRow>
          )}
          {behavior.duration !== null && (
            <InfoRow className="marginBottom8">
              <Text title={"Total duration:"} size="tinyBold" />
              <Text title={`${behavior.duration} seconds`} size="tinyBold" />
            </InfoRow>
          )}
        </div>
      ))}
    </>
  );
};

export { Targets, Behaviors };
