import { FC } from "react";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";

import { HeaderProperties } from "../../../shared/uiComponents/Table/tableProperties";
import { Text } from "../../../shared/uiComponents";
import { InfoRow } from "../../../components/StyledComponents";

import { SoapNotesFullProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import Actions from "../Admin/Actions";

export const Info = ({ note }: { note: SoapNotesFullProperties }) => {
  const {
    client,
    user,
    status,
    createdAt,
    createdBy,
    sessionType,
    submittedAt,
    submittedBy,
    startTime,
    endTime,
  } = note;

  return (
    <>
      <Text title={"Client Info:"} size="mediumBold" className="title" />
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Client name:"} size={"smallBold"} />
        <Text title={client.fullName} />
      </InfoRow>
      {!!client.insurances?.length &&
        client.insurances.map((insurance) => (
          <div>
            <InfoRow className={"paddingTop8 paddingBottom8"}>
              <Text
                title={`${
                  insurance.type === 1 ? "Primary" : "Secondary"
                } Insurance:`}
                size={"smallBold"}
              />
              <Text title={insurance.name as string} />
            </InfoRow>
            <InfoRow className={"paddingTop8 paddingBottom8"}>
              <Text
                title={`${
                  insurance.type === 1 ? "Primary" : "Secondary"
                } Member ID:`}
                size={"smallBold"}
              />
              <Text title={insurance.insuranceNumber} />
            </InfoRow>
          </div>
        ))}
      <Text title={"SOAP Note Info:"} size="mediumBold" className="title" />
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Type:"} size={"smallBold"} />
        <Text title={sessionType.name} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Created by:"} size={"smallBold"} />
        <Text title={createdBy.fullName} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Created:"} size={"smallBold"} />
        <Text title={dayjs(createdAt).format("MM/DD/YYYY hh:mm A")} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Status:"} size={"smallBold"} />
        <Text title={status.name} />
      </InfoRow>

      {!!submittedBy && !!submittedAt && (
        <>
          <InfoRow className={"paddingTop8 paddingBottom8"}>
            <Text title={"Submited by:"} size={"smallBold"} />
            <Text title={submittedBy.fullName} />
          </InfoRow>
          <InfoRow className={"paddingTop8 paddingBottom8"}>
            <Text title={"Submited:"} size={"smallBold"} />
            <Text title={dayjs(submittedAt).format("MM/DD/YYYY hh:mm A")} />
          </InfoRow>
        </>
      )}
      <Text title={"Session Info:"} size="mediumBold" className="title" />
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Provider name:"} size={"smallBold"} />
        <Text title={user.fullName} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Provider role:"} size={"smallBold"} />
        <Text title={user.role.name} />
      </InfoRow>

      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Started:"} size={"smallBold"} />
        <Text title={dayjs(startTime).format("MM/DD/YYYY hh:mm A")} />
      </InfoRow>
      <InfoRow className={"paddingTop8 paddingBottom8"}>
        <Text title={"Ended:"} size={"smallBold"} />
        <Text title={dayjs(endTime).format("MM/DD/YYYY hh:mm A")} />
      </InfoRow>
    </>
  );
};

export interface RowRendererProperties {
  data: Array<SoapNotesFullProperties>;
}

export const Headers: Array<HeaderProperties> = [
  { id: "1", name: "Client Name", orderBy: "client.fullName" },
  { id: "2", name: "Type", orderBy: "sessionType.name" },
  { id: "3", name: "Session Date", orderBy: "date" },
  { id: "4", name: "SOAP Note Created", orderBy: "createdAt" },
  { id: "5", name: "Created by", orderBy: "createdBy.fullName" },
  {
    id: "6",
    name: "Status",
    titles: [
      "Draft",
      "Submitted",
      "Expired BT",
      "Expired BCBA",
      "Unlocked",
      "Pending BT",
      "Time/Location Conflict",
      "All",
    ], //All keyword is for reseting column
    width: "200px",
    titlesHeader: "Select Status:",
    statusKey: "status",
  },
  { id: "7", name: "Actions", width: "120px" },
];

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  return (
    <>
      <Body>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.client.fullName}</TableCell>
            <TableCell>{row.sessionType.name}</TableCell>
            <TableCell>{dayjs(row.date).format("MM/DD/YYYY")}</TableCell>
            <TableCell>{dayjs(row.createdAt).format("MM/DD/YYYY")}</TableCell>
            <TableCell>{row.createdBy.fullName}</TableCell>
            <TableCell>{row.status.name}</TableCell>
            <TableCell>
              <Actions data={row} />
            </TableCell>
          </TableRow>
        ))}
      </Body>
    </>
  );
};
