import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import { CustomAlert, CustomButton, Text } from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { red300 } from "../../../shared/Helpers/colors";
import { AdminTypes } from "../../../components/Menu";
import AssignInfo from "./assignInfo";
import DefaultBCBAConfirm from "./defaultBCBAConfirmContent";
import RemoveTherapistsContent from "./removeTherapistContent";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  assignUsersToClient,
  getDefaultBCBA,
  getDefaultBCBAOptions,
} from "../../../redux/State/clientSlice/userClientSlice";
import { clearTherapists } from "../../../redux/State/clientSlice/clientSlice";
import { getUsersAllocationData } from "../../../redux/State/clientSlice/insuranceAuthorizationSlice";

const SaveChanges = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [params] = useSearchParams();

  const selectedUsers = useSelector(
    (state) => state.client.therapistsListForAssign
  );
  const billingUserId = useSelector((state) => state.userClient.billableUserId);
  const therapists = useSelector((state) => state.userClient.therapists);
  const [loading, setLoading] = useState<boolean>(false);

  const assignedUsers = selectedUsers.filter((x) => x.isAssigned);
  const unAssignedUsers = selectedUsers.filter(
    (x) => !x.isAssigned && therapists?.find((y) => y.id === x.id)
  );

  const userIds = [...assignedUsers, ...unAssignedUsers];

  const [removalConfirm, setRemovalConfirm] = useState<boolean>(false);
  const [assingConfirm, setAssignConfirm] = useState<boolean>(false);

  const closeAssignHandler = () => setAssignConfirm(false);
  const closeRemovalHandler = () => setRemovalConfirm(false);

  const assginTherapistsHandler = () => {
    if (!clientId) return;
    setLoading(true);
    if (!!unAssignedUsers.length) {
      dispatch(
        getUsersAllocationData({
          clientId,
          userIds: unAssignedUsers.map((x) => x.id),
        })
      )
        .then(unwrapResult)
        .then((response) => {
          if (!!response?.length) {
            setRemovalConfirm(true);
            return;
          }
          toast("No allocated time found for provided users");
          changeTherapistsHandler();
        })
        .catch(errorNormalizer);
      return;
    }
    changeTherapistsHandler();
  };

  const navigateToAllocateHandler = () => {
    if (!clientId) return;
    dispatch(clearTherapists());
    navigate(`/clients/client/allocate-authorization/${clientId}`);
  };

  const changeTherapistsHandler = () => {
    if (!clientId) return;

    const startDate = params.get("startDate");
    const endDate = params.get("endDate");

    dispatch(
      assignUsersToClient({
        userIds,
        clientId,
        billingUserId,
        endDate,
        startDate,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        if (!response.isSuccessful) {
          toast("Something went wrong");
          return;
        }
        toast("Success");
        if (selectedUsers.every((x) => !x.isAssigned)) {
          navigateToAllocateHandler();
          return;
        }
        if (selectedUsers.some((x) => x.role !== AdminTypes.bt)) {
          dispatch(getDefaultBCBA(clientId));
          dispatch(getDefaultBCBAOptions(clientId));
          setAssignConfirm(true);
          return;
        }
        navigateToAllocateHandler();
      })
      .catch(errorNormalizer)
      .finally(() => {
        closeRemovalHandler();
        dispatch(clearTherapists());
        setLoading(false);
      });
  };

  return (
    <div style={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
      <AssignInfo />
      <div style={{ width: "350px", textAlign: "center" }}>
        <Text
          title={
            "Changes from all tabs will be saved. Adjust provider selections as needed before saving."
          }
          textColor={red300}
          size="tinyBold"
          className="padding8"
        />
        <CustomButton
          title="Save"
          onClick={assginTherapistsHandler}
          loading={loading}
        />
        <CustomAlert
          open={assingConfirm}
          onClose={closeAssignHandler}
          title="Set Default BCBA"
          Content={({ onClose }) => <DefaultBCBAConfirm onClose={onClose} />}
        />
        <CustomAlert
          open={removalConfirm}
          onClose={closeRemovalHandler}
          title="Confirm Provider Removal"
          Content={({ onClose }) => (
            <RemoveTherapistsContent
              onClose={onClose}
              confirmHandler={changeTherapistsHandler}
            />
          )}
        />
      </div>
    </div>
  );
};

export default SaveChanges;
