import { ChangeEvent, FC } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableBody as Body,
} from "@mui/material";

import "./table.scss";
import { TableProperties } from "./tableProperties";
import { TableLoader } from "./tableHelpers";
import Text from "../Text";
import { ColumnHeader, FilterColumnHeader } from "./columnHeaders";

const CustomTable: FC<TableProperties> = ({
  headers,
  data,
  TableBody,
  loading,
  hasMobile = true,
  hasPagination = true,
}) => {
  const [params, setParams] = useSearchParams();

  const handleChangePage = (_: unknown, newPage: number) => {
    const page = newPage + 1;
    const pageSize = params.get("pageSize") || "8";
    params.set("page", `${page}`);
    params.set("pageSize", pageSize);
    if (page > 0) {
      setParams(params);
    }
  };

  const handlePageSize = (event: ChangeEvent<HTMLInputElement>) => {
    const pageSize = event.target.value;
    params.set("page", "1");
    params.set("pageSize", pageSize);
    setParams(params);
  };

  return (
    <TableContainer className={hasMobile ? "tableContainer" : ""}>
      <Table>
        <TableHead className={"tableHeader"}>
          <TableRow>
            {!!headers &&
              headers.map((item) => (
                <TableCell key={item.id} width={item.width}>
                  {!item.titles ? (
                    <ColumnHeader item={item} />
                  ) : (
                    <FilterColumnHeader item={item} />
                  )}
                </TableCell>
              ))}
          </TableRow>
        </TableHead>
        {!loading ? (
          !!data.query && !!data.query.length ? (
            <TableBody data={data.query} />
          ) : (
            <Body>
              <TableRow>
                <TableCell colSpan={headers.length}>
                  <Text title={"No Data"} size="mediumBold" center />
                </TableCell>
              </TableRow>
            </Body>
          )
        ) : (
          <TableLoader spanScope={headers.length} />
        )}
      </Table>
      {hasPagination && (
        <TablePagination
          rowsPerPageOptions={[4, 8, 12, 25, 50]}
          count={data.totalNumberOfItems as number}
          page={parseInt(params.get("page") || "1") - 1}
          rowsPerPage={parseInt(params.get("pageSize") || "8")}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handlePageSize}
          component={"div"}
        />
      )}
    </TableContainer>
  );
};

export default CustomTable;
