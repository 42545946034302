import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  CloseOutlined as Close,
  CheckOutlined as Check,
  LockOpenOutlined as Lock,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import {
  dateNormalizer,
  errorNormalizer,
} from "../../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomButton,
  Picker,
} from "../../../../shared/uiComponents";
import { ButtonWrapper } from "../../../../components/StyledComponents";
import { Wrapper } from "../helpers";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import {
  getSoapNotesAdmin,
  unlockSoapNote,
} from "../../../../redux/State/clientSlice/soapNoteSlice";

const initialExpirationDate = dayjs().add(2, "days");

const Unlock: FC<{ reportId: string }> = ({ reportId }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const filterData = useSelector((state) => state.billing.billingFilter);

  const [unlockConfirm, setUnlockConfirm] = useState<boolean>(false);
  const [addNextExpiration, setAddNextExpiration] = useState<boolean>(false);
  const [expirationDateValue, setExpirationDateValue] = useState<Dayjs | null>(
    initialExpirationDate
  );

  const unlockHandler = () => {
    setUnlockConfirm(false);
    if (!reportId) return;
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("search") || "";
    const status = params.get("status") || "";
    const { clientId, endDate, startDate, userId } = filterData;

    dispatch(
      unlockSoapNote({
        reportId,
        clientId,
        nextExpirationDate: dateNormalizer(dayjs(expirationDateValue)),
      })
    )
      .then(unwrapResult)
      .then(() => {
        dispatch(
          getSoapNotesAdmin({
            page,
            pageSize,
            direction,
            orderBy,
            searchString,
            status,
            endDate,
            startDate,
            userId,
            clientId,
          })
        );
        toast("Success");
      })
      .catch(errorNormalizer);
  };

  return (
    <Wrapper unlockConfirm={unlockConfirm}>
      <Tooltip title="Unlock">
        {unlockConfirm ? (
          <>
            <Close fontSize="small" onClick={() => setUnlockConfirm(false)} />
            <Check
              fontSize="small"
              onClick={() => setAddNextExpiration(true)}
            />
          </>
        ) : (
          <Lock fontSize="small" onClick={() => setUnlockConfirm(true)} />
        )}
      </Tooltip>
      <CustomAlert
        title="Set next expiration date"
        onClose={() => setAddNextExpiration(false)}
        open={addNextExpiration}
        Content={() => (
          <>
            <ButtonWrapper>
              <Picker.CustomDate
                label="Expiration date"
                value={expirationDateValue}
                onChange={setExpirationDateValue}
              />
            </ButtonWrapper>
            <ButtonWrapper>
              <CustomButton
                secondaryButton
                title="Cancel"
                onClick={() => setAddNextExpiration(false)}
              />
              <CustomButton title="Unlock" onClick={unlockHandler} />
            </ButtonWrapper>
          </>
        )}
      />
    </Wrapper>
  );
};

export default Unlock;
