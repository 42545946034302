import { useDispatch } from "react-redux";
import dayjs, { Dayjs } from "dayjs";
import { styled } from "@mui/material";

import { CustomInput, Picker } from "../../../shared/uiComponents";
import { useCompareDates } from "../../../shared/Helpers/hooks";
import { findDuration } from "../../Calendar/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  setEndTime,
  setStartTime,
} from "../../../redux/State/clientSlice/sessionSlice";

const TimeWrapper = styled("div")(() => ({
  display: "flex",
  gap: "32px",
  width: "100%",
  flexWrap: "wrap",
}));

const TimeInfo = ({
  disabled,
}: {
  disabled?: boolean;
  save?: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();

  const { date, startTime, endTime } = useSelector(
    (state) => state.session.sessionInfo
  );
  const noteDate = !!date ? date.split("T")[0] : "";

  const { hasError, message } = useCompareDates(
    dayjs(startTime),
    dayjs(endTime)
  );

  const startTimeHandler = (value: Dayjs | null) => {
    if (!value) return;
    const startTime = value.format().slice(10, 19);
    dispatch(setStartTime(`${noteDate}${startTime}`));
  };

  const endTimeHandler = (value: Dayjs | null) => {
    if (!value) return;
    const endTime = value.format().slice(10, 19);
    dispatch(setEndTime(`${noteDate}${endTime}`));
  };

  return !!startTime && !!endTime ? (
    <TimeWrapper className="marginBottom16">
      <Picker.CustomTime
        label="Start time"
        onChange={startTimeHandler}
        value={dayjs(startTime)}
        disabled={disabled}
      />
      <Picker.CustomTime
        label="End time"
        onChange={endTimeHandler}
        value={dayjs(endTime)}
        error={hasError}
        errorMessage={message}
        disabled={disabled}
      />
      <CustomInput
        label="Duration:"
        value={findDuration(startTime, endTime)}
        setValue={() => {}}
        disabled={true}
      />
    </TimeWrapper>
  ) : null;
};

export default TimeInfo;
