import { unwrapResult } from "@reduxjs/toolkit";
import { ChangeEvent, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  CustomButton,
  CustomCheckBox,
  CustomTable,
} from "../../../../shared/uiComponents";
import { errorNormalizer } from "../../../../shared/Helpers/functions";
import { Headers, TableBody } from "../MBHReport/tableInfo";
import { ReportFilters, downloadFile } from "../../helpers";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import {
  GetBillingProperties,
  GetTotalBillingProperties,
} from "../../../../redux/API/ClientAPIHelpers/billingProperties";
import {
  downloadNCExcelReport,
  downloadTotalReport,
  getNonCredentialedSessions,
  setOnlyNew,
} from "../../../../redux/State/clientSlice/billingSlice";

const NonCredentialedReport = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [params] = useSearchParams();

  const filterData = useSelector((state) => state.billing.billingFilter);
  const sessions = useSelector((state) => state.billing.ncSessions);
  const loading = useSelector((state) => state.billing.loading);

  const fetchData = useCallback(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const { clientId, endDate, startDate, userId, onlyNew } = filterData;

    if (!startDate || !endDate) return;

    const data: GetBillingProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      onlyNew,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
    };

    dispatch(getNonCredentialedSessions(data))
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [dispatch, params, filterData]);

  const downloadNonCredentialedReportHandler = useCallback(() => {
    const page = params.get("page") || "1";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const { clientId, endDate, startDate, userId, onlyNew } = filterData;

    const pageSize = !!sessions.totalNumberOfItems
      ? sessions.totalNumberOfItems.toString()
      : "8";

    if (!startDate || !endDate) return;

    const data: GetBillingProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      onlyNew,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
    };
    dispatch(downloadNCExcelReport(data))
      .then(unwrapResult)
      .then((response) => {
        downloadFile(response);
        fetchData();
      })
      .catch(errorNormalizer);
  }, [dispatch, params, sessions, filterData, fetchData]);

  const downloadTotalReportHandler = useCallback(() => {
    const { clientId, endDate, startDate, userId } = filterData;

    if (!startDate || !endDate) return;

    const data: GetTotalBillingProperties =
      !!userId && !!clientId
        ? {
            startDate,
            endDate,
            userId,
            clientId,
          }
        : !!userId && !clientId
        ? {
            startDate,
            endDate,
            userId,
          }
        : {
            startDate,
            endDate,
          };

    dispatch(downloadTotalReport(data))
      .then(unwrapResult)
      .then((response) => downloadFile(response))
      .catch(errorNormalizer);
  }, [dispatch, filterData]);

  const onlyNewHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    dispatch(setOnlyNew(checked));
  };

  useEffect(() => {
    const timeout = setTimeout(fetchData, 1000);
    return () => clearTimeout(timeout);
  }, [fetchData]);

  return (
    <>
      <ReportFilters showUsers={true} />
      <div style={{ display: "flex", maxWidth: "720px", gap: "16px" }}>
        <CustomCheckBox
          item={{
            label: "Show only new",
            id: "0",
            checked: filterData.onlyNew,
            disabled: loading,
          }}
          onChange={onlyNewHandler}
        />
        <CustomButton
          onClick={downloadNonCredentialedReportHandler}
          title={"Download Detailed Report"}
          loading={loading}
        />
        <CustomButton
          onClick={downloadTotalReportHandler}
          title={"Download Total Report"}
          loading={loading}
        />
      </div>
      <CustomTable
        headers={Headers}
        TableBody={TableBody}
        data={sessions}
        loading={loading}
        hasMobile={false}
      />
    </>
  );
};

export default NonCredentialedReport;
