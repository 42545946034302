import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import dayjs, { Dayjs } from "dayjs";
import { SelectChangeEvent } from "@mui/material";

import {
  dateNormalizer,
  errorNormalizer,
} from "../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomSelect,
  Picker,
  CustomButton,
} from "../../../shared/uiComponents";
import { SelectDataProperties } from "../../../shared/uiComponents/Dropdown";
import { useCompareDates } from "../../../shared/Helpers/hooks";
import { AdminTypes } from "../../../components/Menu";
import { TimeInputsWrapper } from "../../Reports/helpers";
import { months } from "../Helpers/constants";
import { DayProperties } from "../Helpers/interfaces";
import { useResetCalendarData } from "../Helpers/hooks";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  setAddEvent,
  createEvent,
} from "../../../redux/State/clientSlice/calendarSlice";
import { CreateEventProperties } from "../../../redux/API/ClientAPIHelpers/calendarProperties";
import { getUserClients } from "../../../redux/State/clientSlice/userClientSlice";

const AddEvent = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const open = useSelector((state) => state.calendar.popups.add);
  const selectedDay = useSelector((state) => state.calendar.selectedDay);

  const closeHandler = () => {
    dispatch(setAddEvent(false));
  };

  return (
    <CustomAlert
      open={open}
      onClose={closeHandler}
      title={`Add calendar event to ${months[selectedDay.month]} ${
        selectedDay.day
      } ${selectedDay.year}`}
      Content={() => (
        <AddContent selectedDay={selectedDay} onClose={closeHandler} />
      )}
    />
  );
};

const AddContent = ({
  selectedDay,
  onClose,
}: {
  selectedDay: DayProperties;
  onClose: () => void;
}) => {
  const dispatch = useDispatch<DispatchProperties>();
  const { resetData } = useResetCalendarData();

  const [pageSize, setPageSize] = useState<number>(8);
  const [sessionTypeId, setSessionTypeId] = useState<string>("0");
  const [clientId, setClientId] = useState<string>("0");
  const [clientList, setClientList] = useState<Array<SelectDataProperties>>([]);
  const [startTimeValue, setStartTimeValue] = useState<Dayjs | null>(null);
  const [endTimeValue, setEndTimeValue] = useState<Dayjs | null>(null);
  const [sessionTypeDisabled, setSessionTypeDisabled] =
    useState<boolean>(false);

  const sessionTypes = useSelector((state) => state.session.sessionTypes);
  const userId = useSelector((state) => state.account.user.id);
  const role = useSelector((state) => state.account.role);
  const userClients = useSelector((state) => state.userClient.userClients);
  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);
  const [loading, setLoading] = useState<boolean>(false);

  const { hasError, message } = useCompareDates(startTimeValue, endTimeValue);

  useEffect(() => {
    if (!userId) return;
    dispatch(
      getUserClients({ userId, page: "1", pageSize: pageSize.toString() })
    );
  }, [userId, pageSize, dispatch]);

  useEffect(() => {
    if (!userClients.query) return;
    const clientList = userClients.query.map((client) => ({
      id: client.id as string,
      name: `${client.firstName} ${client.lastName}`,
    }));
    if (!clientList.length) {
      setClientId("");
      return;
    }
    setClientList([{ id: "0", name: "Select Client" }, ...clientList]);
  }, [userClients]);

  const onClientChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setClientId(value);
  };

  const onTypeChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    setSessionTypeId(value);
  };

  useEffect(() => {
    if (!!startTimeValue) {
      setEndTimeValue(startTimeValue.add(1, "h"));
    }
  }, [startTimeValue]);

  const addSession = () => {
    const { day, month, year } = selectedDay;
    if (!startTimeValue || !endTimeValue || !days.length) return;
    const hour = startTimeValue.hour();
    const minute = startTimeValue.minute();
    const dateValue = new Date(year, month, day, hour, minute);
    const startTime = dayjs(dateValue).format("YYYY-MM-DDTHH:mm");

    const endHour = endTimeValue.hour();
    const endMinute = endTimeValue.minute();
    const endTime = dayjs(
      new Date(year, month, day, endHour, endMinute)
    ).format("YYYY-MM-DDTHH:mm");

    if (!startTime || !endTime) return;
    setLoading(true);

    const data: CreateEventProperties = {
      clientId,
      date: dateNormalizer(dayjs(dateValue)),
      startTime,
      endTime,
      sessionTypeId: parseInt(sessionTypeId),
    };

    dispatch(createEvent(data))
      .then(unwrapResult)
      .then(() => {
        resetData();
        toast("Created");
        onClose();
      })
      .catch(errorNormalizer)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!role || role.section.id !== AdminTypes.bt) return;
    setSessionTypeDisabled(true);
    setSessionTypeId("3");
  }, [role]);

  return (
    <>
      <CustomSelect
        label="Client"
        data={clientList}
        value={clientId}
        setValue={onClientChange}
        className={"marginBottom16"}
        loadMore={{
          activate: !!userClients.totalNumberOfItems
            ? userClients.totalNumberOfItems > 8
            : false,
          setSize: setPageSize,
        }}
      />
      {!!sessionTypes && (
        <CustomSelect
          label="Session type"
          data={sessionTypes}
          value={sessionTypeId}
          setValue={onTypeChange}
          className={"marginBottom16"}
          disabled={sessionTypeDisabled}
        />
      )}
      <TimeInputsWrapper>
        <Picker.CustomTime
          label="Start time"
          value={startTimeValue}
          onChange={setStartTimeValue}
        />
        <Picker.CustomTime
          label="End time"
          value={endTimeValue}
          onChange={setEndTimeValue}
          error={hasError}
          errorMessage={message}
        />
      </TimeInputsWrapper>
      <CustomButton
        onClick={addSession}
        title={"Add calendar event"}
        loading={loading}
        disabled={
          !startTimeValue ||
          !endTimeValue ||
          !clientId ||
          hasError ||
          clientId === "0" ||
          sessionTypeId === "0"
        }
      />
    </>
  );
};

export default AddEvent;
