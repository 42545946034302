import { FC } from "react";
import dayjs from "dayjs";
import { TableBody as Body, TableCell, TableRow } from "@mui/material";

import { Text } from "../../shared/uiComponents";

import {
  AdminDashboardAuthorizationProperties,
  AuthorizationDetailsProperties,
  DashboardAuthorizationProperties,
} from "../../redux/API/ClientAPIHelpers/userClientProperties";
import AuthorizationDetailedInfo from "./authorizationDetailedInfo";
import { findExpiredInfoColor } from "./expiredInfo";
import { Link } from "react-router-dom";

interface RowRendererProperties {
  data: Array<DashboardAuthorizationProperties>;
}

interface AdminRowRendererProperties {
  data: Array<AdminDashboardAuthorizationProperties>;
}

const sortByStartDate = (
  a: AuthorizationDetailsProperties,
  b: AuthorizationDetailsProperties
) => {
  const c = new Date(a.startDate);
  const d = new Date(b.startDate);
  if (c < d) return 1;
  if (c > d) return -1;
  return 0;
};

const sortByStartDateDesc = (
  a: AuthorizationDetailsProperties,
  b: AuthorizationDetailsProperties
) => {
  const c = new Date(a.startDate);
  const d = new Date(b.startDate);
  if (c > d) return 1;
  if (c < d) return -1;
  return 0;
};

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  return (
    <Body>
      <TableRow>
        <TableCell />
        <TableCell
          style={{ display: "flex", gap: "24px", padding: "8px 32px" }}
        >
          <Text
            title={"Start Date"}
            size="tinyBold"
            style={{ minWidth: "100px" }}
          />
          <Text
            title={"End Date"}
            size="tinyBold"
            style={{ minWidth: "100px" }}
          />
        </TableCell>
        <TableCell />
      </TableRow>
      {data
        .filter((x) =>
          x.authorizations.some((x) => !dayjs().isAfter(x.endDate))
        )
        .map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <Link to={`/clients/client/${row.client.id}`} target="_blank">
                <Text
                  title={row.client.fullName as string}
                  className="paddingLeft16"
                />
              </Link>
            </TableCell>
            <TableCell>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!!row.authorizations &&
                  [...row.authorizations]
                    .sort(sortByStartDateDesc)
                    .map((item) => (
                      <div
                        style={{
                          padding: "8px 16px",
                          backgroundColor: findExpiredInfoColor({
                            endDate: item.endDate,
                          }),
                          width: "max-content",
                          borderRadius: "12px",
                          marginBottom: "4px",
                          display: "flex",
                          gap: "32px",
                        }}
                      >
                        <Text
                          title={`${dayjs(item.startDate).format(
                            "MM-DD-YYYY"
                          )}`}
                        />
                        <Text
                          title={`${dayjs(item.endDate).format(
                            "MM-DD-YYYY"
                          )}`}
                        />
                      </div>
                    ))}
              </div>
            </TableCell>
            <TableCell>
              <AuthorizationDetailedInfo info={row} />
            </TableCell>
          </TableRow>
        ))}
      {data
        .filter((x) =>
          x.authorizations.every((x) => dayjs().isAfter(x.endDate))
        )
        .map((row, index) => (
          <TableRow key={index}>
            <TableCell>
              <Text
                title={row.client.fullName as string}
                className="paddingLeft16"
              />
            </TableCell>
            <TableCell>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {!!row.authorizations &&
                  [...row.authorizations].sort(sortByStartDate).map((item) => (
                    <div
                      style={{
                        padding: "8px 16px",
                        backgroundColor: findExpiredInfoColor({
                          endDate: item.endDate,
                        }),
                        width: "max-content",
                        borderRadius: "12px",
                        marginBottom: "4px",
                        display: "flex",
                        gap: "32px",
                      }}
                    >
                      <Text
                        title={`${dayjs(item.startDate).format("MM-DD-YYYY")}`}
                      />
                      <Text
                        title={`${dayjs(item.endDate).format("MM-DD-YYYY")}`}
                      />
                    </div>
                  ))}
              </div>
            </TableCell>
            <TableCell>
              <AuthorizationDetailedInfo info={row} />
            </TableCell>
          </TableRow>
        ))}
    </Body>
  );
};

export const TableBodyAdmin: FC<AdminRowRendererProperties> = ({ data }) => {
  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>
            <Link to={`/clients/client/${row.client.id}`} target="_blank">
              <Text
                title={row.client.fullName as string}
                className="paddingLeft16"
              />
            </Link>
          </TableCell>
          <TableCell>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Text
                title={`${dayjs(row.authorization.endDate).format(
                  "MM-DD-YYYY"
                )}`}
                style={{
                  padding: "8px 16px",
                  backgroundColor: findExpiredInfoColor({
                    endDate: row.authorization.endDate,
                  }),
                  width: "max-content",
                  borderRadius: "12px",
                  marginBottom: "4px",
                  display: "flex",
                  gap: "32px",
                }}
              />
            </div>
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
