import { UpdateParentTrainingProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

export enum ACTIONS {
    setState = "setState",
    setDate = "setDate",
    setEndTime = "setEndTime",
    setStartTime = "setStartTime",
    setLocationId = "setLocationId",
    setClientTelehealthLocationId = "setClientTelehealthLocationId",
    setUserTelehealthLocationId = "setUserTelehealthLocationId",
    setSessionSummary = "setSessionSummary",
    setSessionId = "setSessionId",
    setSignature = "setSignature",
    setPrintedName = "setPrintedName",
    setSubmit = "setSubmit",
    setGoalsAddressed = "setGoalsAddressed",
    setBarriers = "setBarriers",
    setAttendance = "setAttendance",
    setDirectParentTraining = "setDirectParentTraining",
    setParentFollowingPlan = "setParentFollowingPlan",
    setAntecedentBehaviorAddressed = "setAntecedentBehaviorAddressed",
    setBehaviorAddressed = "setBehaviorAddressed",
    setConsequenceBehaviorAddressed = "setConsequenceBehaviorAddressed",
    setBehavioralMethodsUsed = "setBehavioralMethodsUsed",
    setParentResponseToIntervention = "setParentResponseToIntervention",
}

export interface ActionProperties {
    type: keyof typeof ACTIONS,
    payload: any,
};

export const initialParentTrainingState: UpdateParentTrainingProperties = {
    date: '',
    startTime: "",
    endTime: '',
    goalsAddressed: '',
    locationId: null,
    printedName: '',
    sessionId: "",
    sessionSummary: '',
    signature: '',
    submit: false,
    antecedent: "",
    barriersToTreatment: "",
    behavior: "",
    behavioralMethodsUsed: "",
    consequence: "",
    participantResponses: [],
    directParentTrainingResponses: [],
    parentFollowing: false,
    parentResponseToIntervention: "",
    forcedOperation: false,
    clientTelehealthLocationId: '',
    userTelehealthLocationId: '',
    clientId: "",
    reportId: "",
}


export const parentTrainingReducer = (
    state: UpdateParentTrainingProperties,
    action: ActionProperties,
): UpdateParentTrainingProperties => {

    const { type, payload } = action;

    switch (type) {
        case ACTIONS.setState:
            return {
                ...state,
                ...payload,
            }
        case ACTIONS.setAttendance:
            return {
                ...state,
                participantResponses: payload,
            }
        case ACTIONS.setDirectParentTraining:
            return {
                ...state,
                directParentTrainingResponses: payload,
            }
        case ACTIONS.setParentFollowingPlan:
            return {
                ...state,
                parentFollowing: payload,
            }
        case ACTIONS.setAntecedentBehaviorAddressed:
            return {
                ...state,
                antecedent: payload,
            }
        case ACTIONS.setBehaviorAddressed:
            return {
                ...state,
                behavior: payload,
            }
        case ACTIONS.setConsequenceBehaviorAddressed:
            return {
                ...state,
                consequence: payload,
            }
        case ACTIONS.setBehavioralMethodsUsed:
            return {
                ...state,
                behavioralMethodsUsed: payload,
            }
        case ACTIONS.setGoalsAddressed:
            return {
                ...state,
                goalsAddressed: payload,
            }
        case ACTIONS.setBarriers:
            return {
                ...state,
                barriersToTreatment: payload,
            }
        case ACTIONS.setParentResponseToIntervention:
            return {
                ...state,
                parentResponseToIntervention: payload,
            }
        case ACTIONS.setSessionSummary:
            return {
                ...state,
                sessionSummary: payload,
            }
        case ACTIONS.setSignature:
            return {
                ...state,
                signature: payload,
            }
        case ACTIONS.setLocationId:
            return {
                ...state,
                locationId: payload,
            }
        case ACTIONS.setClientTelehealthLocationId:
            return {
                ...state,
                clientTelehealthLocationId: payload,
            }
        case ACTIONS.setUserTelehealthLocationId:
            return {
                ...state,
                userTelehealthLocationId: payload,
            }
        default:
            return state;
    }
}

export enum ERRORS {
    LocationId = "LocationId",
    SessionSummary = "SessionSummary",
    SessionId = "SessionId",
    Signature = "Signature",
    GoalsAddressed = "GoalsAddressed",
    Barriers = "Barriers",
    Attendance = "Attendance",
    DirectParentTraining = "DirectParentTraining",
    ParentFollowingPlan = "ParentFollowingPlan",
    AntecedentBehaviorAddressed = "AntecedentBehaviorAddressed",
    BehaviorAddressed = "BehaviorAddressed",
    ConsequenceBehaviorAddressed = "ConsequenceBehaviorAddressed",
    BehavioralMethodsUsed = "BehavioralMethodsUsed",
    ParentResponseToIntervention = "ParentResponseToIntervention",
}

interface ParentTrainingErrorStateProperties {
    LocationId: boolean;
    SessionSummary: boolean;
    SessionId: boolean;
    Signature: boolean;
    GoalsAddressed: boolean;
    Barriers: boolean;
    Attendance: boolean;
    DirectParentTraining: boolean;
    ParentFollowingPlan: boolean;
    AntecedentBehaviorAddressed: boolean;
    BehaviorAddressed: boolean;
    ConsequenceBehaviorAddressed: boolean;
    BehavioralMethodsUsed: boolean;
    ParentResponseToIntervention: boolean;
}

export const initialParentTrainingErrorState: ParentTrainingErrorStateProperties = {
    LocationId: false,
    SessionSummary: false,
    SessionId: false,
    Signature: false,
    GoalsAddressed: false,
    Barriers: false,
    Attendance: false,
    DirectParentTraining: false,
    ParentFollowingPlan: false,
    AntecedentBehaviorAddressed: false,
    BehaviorAddressed: false,
    ConsequenceBehaviorAddressed: false,
    BehavioralMethodsUsed: false,
    ParentResponseToIntervention: false,
}

interface ErrorActionProperties {
    type: keyof typeof ERRORS,
    payload: any,
}

export const parentTrainingErrorReducer = (state: ParentTrainingErrorStateProperties, action: ErrorActionProperties) => {
    const { payload, type } = action;
    switch (type) {
        case ERRORS[type]:
            return {
                ...state,
                [type]: payload,
            }
        default:
            return state
    }
}