import { ChangeEvent, Dispatch, useEffect, useReducer, useState } from "react";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { SelectChangeEvent } from "@mui/material/Select";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  CustomSelect,
  Text,
  CustomCheckBox,
  MultipleSelect,
  CustomInput,
} from "../../../shared/uiComponents";
import {
  errorNormalizer,
  validateName,
} from "../../../shared/Helpers/functions";
import { useDirectSupervisionOptions, useIsTelehealth } from "../hook";
import {
  SignatureInput,
  Targets,
  Behaviors,
  TimeInfo,
  TelehealthLocations,
} from "../components";

import {
  ACTIONS,
  ActionProperties,
  ERRORS,
  directSuperVisionErrorReducer,
  initialDirectSupervisionErrorState,
} from "./directSupervisionReducer";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  createSupervisedBehaviorTreatmentSession,
  getSupervisedSessionsInfo,
} from "../../../redux/State/clientSlice/sessionSlice";
import { GetSessionProperties } from "../../../redux/API/ClientAPIHelpers/calendarProperties";
import { LocationTypes } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import { getClientBTs } from "../../../redux/State/clientSlice/userClientSlice";
import { UpdateDirectSupervisionProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import { isActionAllowed } from "../helpers";

const DirectSupervisionData = ({
  data,
  setData,
  save,
}: {
  data: UpdateDirectSupervisionProperties;
  setData: Dispatch<ActionProperties>;
  save: () => void;
}) => {
  const today = new Date();
  const todayString = dayjs(today).format("MM/DD/YYYY");
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const { feedbackList, participantsList } = useDirectSupervisionOptions();

  const [errors, setErrors] = useReducer(
    directSuperVisionErrorReducer,
    initialDirectSupervisionErrorState
  );
  const [locations, setLocations] = useState<Array<LocationTypes>>([]);
  const isTelehealth = useIsTelehealth(data.locationId);

  const soapNoteStatus = useSelector(
    (state) => state.soapNote.soapNote?.status?.status
  );
  const disabled = !isActionAllowed(soapNoteStatus);

  const errorList = useSelector((state) => state.soapNote.error.errors);
  const supervisedSessions = useSelector(
    (state) => state.session.supervisedSessionInfo
  );
  const locationList = useSelector((state) => state.soapNote.locations);
  const { user, startTime, endTime } = useSelector(
    (state) => state.session.sessionInfo
  );
  const clientBTs = useSelector((state) => state.userClient.clientBTs);

  useEffect(() => {
    const clientId = params.get("clientId");
    if (!clientId) return;
    dispatch(getClientBTs(clientId));
  }, [dispatch, params]);

  useEffect(() => {
    if (!!data.participantResponses.length) return;
    const payload = participantsList
      .filter((x) => x.isDefault)
      .map((y) => y.id);
    setData({ type: ACTIONS.setParticipants, payload });
  }, [participantsList, setData, data]);

  useEffect(() => {
    if (!errorList) return;
    const list = Object.keys(errorList);
    list.forEach((id) => setErrors({ type: ERRORS[id], payload: true }));
  }, [errorList]);

  useEffect(() => {
    const sessionId = params.get("sessionId");
    if (!sessionId) return;
    dispatch(getSupervisedSessionsInfo(sessionId));
  }, [params, dispatch]);

  useEffect(() => {
    if (!locationList) return;
    const id = data.supervisedSessionId;
    const choosenLocationType = supervisedSessions.find((x) => x.id === id)
      ?.location?.locationType;

    if (!choosenLocationType) {
      setLocations(locationList);
      return;
    }

    const locations = locationList.filter(
      (x) => x.locationType === choosenLocationType
    );
    setLocations(locations);
  }, [data, locationList, supervisedSessions]);

  const chooseSupervisedSessionHandler = (event: SelectChangeEvent<string>) => {
    const sessionId = params.get("sessionId");
    const { value } = event.target;
    if (!supervisedSessions?.length) {
      if (!sessionId) return;
      dispatch(
        createSupervisedBehaviorTreatmentSession({ sessionId, userId: value })
      )
        .then(unwrapResult)
        .then((response) => {
          dispatch(getSupervisedSessionsInfo(sessionId))
            .then(unwrapResult)
            .then(() => {
              const payload = response.id;
              setData({ payload, type: ACTIONS.setSupervisedSessionId });
            });
        })
        .catch(errorNormalizer);
      return;
    }

    setData({ payload: value, type: ACTIONS.setSupervisedSessionId });
  };

  const selectFeedbackHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ payload: value, type: ACTIONS.setFeedbackResponses });
  };

  const promtedEffectivelyHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setData({
      type: ACTIONS.setPromtedEffectively,
      payload: checked,
    });
    if (!errors.PromtedEffectively) return;
    setErrors({ payload: false, type: ERRORS.PromtedEffectively });
  };

  const reinforcerImplementationHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setData({
      type: ACTIONS.setReinforcerImplementation,
      payload: checked,
    });
    if (!errors.ReinforcerImplementation) return;
    setErrors({ payload: false, type: ERRORS.ReinforcerImplementation });
  };

  const dataEnteredCorrectlyHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    setData({
      type: ACTIONS.setDataEnteredCorrectly,
      payload: checked,
    });
    if (!errors.DataEnteredCorrectly) return;
    setErrors({ payload: false, type: ERRORS.DataEnteredCorrectly });
  };

  const implementationOfGoalHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setImplementationOfGoals });
    if (!errors.ImplementationOfGoal) return;
    setErrors({ payload: false, type: ERRORS.ImplementationOfGoal });
  };

  const responseToBehavioralFeedbackHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setResponseToBehavioralFeedback });
    if (!errors.ResponseToBehavioralFeedback) return;
    setErrors({ payload: false, type: ERRORS.ResponseToBehavioralFeedback });
  };

  const clientPerformanceHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setClientPerformance });
    if (!errors.ClientPerformance) return;
    setErrors({ payload: false, type: ERRORS.ClientPerformance });
  };

  const changesAndMeasurementsObservedHandler = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setData({
      payload: value,
      type: ACTIONS.setChangesAndMeasurementsObserved,
    });
    if (!errors.ChangesAndMeasurementsObserved) return;
    setErrors({ payload: false, type: ERRORS.ChangesAndMeasurementsObserved });
  };

  const descriptionHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setDescriptionOfSession });
    if (!errors.DescriptionOfSession) return;
    setErrors({ payload: false, type: ERRORS.DescriptionOfSession });
  };

  const signatureHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value.toUpperCase(), type: ACTIONS.setSignature });
    if (!errors.Signature) return;
    setErrors({ payload: false, type: ERRORS.Signature });
  };

  const supervisedSessionNameNormilizer = (info: GetSessionProperties) => ({
    id: info.id,
    name: `${info.client.fullName} - ${dayjs(info.startTime)
      .utc(false)
      .format("MM/DD/YYYY hh:mm A")} - ${dayjs(info.endTime)
      .utc(false)
      .format("hh:mm A")} - ${
      info.isCompleted ? "Session completed" : "Session not completed"
    }`,
  });

  const supervisedBTSessionNormilizer = (id: string, fullName: string) => ({
    id,
    name: `${fullName} - ${dayjs(startTime).format(
      "MM/DD/YYYY hh:mm A"
    )} - ${dayjs(endTime).format("hh:mm A")} - Session not created`,
  });

  const selectLocationHandler = (event: SelectChangeEvent<string>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setLocationId });
    if (!errors.LocationId) return;
    setErrors({ payload: false, type: ERRORS.LocationId });
  };

  const validateSignatureHandler = () => {
    if (validateName(data.signature)) {
      save();
      return;
    }
    setErrors({ payload: true, type: ERRORS.Signature });
  };

  const participantsHandler = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (typeof value === "string") return;
    setData({ type: ACTIONS.setParticipants, payload: value });
    if (!errors.ParticipantResponses) return;
    setErrors({ payload: false, type: ERRORS.ParticipantResponses });
  };

  const feedbackOtherHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setData({ payload: value, type: ACTIONS.setFeedbackOther });
    if (!errors.FeedbackOther) return;
    setErrors({ payload: false, type: ERRORS.FeedbackOther });
  };

  const isOtherSelectedForFeedback = data.feedbackResponses.find(
    (x) => feedbackList.find((y) => y.id === x)?.text === "Other"
  );

  return (
    <div>
      <TimeInfo disabled={disabled} />
      <CustomSelect
        label="Choose supervised session:"
        data={
          !!supervisedSessions?.length
            ? supervisedSessions.map((x) => supervisedSessionNameNormilizer(x))
            : clientBTs.map(({ id, fullName }) =>
                supervisedBTSessionNormilizer(id, fullName)
              )
        }
        value={data.supervisedSessionId as string}
        setValue={chooseSupervisedSessionHandler}
        className="marginBottom16"
        disabled={disabled}
        error={errors.SupervisedSessionId}
        description={
          !supervisedSessions?.length
            ? "Selected session will be created automatically"
            : ""
        }
        onBlur={save}
      />
      <CustomSelect
        label="Location:"
        data={locations}
        value={!!data.locationId ? data.locationId : ""}
        error={errors.LocationId}
        setValue={selectLocationHandler}
        disabled={disabled}
        className="marginBottom16"
        onBlur={save}
      />
      {isTelehealth && (
        <TelehealthLocations
          setData={setData}
          data={data}
          disabled={disabled}
          save={save}
        />
      )}
      <MultipleSelect
        label="Session Participants:"
        data={participantsList}
        initialValue={data.participantResponses}
        setValue={participantsHandler}
        error={errors.ParticipantResponses}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      <CustomCheckBox
        item={{
          label: "Prompted effectively?",
          checked: data.promptedEffectively,
          id: "0",
          disabled,
        }}
        onChange={promtedEffectivelyHandler}
        onBlur={save}
      />
      <CustomCheckBox
        item={{
          label: `BT implemented the use of reinforcers properly according to the reinforcer rank order?`,
          checked: data.isReinforcerImplementationRight,
          id: "0",
          disabled,
        }}
        onChange={reinforcerImplementationHandler}
        onBlur={save}
      />
      <CustomCheckBox
        item={{
          label: "Data entered correctly?",
          checked: data.dataEnteredCorrectly,
          id: "0",
          disabled,
        }}
        onChange={dataEnteredCorrectlyHandler}
        onBlur={save}
      />
      <MultipleSelect
        label="Feedback provided to BT:"
        data={feedbackList}
        className="marginBottom16"
        setValue={selectFeedbackHandler}
        initialValue={data.feedbackResponses}
        error={errors.FeedbackResponses}
        disabled={disabled}
        onBlur={save}
      />
      {isOtherSelectedForFeedback && (
        <CustomInput
          label="If other, please describe: "
          value={data.feedbackOther}
          setValue={feedbackOtherHandler}
          className="marginBottom16"
          disabled={disabled}
          onBlur={save}
          maxLength={40}
        />
      )}
      <CustomInput
        label="BT implementation of a goal from treatment plan:"
        value={data.implementationOfGoal}
        setValue={implementationOfGoalHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        error={errors.ImplementationOfGoal}
        className="marginBottom16"
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="BT response to behavioral feedback:"
        value={data.responseToBehavioralFeedback}
        setValue={responseToBehavioralFeedbackHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.ResponseToBehavioralFeedback}
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="Client performance on two (2) goals:"
        value={data.clientPerformance}
        setValue={clientPerformanceHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.ClientPerformance}
        disabled={disabled}
        onBlur={save}
      />
      <CustomInput
        label="Maladaptive behaviors, changes and measurements observed:"
        value={data.changesAndMeasurementsObserved}
        setValue={changesAndMeasurementsObservedHandler}
        multiline={{ multiline: true, rowCount: 3 }}
        className="marginBottom16"
        error={errors.ChangesAndMeasurementsObserved}
        disabled={disabled}
      />
      <Text
        title="Session summary"
        size="smallBold"
        className="marginBottom16 title"
      />

      <Targets />
      <Behaviors />
      <CustomInput
        label="Description of session: "
        value={data.descriptionOfSession}
        setValue={descriptionHandler}
        error={errors.DescriptionOfSession}
        className="marginBottom8"
        multiline={{
          multiline: true,
          rowCount: 3,
        }}
        disabled={disabled}
        onBlur={save}
      />
      <div style={{ width: "250px" }}>
        <CustomInput
          label="Printed Name:"
          value={user.fullName.toUpperCase()}
          setValue={() => {}}
          disabled={true}
          className="marginBottom8 signature"
        />
        <SignatureInput
          value={data.signature}
          setValue={signatureHandler}
          error={errors.Signature}
          disabled={disabled}
          onBlur={validateSignatureHandler}
        />
      </div>
      <div>
        <Text title="Date of signature: " size="smallBold" />
        <Text title={todayString} />
      </div>
    </div>
  );
};

export default DirectSupervisionData;
