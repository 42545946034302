import { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  TableBody as Body,
  CircularProgress,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import dayjs from "dayjs";
import {
  RemoveRedEyeOutlined as View,
  EditOutlined as Edit,
} from "@mui/icons-material";

import { HeaderProperties } from "../../../shared/uiComponents/Table/tableProperties";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { useCheckPermission } from "../../../shared/Helpers/hooks";
import { PERMISSIONS } from "../../../App/constants";

import { DispatchProperties } from "../../../redux/store";
import { DSUSoapNoteProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";
import { getSoapNotePDF } from "../../../redux/State/exportSlice";

export const Headers: Array<HeaderProperties> = [
  { id: "1", name: "Provider", orderBy: "user.fullName" },
  { id: "2", name: "Client", orderBy: "client.fullName" },
  { id: "3", name: "Session Date", orderBy: "date", width: "200px" },
  {
    id: "4",
    name: "BCBA SOAP Note Status",
    titles: [
      "Draft",
      "Submitted",
      "Expired BT",
      "Expired BCBA",
      "Unlocked",
      "Pending BT",
      "Time/Location Conflict",
      "All",
    ], //All keyword is for reseting column
    width: "200px",
    titlesHeader: "Select Status:",
    statusKey: "status",
  },
  {
    id: "5",
    name: "BT SOAP Note Status",
    titles: [
      "Draft",
      "Submitted",
      "Expired BT",
      "Expired BCBA",
      "Unlocked",
      "Pending BT",
      "Time/Location Conflict",
      "All",
    ], //All keyword is for reseting column
    width: "200px",
    titlesHeader: "Select Status:",
    statusKey: "supervisedStatus",
  },
  { id: "6", name: "Action", width: "100px" },
];

export interface RowRendererProperties {
  data: Array<DSUSoapNoteProperties>;
}

export const TableBody: FC<RowRendererProperties> = ({ data }) => {
  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.user.fullName}</TableCell>
          <TableCell>{row.client.fullName}</TableCell>
          <TableCell>{dayjs.utc(row.date).format("MM-DD-YYYY")}</TableCell>
          <TableCell>{row.status.name}</TableCell>
          <TableCell>
            {!!row.supervisedSessionStatus
              ? row.supervisedSessionStatus.name
              : "-"}
          </TableCell>
          <TableCell>
            <ViewSoapNote item={row} />
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};

const ViewSoapNote = ({ item }: { item: DSUSoapNoteProperties }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const EDIT = useCheckPermission(PERMISSIONS.SOAPNOTE.EDIT);

  const viewSoapNoteHandler = () => {
    const { status, sessionType, client, id, sessionId } = item;
    if (
      SoapNotesStatuses.expiredWeek === status.status ||
      SoapNotesStatuses.expired48 === status.status ||
      SoapNotesStatuses.unlocked === status.status ||
      SoapNotesStatuses.draft === status.status ||
      SoapNotesStatuses.pendingBT === status.status
    ) {
      const query = new URLSearchParams();
      query.set("type", `${sessionType.type}`);
      query.set("soapNoteStatus", `${status.status}`);
      query.set("clientName", client.fullName);
      query.set("reportId", id);
      query.set("sessionId", sessionId);
      query.set("clientId", client.id);
      navigate(`/soap-notes/edit?${query.toString()}`);
      return;
    }
    setLoading(true);
    dispatch(
      getSoapNotePDF({
        reportId: id,
        includeTrials: true,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        window.open(objectURL);
      })
      .catch(errorNormalizer)
      .finally(() => setLoading(false));
  };

  return !loading ? (
    <Tooltip title="View">
      {(SoapNotesStatuses.unlocked === item.status.status ||
        SoapNotesStatuses.draft === item.status.status ||
        SoapNotesStatuses.pendingBT === item.status.status) &&
      EDIT.permissionGranted ? (
        <Edit
          fontSize="small"
          style={{ marginRight: "8px" }}
          onClick={viewSoapNoteHandler}
        />
      ) : (
        <View
          fontSize="small"
          style={{ marginRight: "8px" }}
          onClick={viewSoapNoteHandler}
        />
      )}
    </Tooltip>
  ) : (
    <div style={{ width: "24px", height: "24px" }}>
      <CircularProgress size={16} />
    </div>
  );
};
