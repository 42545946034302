import { useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material";

import {
  CustomTabs,
  CustomAccordion,
  Text,
} from "../../../shared/uiComponents";
import { useScreenWidth } from "../../../shared/Helpers/hooks";
import { AdminTypes } from "../../../components/Menu";
import ClientNotes from "./clientNotes";
import TherapistsList from "./therapistsList";
import ClientInfo from "./clientInfo";
import MasteredTargets from "./MasteredTargets";
import SkillAcquisition from "./SkillAcquisition";
import BehaviorReduction from "./BehaviorReduction";
import InsuranceAuthorization from "./InsuranceAuthorization";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getClient } from "../../../redux/State/clientSlice/clientSlice";
import { Loader } from "../../../components/StyledComponents";

const Wrapper = styled("div")(() => ({
  display: "flex",
  width: "100%",
  "@media (max-width: 1024px)": {
    flexDirection: "column",
  },
}));

const NotesWrapper = styled("div")(() => ({
  width: "100%",
  paddingLeft: "24px",
  overflowX: "auto",
  "@media (max-width: 1024px)": {
    paddingLeft: 0,
  },
}));

const ClientWrapper = styled("div")(() => ({
  minWidth: "270px",
}));

const tabHeaders = [
  "Skill Acquisition",
  "Behavior Reduction",
  "Insurance Authorization",
  "Maintenance",
];

const tabHeadersBT = ["Skill Acquisition", "Behavior Reduction"];

const tabPanels = [
  SkillAcquisition,
  BehaviorReduction,
  InsuranceAuthorization,
  MasteredTargets,
];
const tabPanelsBT = [SkillAcquisition, BehaviorReduction];

const Client = () => {
  const screenWidth = useScreenWidth();
  const dispatch = useDispatch<DispatchProperties>();
  const { clientId } = useParams();

  const roleId = useSelector((state) => state.account.role?.section.id);
  const loadingClient = useSelector((state) => state.client.loading);

  useEffect(() => {
    if (!clientId) return;
    dispatch(getClient(clientId));
  }, [clientId, dispatch]);

  return (
    <div className="container">
      <Wrapper>
        {!loadingClient ? (
          <>
            <ClientWrapper>
              <ClientInfo />
              <TherapistsList />
              <ClientNotes />
            </ClientWrapper>
            <NotesWrapper>
              {screenWidth > 768 ? (
                <CustomTabs
                  headers={AdminTypes.bt !== roleId ? tabHeaders : tabHeadersBT}
                  panels={AdminTypes.bt !== roleId ? tabPanels : tabPanelsBT}
                />
              ) : (
                (AdminTypes.bt !== roleId ? tabHeaders : tabHeadersBT).map(
                  (header, index) => (
                    <CustomAccordion
                      key={index}
                      HeaderContent={() => (
                        <Text title={header} size="smallBold" />
                      )}
                      Content={tabPanels[index]}
                    />
                  )
                )
              )}
            </NotesWrapper>
          </>
        ) : (
          <Loader />
        )}
      </Wrapper>
    </div>
  );
};

export default Client;
