import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

import { CustomAlert, Text } from "../../shared/uiComponents";
import { useScreenWidth } from "../../shared/Helpers/hooks";

import {
  clearDrafts,
  getSoapNotesUserDrafts,
} from "../../redux/State/clientSlice/soapNoteSlice";
import { DispatchProperties, useSelector } from "../../redux/store";

const SavedDraftsInfo = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [open, setOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(8);
  const screenWidth = useScreenWidth();

  const userId = useSelector((state) => state.account.user.id);
  const availableSessions = useSelector(
    (state) => state.soapNote.soapNotesDrafts
  );

  useEffect(() => {
    if (!userId || !!availableSessions.query) return;

    dispatch(
      getSoapNotesUserDrafts({
        userId,
        pageSize: `${pageSize}`,
      })
    );
  }, [userId, availableSessions, pageSize, dispatch]);

  const loadMoreHandler = () => {
    dispatch(clearDrafts());
    setPageSize((prev) => prev + 8);
  };

  return (
    <>
      {!!availableSessions.totalNumberOfItems && (
        <Text
          size="tinyBold"
          onClick={() => setOpen(true)}
          title={`You have ${availableSessions.totalNumberOfItems} session${
            availableSessions.totalNumberOfItems > 1 ? "s" : ""
          } pending submission.`}
          className={screenWidth < 768 ? "width150" : ""}
        />
      )}
      <CustomAlert
        open={open && !!availableSessions.query}
        title="Please complete drafts or submit missing notes."
        onClose={() => setOpen(false)}
        Content={({ onClose }) => (
          <>
            {availableSessions.query?.map((x, index) => (
              <OpenedNoteLabel
                x={x}
                index={index}
                key={index}
                onClose={onClose}
              />
            ))}
            {availableSessions.hasNextPage && (
              <Text title={"Load More"} onClick={loadMoreHandler} />
            )}
          </>
        )}
      />
    </>
  );
};

const OpenedNoteLabel = ({
  x,
  index,
  onClose,
}: {
  x: any;
  index: number;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const navigateNoteHandler = () => {
    const { id, client, sessionId } = x;
    navigate(
      `/soap-notes/info?clientId=${!!client?.id ? client.id : ""}&sessionId=${
        !!sessionId ? sessionId : ""
      }&reportId=${!!id ? id : ""}`
    );
    onClose();
  };

  return (
    <div style={{ display: "flex", gap: "16px" }} onClick={navigateNoteHandler}>
      <Text style={{ wordBreak: "unset" }} title={`${index + 1}: `} />
      {!!x.client && <Text title={x.client.fullName} />}
      <Text title={dayjs(x.startTime).format("MM/DD/YYYY hh:mm A")} />
    </div>
  );
};

export default SavedDraftsInfo;
