import { styled } from "@mui/material";

import { SoapNotesStatuses } from "../../redux/API/ClientAPIHelpers/dataCollectionProperties";

export const HeaderWrapper = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
  "&>div": {
    width: "50%",
  },
}));

export const isActionAllowed = (status: number) => {
  if (
    status === SoapNotesStatuses.submitted ||
    status === SoapNotesStatuses.expired48 ||
    status === SoapNotesStatuses.expiredWeek
  ) {
    return false;
  }

  return true;
};