import { ChangeEvent, FC, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { RemoveRedEyeOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

import { errorNormalizer } from "../../../../shared/Helpers/functions";
import {
  CustomAlert,
  CustomButton,
  CustomCheckBox,
} from "../../../../shared/uiComponents";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { getSoapNotePDF } from "../../../../redux/State/exportSlice";

const View: FC<{ reportId: string }> = ({ reportId }) => {
  const dispatch = useDispatch<DispatchProperties>();

  const loading = useSelector((state) => state.export.loading);

  const [open, setOpen] = useState<boolean>(false);
  const [includeTrials, setIncludeTrials] = useState<boolean>(false);

  const viewSoapNoteHandler = () => {
    dispatch(
      getSoapNotePDF({
        reportId,
        includeTrials,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        window.open(objectURL);
      })
      .catch(errorNormalizer)
      .finally(() => setOpen(false));
  };

  const includeTrialsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeTrials(checked);
  };

  return (
    <>
      <Tooltip title="View">
        <RemoveRedEyeOutlined fontSize="small" onClick={() => setOpen(true)} />
      </Tooltip>
      <CustomAlert
        title="Include Trials?"
        open={open}
        onClose={() => setOpen(false)}
        Content={() => (
          <>
            <CustomCheckBox
              item={{
                id: "1",
                checked: includeTrials,
                label: "Include",
              }}
              onChange={includeTrialsHandler}
            />
            <CustomButton
              title="View PDF"
              onClick={viewSoapNoteHandler}
              loading={loading}
            />
          </>
        )}
      />
    </>
  );
};

export default View;
