import { TableBody as Body, TableCell, TableRow } from "@mui/material";
import dayjs from "dayjs";
import { CloseOutlined } from "@mui/icons-material";

import { HeaderProperties } from "../../../shared/uiComponents/Table/tableProperties";

import { BillingProperties } from "../../../redux/API/ClientAPIHelpers/billingProperties";
import { ModifiedSessionDetails } from "../BillingReports/MBHReport/tableInfo";

export const Headers: Array<HeaderProperties> = [
  { id: "1", name: "Client Name", orderBy: "client.fullName" },
  { id: "2", name: "Session Date", orderBy: "sessionDate" },
  { id: "3", name: "Session Type" },
  { id: "4", name: "Provider" },
  { id: "5", name: "Modified" },
  { id: "6", name: "Submit Date", orderBy: "reportSubmittedAt" },
  { id: "7", name: "Download Date", orderBy: "reportExportedAt" },
];

export const TableBody = ({ data }: { data: Array<BillingProperties> }) => {
  return (
    <Body>
      {data.map((row, index) => (
        <TableRow key={index}>
          <TableCell>{row.client.fullName}</TableCell>
          <TableCell>
            {dayjs.utc(row.sessionDate).format("MM-DD-YYYY")}
          </TableCell>
          <TableCell>{row.sessionType.name}</TableCell>
          <TableCell>{!!row.billedBy ? row.billedBy.fullName : "-"}</TableCell>
          <TableCell>
            {!!row.sessionDateModifiedDetails ? (
              <ModifiedSessionDetails item={row.sessionDateModifiedDetails} />
            ) : (
              <CloseOutlined />
            )}
          </TableCell>
          <TableCell>
            {!!row.reportSubmittedAt
              ? dayjs(row.reportSubmittedAt).format("MM-DD-YYYY")
              : "Not submitted"}
          </TableCell>
          <TableCell>
            {!!row.reportExportedAt
              ? dayjs(row.reportExportedAt).format("MM-DD-YYYY")
              : "Not downloaded"}
          </TableCell>
        </TableRow>
      ))}
    </Body>
  );
};
