import { UpdateAssessmentProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

export enum ACTIONS {
    setState = "setState",
    setDate = "setDate",
    setEndTime = "setEndTime",
    setStartTime = "setStartTime",
    setLocationId = "setLocationId",
    setClientTelehealthLocationId = "setClientTelehealthLocationId",
    setUserTelehealthLocationId = "setUserTelehealthLocationId",
    setSessionSummary = "setSessionSummary",
    setSessionId = "setSessionId",
    setSignature = "setSignature",
    setPrintedName = "setPrintedName",
    setSubmit = "setSubmit",
    setAssessmentTools = "setAssessmentTools",
    setAssessmentActivities = "setAssessmentActivities",
    setParticipants = "setParticipants",
    setTreatmentPlans = "setTreatmentPlans",
    setDataCollected = "setDataCollected",
    setGoalsCreated = "setGoalsCreated",
    setAssessmentActivityOther = "setAssessmentActivityOther",
    setAssessmentParticipantOther = "setAssessmentParticipantOther",
}

export interface ActionProperties {
    type: keyof typeof ACTIONS,
    payload: any,
};

export const initialAssessmentState: UpdateAssessmentProperties = {
    date: "",
    endTime: "",
    locationId: null,
    sessionSummary: "",
    printedName: "",
    sessionId: '',
    signature: "",
    startTime: "",
    submit: false,
    assessmentToolResponses: [],
    assessmentActivityResponses: [],
    dataCollected: "",
    goalsCreated: "",
    participantResponses: [],
    treatmentPlanResponses: [],
    forcedOperation: false,
    clientTelehealthLocationId: '',
    userTelehealthLocationId: '',
    clientId: "",
    reportId: "",
    assessmentActivityOther: "",
    assessmentParticipantOther: "",
}

export const assessmentReducer = (
    state: UpdateAssessmentProperties,
    action: ActionProperties
): UpdateAssessmentProperties => {
    const { type, payload } = action;

    switch (type) {
        case ACTIONS.setState:
            return {
                ...state,
                ...payload,
            }
        case ACTIONS.setAssessmentTools:
            return {
                ...state,
                assessmentToolResponses: payload,
            }
        case ACTIONS.setAssessmentActivities:
            return {
                ...state,
                assessmentActivityResponses: payload,
            }
        case ACTIONS.setParticipants:
            return {
                ...state,
                participantResponses: payload,
            }
        case ACTIONS.setSessionSummary:
            return {
                ...state,
                sessionSummary: payload,
            }
        case ACTIONS.setTreatmentPlans:
            return {
                ...state,
                treatmentPlanResponses: payload,
            }
        case ACTIONS.setLocationId:
            return {
                ...state,
                locationId: payload,
            }
        case ACTIONS.setSignature:
            return {
                ...state,
                signature: payload,
            }
        case ACTIONS.setDataCollected:
            return {
                ...state,
                dataCollected: payload,
            }
        case ACTIONS.setGoalsCreated:
            return {
                ...state,
                goalsCreated: payload,
            }
        case ACTIONS.setClientTelehealthLocationId:
            return {
                ...state,
                clientTelehealthLocationId: payload,
            }
        case ACTIONS.setUserTelehealthLocationId:
            return {
                ...state,
                userTelehealthLocationId: payload,
            }
        case ACTIONS.setAssessmentActivityOther:
            return {
                ...state,
                assessmentActivityOther: payload,
            }
        case ACTIONS.setAssessmentParticipantOther:
            return {
                ...state,
                assessmentParticipantOther: payload,
            }
        default:
            return state;
    }
}

export enum ERRORS {
    LocationId = "LocationId",
    SessionSummary = "SessionSummary",
    Signature = "Signature",
    AssessmentToolResponses = "AssessmentToolResponses",
    AssessmentActivityResponses = "AssessmentActivityResponses",
    AssessmentActivityOther = "AssessmentActivityOther",
    ParticipantResponses = "ParticipantResponses",
    AssessmentParticipantOther = "AssessmentParticipantOther",
    TreatmentPlanResponses = "TreatmentPlanResponses",
    DataCollected = "DataCollected",
    GoalsCreated = "GoalsCreated",
}

interface AssessmentErrorStateProperties {
    LocationId: boolean;
    SessionSummary: boolean;
    Signature: boolean;
    AssessmentToolResponses: boolean;
    AssessmentActivityResponses: boolean;
    AssessmentActivityOther: boolean;
    ParticipantResponses: boolean;
    AssessmentParticipantOther: boolean;
    TreatmentPlanResponses: boolean;
    DataCollected: boolean;
    GoalsCreated: boolean;
}

interface ErrorActionProperties {
    type: keyof typeof ERRORS,
    payload: any
}

export const initialAssessmentErrorState: AssessmentErrorStateProperties = {
    AssessmentToolResponses: false,
    AssessmentActivityResponses: false,
    DataCollected: false,
    GoalsCreated: false,
    LocationId: false,
    ParticipantResponses: false,
    SessionSummary: false,
    Signature: false,
    TreatmentPlanResponses: false,
    AssessmentActivityOther: false,
    AssessmentParticipantOther: false,
}

export const AssessmentErrorReducer = (state: AssessmentErrorStateProperties, action: ErrorActionProperties) => {
    const { payload, type } = action;
    switch (type) {
        case ERRORS[type]:
            return {
                ...state,
                [type]: payload,
            }
        default:
            return state
    }
}