import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import {
    AdminDashboardProperties,
    AssignUsersToClientProperties,
    ClientUserPaginationProperties,
    DashboardAuthorizationProperties,
    DismissClientProperties,
    UpdateDefaultBCBAProperties,
    UserClientPaginationProperties,
    UserClientsProperties,
} from "../../API/ClientAPIHelpers/userClientProperties";
import {
    AuthorizedUsersProperties,
    ShortBTInfoProperties,
    TherapistProperties,
} from "../../API/ClientAPIHelpers/clientProperties";
import { AvailableUsersDataProperties } from "../../../pages/Clients/AssignTherapists/tableInfo";
import { ErrorProperties } from "../../API/identityAPIProperties";
import { PaginationProperties } from "../../../shared/uiComponents/Table/tableProperties";
// import { AdminTypes } from "../../../components/Menu";

interface initialUserClientStateProperties {
    loading: boolean;
    loadingGetUserClients: boolean;
    loadingGetAvailableBCBAs: boolean;
    loadingGetAvailableBTs: boolean;
    therapists: Array<TherapistProperties> | null;
    billableUsers: Array<AuthorizedUsersProperties>;
    billableUserId: string | null;
    supervisors: Array<AuthorizedUsersProperties>;
    caseCoordinators: Array<AuthorizedUsersProperties>;
    userClients: UserClientsProperties;
    availableUsers: AvailableUsersDataProperties;
    credentialedBCBAs: AvailableUsersDataProperties;
    notCredentialedBCBAs: AvailableUsersDataProperties;
    availableBTs: AvailableUsersDataProperties;
    userBTs: Array<ShortBTInfoProperties>;
    clientBTs: Array<ShortBTInfoProperties>;
    error: ErrorProperties;
    defaultBCBAOptions: Array<TherapistProperties>;
    defaultBCBA: TherapistProperties | null;
    authorizationBCBA: Array<DashboardAuthorizationProperties> | null,
    authorizationAdmin: AdminDashboardProperties,
}

const initialUserClientState: initialUserClientStateProperties = {
    loading: false,
    loadingGetAvailableBCBAs: false,
    loadingGetAvailableBTs: false,
    loadingGetUserClients: false,
    billableUsers: [],
    billableUserId: null,
    supervisors: [],
    caseCoordinators: [],
    therapists: null,
    userBTs: [],
    authorizationBCBA: null,
    authorizationAdmin: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    clientBTs: [],
    availableBTs: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    availableUsers: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    credentialedBCBAs: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    notCredentialedBCBAs: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    userClients: {
        query: null,
        page: 1,
        totalNumberOfItems: 0,
        numberOfItems: 0,
        totalPages: 1,
        hasNextPage: false,
        hasPreviousPage: false,
    },
    error: { status: 0, title: "" },
    defaultBCBAOptions: [],
    defaultBCBA: null,
}

export const getAuthorizationInfoAdmin = createAsyncThunk(
    '/client/insurance-info/authorization-admin',
    async (data: PaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getAuthorizationInfoAdmin(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as AdminDashboardProperties;
    }
)

export const getAuthorizationInfoBCBA = createAsyncThunk(
    '/client/insurance-info/authorization-bcba',
    async (userId: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getAuthorizationInfoBCBA(userId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getTherapists = createAsyncThunk(
    '/client/therapists',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getTherapists(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<TherapistProperties>;
    }
)

export const getUserBTs = createAsyncThunk(
    '/client/therapist/bts',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getBTs(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ShortBTInfoProperties>;
    }
)

export const getClientBTs = createAsyncThunk(
    '/client/cleint/bts',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getBTs(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<ShortBTInfoProperties>;
    }
)

export const getUserClients = createAsyncThunk(
    '/client/user/clients',
    async (data: UserClientPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getClients(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserClientsProperties;
    }
)

export const getUserClientsStartSession = createAsyncThunk(
    '/client/user/clients/start-session',
    async (data: UserClientPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getClients(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as UserClientsProperties;
    }
)

export const assignUsersToClient = createAsyncThunk(
    '/client/assign/users',
    async (data: AssignUsersToClientProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.assignUsersToClient(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const dismissClient = createAsyncThunk(
    '/client/dismiss',
    async (data: DismissClientProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.dissmissClient(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getAvailableUserClients = createAsyncThunk(
    '/user/assign/clients',
    async (data: UserClientPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getAvailableClients(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getAvailableClientUsers = createAsyncThunk(
    '/user/assign/users',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getAvailableTherapists(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getBillableUsers = createAsyncThunk(
    '/client/billable-users/options',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getBillableUsers();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<AuthorizedUsersProperties>;
    }
)

export const getClientBillableUser = createAsyncThunk(
    '/client/billable-user',
    async (id: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getBillingUserOfClient(id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as AuthorizedUsersProperties | null;
    }
)

export const getSupervisors = createAsyncThunk(
    '/client/supervisors',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getSupervisors();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<AuthorizedUsersProperties>;
    }
)

export const getAvailableCredentialedBCBAs = createAsyncThunk(
    '/user/assign/bcbas/credentialed',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getAvailableCredentialedBCBAs(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getLicensedBCBAs = createAsyncThunk(
    '/user/assign/bcbas/licensed',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getLicensedBCBAs(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as AvailableUsersDataProperties;
    }
)

export const getAvailableNotCredentialedBCBAs = createAsyncThunk(
    '/user/assign/bcbas/not-credentialed',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getAvailableNotCredentialedBCBAs(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getAvailableClientBTs = createAsyncThunk(
    '/user/assign/bts',
    async (data: ClientUserPaginationProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getAvailableBTs(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getDefaultBCBAOptions = createAsyncThunk(
    '/user/default-bcba/options',
    async (clientId: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getDefaultBCBAOptions(clientId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<TherapistProperties>;
    }
)

export const getDefaultBCBA = createAsyncThunk(
    '/user/default-bcba/',
    async (clientId: string, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.getDefaultBCBA(clientId);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as TherapistProperties;
    }
)

export const updateDefaultBCBA = createAsyncThunk(
    '/user/default-bcba/update',
    async (data: UpdateDefaultBCBAProperties, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.client.updateDefaultBCBA(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const getCaseCoordinators = createAsyncThunk(
    '/user/case-coordinators',
    async (_, thunkAPI) => {
        const response = await API.ClientAPI.UserClient.users.getCaseCoordinators();
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<AuthorizedUsersProperties>;
    }
)

const userClientSlice = createSlice({
    name: 'userClient',
    initialState: initialUserClientState,
    reducers: {
        setBillingUserId(state, action) {
            state.billableUserId = action.payload;
        }
    },
    extraReducers: (builder) => {
        //getAuthorizationInfoAdmin
        builder.addCase(getAuthorizationInfoAdmin.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAuthorizationInfoAdmin.fulfilled, (state, action) => {
            state.loading = false;
            state.authorizationAdmin = action.payload;
        })
        builder.addCase(getAuthorizationInfoAdmin.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        
        //getClientBTs
        builder.addCase(getClientBTs.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getClientBTs.fulfilled, (state, action) => {
            state.loading = false;
            state.clientBTs = action.payload;
        })
        builder.addCase(getClientBTs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        
        //getAuthorizationInfoBCBA
        builder.addCase(getAuthorizationInfoBCBA.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAuthorizationInfoBCBA.fulfilled, (state, action) => {
            state.loading = false;
            state.authorizationBCBA = action.payload;
        })
        builder.addCase(getAuthorizationInfoBCBA.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getUserBTs
        builder.addCase(getUserBTs.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getUserBTs.fulfilled, (state, action) => {
            state.loading = false;
            state.userBTs = action.payload;
        })
        builder.addCase(getUserBTs.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getClientBillableUser
        builder.addCase(getClientBillableUser.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getClientBillableUser.fulfilled, (state, action) => {
            state.loading = false;
            if (!action.payload) {
                state.billableUserId = null;
                return;
            }
            state.billableUserId = action.payload.id
        })
        builder.addCase(getClientBillableUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getDefaultBCBA
        builder.addCase(getDefaultBCBA.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getDefaultBCBA.fulfilled, (state, action) => {
            state.loading = false;
            state.defaultBCBA = action.payload;
        })
        builder.addCase(getDefaultBCBA.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getDefaultBCBAOptions
        builder.addCase(getDefaultBCBAOptions.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getDefaultBCBAOptions.fulfilled, (state, action) => {
            state.loading = false;
            state.defaultBCBAOptions = action.payload;
        })
        builder.addCase(getDefaultBCBAOptions.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //updateDefaultBCBA
        builder.addCase(updateDefaultBCBA.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(updateDefaultBCBA.fulfilled, (state, action) => {
            state.loading = false;
        })
        builder.addCase(updateDefaultBCBA.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getSupervisors
        builder.addCase(getSupervisors.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getSupervisors.fulfilled, (state, action) => {
            state.loading = false;
            state.supervisors = action.payload;
        })
        builder.addCase(getSupervisors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getCaseCoordinators
        builder.addCase(getCaseCoordinators.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getCaseCoordinators.fulfilled, (state, action) => {
            state.loading = false;
            state.caseCoordinators = action.payload;
        })
        builder.addCase(getCaseCoordinators.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getBillableUsers
        builder.addCase(getBillableUsers.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getBillableUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.billableUsers = action.payload;
        })
        builder.addCase(getBillableUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAvailableClientUsers
        builder.addCase(getAvailableClientUsers.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAvailableClientUsers.fulfilled, (state, action) => {
            state.loading = false;
            state.availableUsers = action.payload;
        })
        builder.addCase(getAvailableClientUsers.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAvailableCredentialedBCBAs
        builder.addCase(getAvailableCredentialedBCBAs.pending, (state) => {
            state.loadingGetAvailableBCBAs = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAvailableCredentialedBCBAs.fulfilled, (state, action) => {
            state.loadingGetAvailableBCBAs = false;
            state.credentialedBCBAs = action.payload;
        })
        builder.addCase(getAvailableCredentialedBCBAs.rejected, (state, action) => {
            state.loadingGetAvailableBCBAs = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAvailableNotCredentialedBCBAs
        builder.addCase(getAvailableNotCredentialedBCBAs.pending, (state) => {
            state.loadingGetAvailableBCBAs = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAvailableNotCredentialedBCBAs.fulfilled, (state, action) => {
            state.loadingGetAvailableBCBAs = false;
            state.notCredentialedBCBAs = action.payload;
        })
        builder.addCase(getAvailableNotCredentialedBCBAs.rejected, (state, action) => {
            state.loadingGetAvailableBCBAs = false;
            state.error = action.payload as ErrorProperties;
        })
        //getAvailableClientBTs
        builder.addCase(getAvailableClientBTs.pending, (state) => {
            state.loadingGetAvailableBTs = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getAvailableClientBTs.fulfilled, (state, action) => {
            state.loadingGetAvailableBTs = false;
            state.availableBTs = action.payload;
        })
        builder.addCase(getAvailableClientBTs.rejected, (state, action) => {
            state.loadingGetAvailableBTs = false;
            state.error = action.payload as ErrorProperties;
        })
        //get therapists
        builder.addCase(getTherapists.pending, (state) => {
            state.loading = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getTherapists.fulfilled, (state, action) => {
            state.loading = false;
            state.therapists = action.payload;
        })
        builder.addCase(getTherapists.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
        //get users clients
        builder.addCase(getUserClients.pending, (state) => {
            state.loadingGetUserClients = true;
            state.error = initialUserClientState.error;
        })
        builder.addCase(getUserClients.fulfilled, (state, action) => {
            state.loadingGetUserClients = false;
            state.userClients = action.payload;
        })
        builder.addCase(getUserClients.rejected, (state, action) => {
            state.loadingGetUserClients = false;
            state.error = action.payload as ErrorProperties;
        })
    }
})

export const { setBillingUserId } = userClientSlice.actions;
export default userClientSlice.reducer;