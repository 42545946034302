import { ChangeEvent, useState } from "react";
import { CustomInput } from "../../../shared/uiComponents";
import { InfoOutlined } from "@mui/icons-material";

const SIGNATURE_INFO_TEXT = `Signatures for this form may be executed
by the user checking a signature box. The
effect of checking the signature box shall
be binding to the same extent, and shall
have the same effect, as with a manual
signature`;

const SignatureInput = ({
  disabled,
  onBlur,
  setValue,
  value,
  error,
}: {
  value: string;
  setValue: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur: () => void;
  disabled: boolean;
  error: boolean;
}) => {
  const [showInfo, setShowInfo] = useState<boolean>(false);

  const toggleSignatureInfoHandler = () => {
    setShowInfo((prev) => !prev);
  };

  return (
    <div style={{ position: "relative" }}>
      <CustomInput
        label="Signature:"
        value={value}
        setValue={setValue}
        className="marginBottom8 signature"
        error={error}
        disabled={disabled}
        onBlur={onBlur}
        EndAdornment={{
          Icon: InfoOutlined,
          onClickEndAdornment: toggleSignatureInfoHandler,
        }}
        description={showInfo ? SIGNATURE_INFO_TEXT : ""}
      />
    </div>
  );
};

export default SignatureInput;
