import { ChangeEvent, useCallback, useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  CustomButton,
  CustomCheckBox,
  CustomTable,
} from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { Headers, TableBody } from "./tableInfo";
import { downloadFile, ReportFilters } from "../helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { GetBillingProperties } from "../../../redux/API/ClientAPIHelpers/billingProperties";
import {
  downloadLimitedPermitSessions,
  getLimitedPermitSessions,
  setOnlyNew,
} from "../../../redux/State/clientSlice/billingSlice";

const LimitedPermit = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const sessions = useSelector((state) => state.billing.lpSessions);
  const loading = useSelector((state) => state.billing.loading);
  const filterData = useSelector((state) => state.billing.billingFilter);

  const fetchData = useCallback(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const { clientId, endDate, startDate, userId, onlyNew } = filterData;

    if (!startDate || !endDate) return;
    const data: GetBillingProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      onlyNew,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
    };

    dispatch(getLimitedPermitSessions(data))
      .then(unwrapResult)
      .catch(errorNormalizer);
  }, [dispatch, params, filterData]);

  const downloadHandler = useCallback(() => {
    const page = params.get("page") || "1";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const pageSize = !!sessions.totalNumberOfItems
      ? sessions.totalNumberOfItems.toString()
      : "8";
    const { clientId, endDate, startDate, userId, onlyNew } = filterData;

    if (!startDate || !endDate) return;

    const data: GetBillingProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      onlyNew,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
    };
    dispatch(downloadLimitedPermitSessions(data))
      .then(unwrapResult)
      .then((response) => {
        downloadFile(response);
        fetchData();
      })
      .catch(errorNormalizer);
  }, [dispatch, fetchData, params, sessions, filterData]);

  const onlyNewHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    dispatch(setOnlyNew(checked));
  };

  useEffect(() => {
    const timeout = setTimeout(fetchData, 1000);
    return () => clearTimeout(timeout);
  }, [fetchData]);

  return (
    <>
      <ReportFilters showUsers={true} />
      <div style={{ display: "flex", maxWidth: "720px", gap: "16px" }}>
        <CustomCheckBox
          item={{
            label: "Show only new",
            id: "0",
            checked: filterData.onlyNew,
            disabled: loading,
          }}
          onChange={onlyNewHandler}
        />
        <CustomButton
          onClick={downloadHandler}
          title={"Download"}
          loading={loading}
        />
      </div>
      <CustomTable
        headers={Headers}
        TableBody={TableBody}
        data={sessions}
        loading={loading}
        hasMobile={false}
      />
    </>
  );
};

export default LimitedPermit;
