import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import "../reportStyles.scss";
import { CustomButton, CustomCheckBox } from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { ReportFilters } from "../helpers";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getClientGraphs } from "../../../redux/State/exportSlice";

const GraphReports = () => {
  const dispatch = useDispatch<DispatchProperties>();

  const [includeTargets, setIncludeTargets] = useState<boolean>(true);
  const [includeBehaviors, setIncludeBehaviors] = useState<boolean>(false);

  const filterData = useSelector((state) => state.billing.billingFilter);
  const loading = useSelector((state) => state.export.loading);

  const handleDownloadGraph = () => {
    const { clientId, startDate, endDate } = filterData;

    if (!clientId || !startDate || !endDate) return;

    dispatch(
      getClientGraphs({
        clientId,
        startDate,
        endDate,
        includeTargets,
        includeBehaviors,
      })
    )
      .then(unwrapResult)
      .then((response) => {
        const objectURL = URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = objectURL;
        link.setAttribute(
          "download",
          `Graph Reports - ${startDate} - ${endDate}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(errorNormalizer);
  };

  const includeBehaviorsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeBehaviors(checked);
  };

  const includeTargetsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIncludeTargets(checked);
  };

  return (
    <>
      <ReportFilters showUsers={false} />
      <div style={{ display: "flex", maxWidth: "720px", gap: "16px" }}>
        <CustomCheckBox
          item={{
            label: "Include Targets",
            id: "0",
            checked: includeTargets,
            disabled: loading,
          }}
          onChange={includeTargetsHandler}
        />
        <CustomCheckBox
          item={{
            label: "Include Behaviors",
            id: "0",
            checked: includeBehaviors,
            disabled: loading,
          }}
          onChange={includeBehaviorsHandler}
        />
        <div style={{ width: "150px" }}>
          <CustomButton
            title={"Download"}
            className={"marginRight16"}
            loading={loading}
            onClick={handleDownloadGraph}
            disabled={!filterData.clientId}
          />
        </div>
      </div>
    </>
  );
};

export default GraphReports;
