import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../API/Main/API";
import { GetSkillAreaProperties, SkillAreaProperties, SkillProperties } from "../../API/ClientAPIHelpers/skillAreaProperties";
import { ErrorProperties } from "../../API/identityAPIProperties";

interface ClientSkillAreaStateProperties {
    loading: boolean;
    skillAreas: Array<SkillProperties> | null;
    skillAreaId: string;
    error: ErrorProperties;
}

const initialSkillAreaState: ClientSkillAreaStateProperties = {
    loading: false,
    skillAreas: null,
    skillAreaId: "",
    error: {
        status: 0,
        title: ""
    },
};

export const getSkillAreas = createAsyncThunk(
    '/client/skillAreas',
    async (data: GetSkillAreaProperties, thunkAPI) => {
        const response = await API.ClientAPI.SkillArea.getSkillAreas(data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as Array<SkillProperties>;
    }
)

export const addSkillAreas = createAsyncThunk(
    '/client/skillAreas/add',
    async (data: SkillAreaProperties, thunkAPI) => {
        const response = await API.ClientAPI.SkillArea.addSkillArea(data.clientId, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response as SkillProperties;
    }
)

export const copySkillArea = createAsyncThunk(
    '/client/skillAreas/copy',
    async ({ clientId, id }: { clientId: string, id: string }, thunkAPI) => {
        const response = await API.ClientAPI.SkillArea.copySkillArea(clientId, id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const updateSkillAreas = createAsyncThunk(
    '/client/skillAreas/update',
    async ({ data, id }: { data: SkillAreaProperties, id: string }, thunkAPI) => {
        const response = await API.ClientAPI.SkillArea.updateSkillArea(data.clientId, id, data);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const deleteSkillArea = createAsyncThunk(
    '/client/skillAreas/delete',
    async ({ clientId, id }: { clientId: string, id: string }, thunkAPI) => {
        const response = await API.ClientAPI.SkillArea.deleteSkillArea(clientId, id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const archiveSkillArea = createAsyncThunk(
    '/client/skillAreas/archive',
    async ({ clientId, id }: { clientId: string, id: string }, thunkAPI) => {
        const response = await API.ClientAPI.SkillArea.archiveSkillArea(clientId, id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

export const unArchiveSkillArea = createAsyncThunk(
    '/client/skillAreas/unarchive',
    async ({ clientId, id }: { clientId: string, id: string }, thunkAPI) => {
        const response = await API.ClientAPI.SkillArea.unarchiveSkillArea(clientId, id);
        if (!!response.error) {
            return thunkAPI.rejectWithValue(response.error);
        }
        return response;
    }
)

const SkillAreaSlice = createSlice({
    name: "skillArea",
    initialState: initialSkillAreaState,
    reducers: {
        clearSkillAreas(state) {
            state.skillAreas = initialSkillAreaState.skillAreas;
            state.skillAreaId = initialSkillAreaState.skillAreaId;
        },
        setSkillAreaId(state, action) {
            state.skillAreaId = action.payload;
        }
    },
    extraReducers: (builder) => {
        //get skill areas
        builder.addCase(getSkillAreas.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getSkillAreas.fulfilled, (state, action) => {
            const skillAreas = action.payload;
            state.loading = false;
            state.skillAreas = skillAreas;
            if (!skillAreas.length || !!state.skillAreaId) return;
            const id = skillAreas[0].id;
            state.skillAreaId = id;
        })
        builder.addCase(getSkillAreas.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //add skill areas
        builder.addCase(addSkillAreas.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(addSkillAreas.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(addSkillAreas.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //copy skill areas
        builder.addCase(copySkillArea.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(copySkillArea.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(copySkillArea.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //update skill areas
        builder.addCase(updateSkillAreas.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(updateSkillAreas.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(updateSkillAreas.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })

        //delete skill areas
        builder.addCase(deleteSkillArea.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(deleteSkillArea.fulfilled, (state) => {
            state.loading = false;
        })
        builder.addCase(deleteSkillArea.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload as ErrorProperties;
        })
    }
})

export const { clearSkillAreas, setSkillAreaId } = SkillAreaSlice.actions;
export default SkillAreaSlice.reducer;