import { PaginationProperties, TableDataProperties } from "../../../shared/uiComponents/Table/tableProperties";

import { InfoClientProperties } from "./clientProperties";
import {
    SessionTypeProperties,
    UserBriefInfoProperties
} from "./calendarProperties";
import { ErrorProperties } from "../identityAPIProperties";
import { InsuranceAuthorizationTypeProperties } from "./insuranceAuthorizationProperties";

export interface ApprovePendingSoapNoteProperties {
    reportId: string;
    signature: string;
}

export interface ApprovePendingSoapNotesProperties extends GetPendingSoapNotesProperties {
    signature: string;
}

export interface GetPendingSoapNotesProperties extends GetSoapNotesProperties {
    includeApproved?: boolean;
}

export interface GetSoapNotesProperties extends PaginationProperties {
    status?: string,
    startDate?: string;
    endDate?: string;
    clientId?: string;
    userId?: string
}

export interface DSUSoapNoteProperties extends SoapNotesFullProperties {
    supervisedSessionStatus: StatusProperties;
}

export interface GetDSUSoapNotesProperties extends GetSoapNotesProperties {
    supervisedStatus?: string;
}

export interface GetSoapNoteProperties {
    clientId: string;
    reportId: string;
}

export interface CreateSoapNoteProperties {
    clientId: string;
    sessionId: string;
}

export interface UnlockSoapNoteProperties extends GetSoapNoteProperties {
    nextExpirationDate: string;
}

export interface StatusProperties {
    status: number,
    name: string
}

export interface LocationTypes {
    id: string;
    name: string;
    locationType: number;
    telehealth: boolean;
}

export interface TelehealthTypes {
    id: string;
    name: string;
    type: number;
}

export enum TelehealthLocationTypes {
    "Client" = 1,
    "User" = 2,
}

export interface InitialSoapNoteStateProperties {
    loading: boolean;
    behaviorTreatmentOptions: BehaviorTreatmentOptionsProperties;
    behaviorTreatmentModificationOptions: BehaviorTreatmentModificationOptionsProperties;
    directSupervisionOptions: DirectSupervisionOptionsProperties;
    assessmentOptions: AssessmentOptionsProperties;
    parentTrainingOptions: ParentTrainingOptionsProperties;
    treatmentPlanningOptions: TreatmentPlanningOptionsProperties;
    locations: Array<LocationTypes> | null;
    telehealtLocations: Array<TelehealthTypes>;
    soapNote:
    BehaviorTreatmentProperties |
    DirectSupervisionProperties |
    AssessmentProperties |
    BehaviorTreatmentModificationProperties |
    ParentTrainingProperties |
    TreatmentPlanningProperties | any;
    soapNotes: SoapNotesDataProperties;
    dsuSoapNotes: SoapNotesDataProperties;
    soapNotesDrafts: SoapNotesDataProperties;
    pendingSoapNotes: SoapNotesApprovalDataProperties;
    error: ErrorProperties;
    soapNoteData: {
        supervisor: {
            id: string;
            fullName: string;
            role: string;
            authoritationType: InsuranceAuthorizationTypeProperties
        } | null
    }
}

export interface SoapNotesFullProperties {
    id: string,
    reportType: string,
    sessionType: SessionTypeProperties,
    sessionId: string,
    date: string,
    startTime: string,
    endTime: string,
    createdBy: UserBriefInfoProperties,
    createdAt: string,
    status: StatusProperties,
    submittedBy: UserBriefInfoProperties | null,
    submittedAt: string | null,
    user: UserBriefInfoProperties,
    client: InfoClientProperties,
    location: LocationTypes,
    clientTelehealthLocation: LocationTypes,
    userTelehealthLocation: LocationTypes,
}

export interface SoapNotesBehaviorTreatmentFullProperties extends SoapNotesFullProperties {
    supervisor: UserBriefInfoProperties,
    behaviorTechName: string,
}

export interface SoapNotesDataProperties extends TableDataProperties {
    query: Array<SoapNotesFullProperties> | null;
}

export interface OptionProperties {
    id: string | number;
    text: string;
}

export interface ParticipantOptionsProperties {
    id: string;
    text: string;
    isDefault: boolean;
}

export interface UpdateBillingCredentialedProperties {
    userId: string,
    startDate: string;
    clientIds: Array<string>;
}

export interface GetExcelReportProperties {
    startDate?: string | null;
    endDate?: string | null;
    userId?: string | null;
    clientId?: string | null;
}

export interface SoapNotesApprovalProperties extends SoapNotesFullProperties {
    approvedAt: string | null,
    approvedBy: string | null,
    isApproved: boolean,
}

export interface SoapNotesApprovalDataProperties extends TableDataProperties {
    query: Array<SoapNotesApprovalProperties> | null;
}

interface DataProperties {
    submit: boolean;
    date: string;
    startTime: string;
    endTime: string;
    locationId: string | null;
    sessionSummary: string;
    printedName: string;
    signature: string;
    forcedOperation: boolean;
    clientTelehealthLocationId: string;
    userTelehealthLocationId: string;
    sessionId: string;
}

interface IdDataProperties {
    reportId: string;
    clientId: string;
}

interface AssessmentDataProperties extends DataProperties {
    dataCollected: string;
    goalsCreated: string;
    assessmentParticipantOther: string;
    assessmentActivityOther: string;
}

export interface UpdateAssessmentProperties
    extends AssessmentDataProperties,
    IdDataProperties {
    participantResponses: Array<string>;
    assessmentToolResponses: Array<string>;
    treatmentPlanResponses: Array<string>;
    assessmentActivityResponses: Array<string>;
}

export interface AssessmentOptionsProperties {
    assessmentParticipantOptions: Array<ParticipantOptionsProperties>;
    assessmentToolOptions: Array<OptionProperties>;
    treatmentPlanOptions: Array<OptionProperties>;
    assessmentActivityOptions: Array<OptionProperties>;
}

export interface AssessmentProperties
    extends SoapNotesFullProperties,
    AssessmentDataProperties {
    participantResponses: Array<OptionProperties>;
    assessmentToolResponses: Array<OptionProperties>;
    treatmentPlanResponses: Array<OptionProperties>;
    assessmentActivityResponses: Array<OptionProperties>;
}

interface BehaviorTreatmentModificationDataProperties extends DataProperties {
    responseToIntervention: number | null;
    dataAnalyzed: string;
    goalsAddressed: string;
    treatmentPlanUpdates: string;
    maladaptiveBehaviorsObserved: string;
    barrierToTreatmentOther: string;
}

export interface UpdateBehaviorTreatmentModificationProperties
    extends BehaviorTreatmentModificationDataProperties,
    IdDataProperties {
    behaviorTreatmentModificationInterventionResponses: Array<string>;
    reinforcementResponses: Array<string>;
    barrierToTreatmentResponses: Array<string>;
    behaviorTreatmentModificationResponses: Array<string>;
    participantResponses: Array<string>;
    sessionId: string;
}

export interface BehaviorTreatmentModificationOptionsProperties {
    behaviorTreatmentModificationInterventionOptions: Array<OptionProperties>;
    reinforcementOptions: Array<OptionProperties>;
    barrierToTreatmentOptions: Array<OptionProperties>;
    responseToInterventionOptions: Array<OptionProperties>;
    behaviorTreatmentModificationParticipantOptions: Array<ParticipantOptionsProperties>;
}

export interface BehaviorTreatmentModificationProperties
    extends SoapNotesFullProperties,
    BehaviorTreatmentModificationDataProperties {
    behaviorTreatmentModificationInterventionResponses: Array<OptionProperties>;
    reinforcementResponses: Array<OptionProperties>;
    barrierToTreatmentResponses: Array<OptionProperties>;
    participantResponses: Array<OptionProperties>;
}

interface BehaviorTreatmentDataProperties extends DataProperties {
    supervisorId: string;
    supervisor: UserBriefInfoProperties;
    notes: string;
    maladaptiveBehaviors: string;
    responseToIntervention: number | null;
    barrierToTreatmentOther: string;
    frequencyAndDurationOfMaladaptiveBehaviors: string;
    isSessionSupervised: boolean;
    sessionDescription: string;
    howBarrierAffect: string;
    workedGoals: string;
    clientResponseToTreatment: number | null;
    levelOfMaladaptiveBehavior: number | null;
    clientMoodOther: string;
    behaviorTreatmentFuturePlanOther: string;
    reinforcementOther: string;
}

export interface UpdateBehaviorTreatmentProperties
    extends BehaviorTreatmentDataProperties,
    IdDataProperties {
    barrierToTreatmentResponses: Array<string>;
    behaviorTreatmentTechniquesResponses: Array<string>;
    skillDomainAddressedResponses: Array<string>;
    promptLevelsResponses: Array<string>;
    reinforcementResponses: Array<string>;
    maladaptiveBehaviorTechniquesResponses: Array<string>;
    clientMoodResponses: Array<string>;
    behaviorTreatmentFuturePlanResponses: Array<string>;
    participantResponses: Array<string>;
}

export interface BehaviorTreatmentProperties
    extends SoapNotesFullProperties,
    BehaviorTreatmentDataProperties {
    signedAt: string | null;
    sessionDescription: string;
    howBarrierAffect: string;
    workedGoals: string;
    barrierToTreatmentResponses: Array<OptionProperties>;
    behaviorTreatmentTechniquesResponses: Array<OptionProperties>;
    promptLevelsResponses: Array<OptionProperties>;
    reinforcementResponses: Array<OptionProperties>;
    skillDomainAddressedResponses: Array<OptionProperties>;
    maladaptiveBehaviorTechniquesResponses: Array<OptionProperties>;
    clientMoodResponses: Array<OptionProperties>;
    behaviorTreatmentFuturePlanResponses: Array<OptionProperties>;
    participantResponses: Array<OptionProperties>;
}

export interface BehaviorTreatmentOptionsProperties {
    barrierToTreatmentOptions: Array<OptionProperties>;
    behaviorTreatmentFuturePlanOptions: Array<OptionProperties>;
    behaviorTreatmentTechniqueOptions: Array<OptionProperties>;
    clientMoodOptions: Array<OptionProperties>;
    clientResponseToTreatmentOptions: Array<OptionProperties>;
    levelOfMaladaptiveBehaviorOptions: Array<OptionProperties>;
    maladaptiveBehaviorTechniqueOptions: Array<OptionProperties>;
    promptLevelOptions: Array<OptionProperties>;
    reinforcementOptions: Array<OptionProperties>;
    skillDomainAddressedOptions: Array<OptionProperties>;
    behaviorTreatmentParticipantOptions: Array<ParticipantOptionsProperties>;
}

interface DirectSupervisionDataProperties extends DataProperties {
    supervisorId: string;
    behaviorTechName: string;
    promptedEffectively: boolean;
    isReinforcerImplementationRight: boolean;
    dataEnteredCorrectly: boolean;
    implementationOfGoal: string;
    responseToBehavioralFeedback: string;
    clientPerformance: string;
    changesAndMeasurementsObserved: string;
    descriptionOfSession: string;
    supervisedSessionId: string | null;
    feedbackOther: string;
}

export interface UpdateDirectSupervisionProperties
    extends DirectSupervisionDataProperties,
    IdDataProperties {
    feedbackResponses: Array<string>;
    participantResponses: Array<string>;
}

export interface DirectSupervisionOptionsProperties {
    feedbackOptions: Array<OptionProperties>;
    directSupervisionParticipantOptions: Array<ParticipantOptionsProperties>;
}

export interface DirectSupervisionProperties
    extends SoapNotesFullProperties,
    DirectSupervisionDataProperties {
    feedbackResponses: Array<OptionProperties>;
    participantResponses: Array<OptionProperties>;
}

interface ParentTrainingDataProperties extends DataProperties {
    parentFollowing: boolean;
    antecedent: string;
    behavior: string;
    consequence: string;
    behavioralMethodsUsed: string;
    goalsAddressed: string;
    barriersToTreatment: string;
    parentResponseToIntervention: string;
}

export interface UpdateParentTrainingProperties
    extends ParentTrainingDataProperties,
    IdDataProperties {
    participantResponses: Array<string>;
    directParentTrainingResponses: Array<string>;
}

export interface ParentTrainingProperties
    extends SoapNotesFullProperties,
    ParentTrainingDataProperties {
    participantResponses: Array<OptionProperties>;
    directParentTrainingResponses: Array<OptionProperties>;
}

export interface ParentTrainingOptionsProperties {
    parentTrainingParticipantOptions: Array<ParticipantOptionsProperties>;
    directParentTrainingOptions: Array<OptionProperties>;
}

interface TreatmentPlanningDataProperties extends DataProperties {
    masteredGoalsAndObjectives: string;
    newGoalsAndObjectives: string;
    analysisOfGoals: string;
    goalUpdates: string;
    coordinationAndMaterialsPrepped: string;
    descriptionOfSession: string;
    treatmentPlanningParticipantOther: string;
    treatmentUpdateOther: string;
}

export interface UpdateTreatmentPlanningProperties
    extends TreatmentPlanningDataProperties,
    IdDataProperties {
    participantResponses: Array<string>;
    treatmentUpdateResponses: Array<string>;
}

export interface TreatmentPlanningProperties
    extends SoapNotesFullProperties,
    TreatmentPlanningDataProperties {
    participantResponses: Array<OptionProperties>;
    treatmentUpdateResponses: Array<OptionProperties>;
}

export interface TreatmentPlanningOptionsProperties {
    treatmentPlanningParticipantOptions: Array<ParticipantOptionsProperties>;
    treatmentUpdateOptions: Array<OptionProperties>;
}