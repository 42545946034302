import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import {
  CustomAccordion,
  CustomTabs,
  Text,
} from "../../../shared/uiComponents";
import { useScreenWidth } from "../../../shared/Helpers/hooks";
import { Container } from "../../../components/StyledComponents";
import CredentialedBCBA from "./credentialedBCBA";
import NotCredentialedBCBA from "./notCredentialedBCBA";
import DefaultBCBA from "./defaultBCBA";
import BT from "./BT";
import SaveChanges from "./saveChanges";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { getClient } from "../../../redux/State/clientSlice/clientSlice";
import { getTherapists } from "../../../redux/State/clientSlice/userClientSlice";

const tabHeaders = [`BCBA - credentialed`, `BCBA - not credentialed`, `BT`];
const panels = [CredentialedBCBA, NotCredentialedBCBA, BT];

const Assign = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const screenWidth = useScreenWidth();
  const { clientId } = useParams();

  const therapists = useSelector((state) => state.userClient.therapists);
  const clientInfo = useSelector((state) => state.client.client);

  useEffect(() => {
    if (!!therapists || !clientId) return;
    dispatch(getTherapists(clientId));
  }, [clientId, therapists, dispatch]);

  useEffect(() => {
    if (!clientId) return;
    if (!!clientInfo.fullName) return;
    dispatch(getClient(clientId));
  }, [clientId, clientInfo, dispatch]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
        className="marginBottom16"
      >
        <Text
          title={`Assign Providers for ${clientInfo.fullName}`}
          size="largeBold"
        />
        <DefaultBCBA />
      </div>
      {screenWidth > 768 ? (
        <CustomTabs headers={tabHeaders} panels={panels} />
      ) : (
        tabHeaders.map((header, index) => (
          <CustomAccordion
            key={index}
            HeaderContent={() => <Text title={header} size="smallBold" />}
            Content={panels[index]}
          />
        ))
      )}
      <SaveChanges />
    </Container>
  );
};

export default Assign;
