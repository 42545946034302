import { useEffect } from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { CustomButton, CustomTable } from "../../../shared/uiComponents";
import { errorNormalizer } from "../../../shared/Helpers/functions";
import { Container } from "../../../components/StyledComponents";
import { downloadFile, ReportFilters } from "../../Reports/helpers";
import SoapNotesHeader from "../soapNotesHeader";
import { Headers, TableBody } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  downloadDSUSoapNotes,
  getDSUSoapNotes,
} from "../../../redux/State/clientSlice/soapNoteSlice";
import { GetDSUSoapNotesProperties } from "../../../redux/API/ClientAPIHelpers/soapNoteProperties";

const DSUNotes = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();

  const notes = useSelector((state) => state.soapNote.dsuSoapNotes);
  const loading = useSelector((state) => state.soapNote.loading);
  const filterData = useSelector((state) => state.billing.billingFilter);

  useEffect(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const status = params.get("status") || "";
    const supervisedStatus = params.get("supervisedStatus") || "";
    const { clientId, endDate, startDate, userId } = filterData;

    if (!startDate || !endDate) return;
    const data: GetDSUSoapNotesProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
      status,
      supervisedStatus,
    };

    dispatch(getDSUSoapNotes(data)).then(unwrapResult).catch(errorNormalizer);
  }, [dispatch, params, filterData]);

  const donwloadDSUNotesHandler = () => {
    const page = params.get("page") || "1";
    const pageSize = !!notes.totalNumberOfItems
      ? notes.totalNumberOfItems.toString()
      : "8";
    const direction = params.get("direction") || "";
    const orderBy = params.get("orderBy") || "";
    const searchString = params.get("searchString") || "";
    const status = params.get("status") || "";
    const supervisedStatus = params.get("supervisedStatus") || "";
    const { clientId, endDate, startDate, userId } = filterData;

    if (!startDate || !endDate) return;
    const data: GetDSUSoapNotesProperties = {
      page,
      pageSize,
      startDate,
      endDate,
      direction,
      orderBy,
      searchString,
      userId,
      clientId,
      status,
      supervisedStatus,
    };

    dispatch(downloadDSUSoapNotes(data))
      .then(unwrapResult)
      .then((response) => downloadFile(response))
      .catch(errorNormalizer);
  };

  return (
    <Container>
      <SoapNotesHeader />
      <ReportFilters showUsers={true} showShorcuts={false} />
      <div style={{ width: "350px" }} className="marginBottom16">
        <CustomButton
          onClick={donwloadDSUNotesHandler}
          title={"Download"}
          loading={loading}
        />
      </div>
      <CustomTable
        headers={Headers}
        TableBody={TableBody}
        data={notes}
        loading={loading}
        hasMobile={false}
      />
    </Container>
  );
};

export default DSUNotes;
