import { useEffect, useState } from "react";
import { InfoOutlined } from "@mui/icons-material";

import { CustomAlert, CustomTable, Text } from "../../shared/uiComponents";
import { green200, red300 } from "../../shared/Helpers/colors";
import { TableBodyAdmin } from "./tableInfo";

import { DispatchProperties, useSelector } from "../../redux/store";
import { useDispatch } from "react-redux";
import { getAuthorizationInfoAdmin } from "../../redux/State/clientSlice/userClientSlice";
import { useSearchParams } from "react-router-dom";

const AdminAuthorizationInfo = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const [params] = useSearchParams();
  const [open, setOpen] = useState<boolean>(false);

  const loading = useSelector((state) => state.userClient.loading);
  const authorization = useSelector(
    (state) => state.userClient.authorizationAdmin
  );

  useEffect(() => {
    const page = params.get("page") || "1";
    const pageSize = params.get("pageSize") || "8";
    dispatch(
      getAuthorizationInfoAdmin({
        page,
        pageSize,
      })
    );
  }, [params, dispatch]);

  return authorization.query?.length ? (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text size="mediumBold" title={"Authorizations Dashboard"} />
        <InfoOutlined onClick={() => setOpen(true)} />
        <CustomAlert
          open={open}
          onClose={() => setOpen(false)}
          title="Details for authorization period"
          Content={() => (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Text
                title={"Expired"}
                style={{
                  backgroundColor: red300,
                  width: "max-content",
                  padding: "8px 16px",
                  borderRadius: "12px",
                  marginBottom: "8px",
                }}
              />
              <Text
                title={"Expires in 3 month"}
                style={{
                  backgroundColor: "#FFFF5C",
                  width: "max-content",
                  padding: "8px 16px",
                  borderRadius: "12px",
                  marginBottom: "8px",
                }}
              />
              <Text
                title={"Active"}
                style={{
                  backgroundColor: green200,
                  width: "max-content",
                  padding: "8px 16px",
                  borderRadius: "12px",
                  marginBottom: "8px",
                }}
              />
            </div>
          )}
        />
      </div>
      <CustomTable
        headers={[
          { id: "1", name: "Client Name" },
          { id: "2", name: "Authorization Expiration Date" },
        ]}
        data={authorization}
        TableBody={TableBodyAdmin}
        loading={loading}
      />
    </div>
  ) : (
    <></>
  );
};

export default AdminAuthorizationInfo;
