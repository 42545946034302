import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { errorNormalizer } from "../../../../shared/Helpers/functions";
import { CustomButton } from "../../../../shared/uiComponents";
import { AdminTypes } from "../../../../components/Menu";
import { useResetCalendarData } from "../../Helpers/hooks";
import { SessionInfoButtonsWrapper } from "./sessionDate";
import DeleteSession from "./deleteSession";

import { DispatchProperties, useSelector } from "../../../../redux/store";
import { startEventSession } from "../../../../redux/State/clientSlice/sessionSlice";
import { useState } from "react";

const SessionActions = () => {
  const dispatch = useDispatch<DispatchProperties>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { resetData } = useResetCalendarData();

  const [loading, setLoading] = useState<boolean>(false);

  const role = useSelector((state) => state.account.role);
  const days = useSelector((state) => state.calendar.displayedCalendarPageDays);
  const data = useSelector((state) => state.calendar.infoEventData);

  const isAdmin = !!role && role.section.id === AdminTypes.admin;
  const isBT = !!role && role.section.id === AdminTypes.bt;

  const collectDataHandler = () => {
    if (!data) return;
    const { sessionId, client } = data;
    resetData();
    navigate(`${pathname}/session/${sessionId}/${client.id}`, {
      state: data,
    });
  };

  const startSessionHandler = () => {
    if (!data || !data.id) return;
    setLoading(true);
    dispatch(startEventSession(data.id))
      .then(unwrapResult)
      .then((response) => {
        if (!response.id || !response.client.id || !days.length) return;
        resetData();
        if (isBT) {
          navigate(`${pathname}/session/${response.id}/${response.client.id}`, {
            state: data,
          });
        }
      })
      .catch(errorNormalizer)
      .finally(() => setLoading(false));
  };

  return (
    <SessionInfoButtonsWrapper>
      {!!data && !data.isCompleted ? (
        !!data.sessionId ? (
          <CustomButton
            title="Collect Data"
            secondaryButton
            onClick={collectDataHandler}
          />
        ) : (
          <CustomButton
            title={isBT ? "Start Session" : "Collect Data"}
            onClick={startSessionHandler}
            loading={loading}
          />
        )
      ) : (
        <CustomButton
          title="Session Ended"
          disabled={!isAdmin}
          onClick={collectDataHandler}
        />
      )}
      <DeleteSession resetData={resetData} />
    </SessionInfoButtonsWrapper>
  );
};

export default SessionActions;
