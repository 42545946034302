import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AddOutlined as Add,
  DeleteOutline as Delete,
  ContentCopyOutlined as Copy,
  EditOutlined as Draft,
  DoneAllOutlined as Submitted,
  LockClockOutlined as Expired,
  Done as SessionDone,
  LockOpenOutlined as Unlocked,
  AlarmOffOutlined as TimeMismatch,
  PendingActionsOutlined as Pending,
} from "@mui/icons-material";
import dayjs from "dayjs";

import { Text } from "../../../shared/uiComponents";
import { useScreenWidth } from "../../../shared/Helpers/hooks";
import { white100 } from "../../../shared/Helpers/colors";
import { AdminTypes } from "../../../components/Menu";
import {
  findColor,
  findDuration,
  findEventColor,
  isPastTime,
  useShowBTsSessions,
} from "./functions";
import { DayProperties } from "./interfaces";

import { DispatchProperties, useSelector } from "../../../redux/store";
import {
  setAddEvent,
  setAdminAddEvent,
  setCopyEvent,
  setDeleteEvent,
  setInfoEvent,
  setInfoEventData,
} from "../../../redux/State/clientSlice/calendarSlice";
import { EventDataProperties } from "../../../redux/API/ClientAPIHelpers/calendarProperties";
import { SoapNotesStatuses } from "../../../redux/API/ClientAPIHelpers/dataCollectionProperties";

export const Session = ({
  event,
  className,
}: {
  event: EventDataProperties;
  className: string;
}) => {
  const {
    sessionType,
    startTime,
    client,
    endTime,
    isCompleted,
    user,
    reportStatus,
    reportId,
  } = event;
  const dispatch = useDispatch<DispatchProperties>();

  const userId = useSelector((state) => state.account.user.id);
  const opacity = useShowBTsSessions(user.id, userId);

  const onSessionClick = () => {
    dispatch(setInfoEvent(true));
    dispatch(setInfoEventData(event));
  };

  return (
    <div
      className={className}
      style={{
        backgroundColor: findEventColor(sessionType.type),
        opacity,
      }}
      onClick={onSessionClick}
    >
      <div className="tooltip">
        <Text title={client.fullName} />
        <Text
          title={`${dayjs(startTime).utc(false).format("hh:mm A")} - ${dayjs(
            endTime
          )
            .utc(false)
            .format("hh:mm A")}`}
        />
        <Text title={sessionType.name} />
        {!!reportStatus ? (
          <Text title={`Status: ${reportStatus.name}`} />
        ) : (
          <Text title={`Status: Report Not Created`} />
        )}
        {isCompleted && !!endTime && (
          <Text title={findDuration(startTime, endTime)} />
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Text
          title={`${client.fullName} - ${dayjs(startTime)
            .utc(false)
            .format("hh:mm A")} - ${dayjs(endTime)
            .utc(false)
            .format("hh:mm A")}`}
          textColor={white100}
          className="overflowText"
          onClick={() => {}} //activate cursor for pointer
        />
        {isCompleted && !reportId && (
          <SessionDone style={{ color: white100 }} fontSize="small" />
        )}
        {!!reportId && SoapNotesStatuses.draft === reportStatus?.status && (
          <Draft style={{ color: white100 }} />
        )}
        {!!reportId && SoapNotesStatuses.submitted === reportStatus?.status && (
          <Submitted style={{ color: white100 }} />
        )}
        {!!reportId &&
          (SoapNotesStatuses.expired48 === reportStatus?.status ||
            SoapNotesStatuses.expiredWeek === reportStatus?.status) && (
            <Expired style={{ color: white100 }} />
          )}
        {!!reportId && SoapNotesStatuses.unlocked === reportStatus?.status && (
          <Unlocked style={{ color: white100 }} />
        )}
        {!!reportId &&
          SoapNotesStatuses.timeMismatch === reportStatus?.status && (
            <TimeMismatch style={{ color: white100 }} />
          )}
        {!!reportId && SoapNotesStatuses.pendingBT === reportStatus?.status && (
          <Pending style={{ color: white100 }} />
        )}
      </div>
    </div>
  );
};

export const SessionHeader = ({
  item,
  index,
}: {
  item: DayProperties;
  index: number;
}) => {
  const today = new Date();

  const dispatch = useDispatch<DispatchProperties>();
  const screenWidth = useScreenWidth();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const role = useSelector((state) => state.account.role);
  const roleRestriction = useSelector((state) => state.account.roleRestriction);
  const filterData = useSelector((state) => state.calendar.filterData);

  const allowedAfterDate = new Date(item.year, item.month, item.day);
  allowedAfterDate.setDate(allowedAfterDate.getDate() + roleRestriction);
  const isPast = isPastTime(today, allowedAfterDate) && !isAdmin;
  const { color } = findColor({
    item,
    index,
    screenWidth,
  });

  useEffect(() => {
    if (!role) return;
    setIsAdmin(role.section.id === AdminTypes.admin);
  }, [role]);

  const openDeleteEventsHandler = () => {
    dispatch(setDeleteEvent(true));
  };

  const openCopyEventsHandler = () => {
    dispatch(setCopyEvent(true));
  };

  const openAddEventHandler = () => {
    dispatch(setAddEvent(true));
  };

  const openAdminAddEventHandler = () => {
    dispatch(setAdminAddEvent(true));
  };

  return (
    <div className={"dayHeader"}>
      <Text title={`${item.day}`} size={"tinyBold"} textColor={color} />
      {!filterData.client?.id && !filterData.therapist?.id ? (
        <>
          <div onClick={openDeleteEventsHandler}>
            <Delete style={{ color }} />
          </div>
          <div onClick={openCopyEventsHandler}>
            <Copy style={{ color }} />
          </div>
          {!isPast && (
            <div onClick={openAddEventHandler}>
              <Add style={{ color }} />
            </div>
          )}
        </>
      ) : (
        <div onClick={openAdminAddEventHandler}>
          <Add style={{ color }} />
        </div>
      )}
    </div>
  );
};
