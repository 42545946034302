import dayjs, { Dayjs } from "dayjs";
import { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  CustomButton,
  CustomCheckBox,
  Picker,
  Text,
} from "../../../shared/uiComponents";
import { ButtonWrapper } from "../../../components/StyledComponents";
import { black900, red300 } from "../../../shared/Helpers/colors";
import {
  dateNormalizer,
  errorNormalizer,
} from "../../../shared/Helpers/functions";

import { DispatchProperties, useSelector } from "../../../redux/store";
import { UpdateDefaultBCBAProperties } from "../../../redux/API/ClientAPIHelpers/userClientProperties";
import { updateDefaultBCBA } from "../../../redux/State/clientSlice/userClientSlice";
import { clearTherapists } from "../../../redux/State/clientSlice/clientSlice";

const today = dayjs();

const DefaultBCBAConfirm = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch<DispatchProperties>();
  const navigate = useNavigate();
  const { clientId } = useParams();

  const [userId, setUserId] = useState<string>("");
  const [startDateValue, setStartDateValue] = useState<Dayjs>(today);

  const loading = useSelector((state) => state.userClient.loading);
  const defaultBCBA = useSelector((state) => state.userClient.defaultBCBA);
  const defaultBCBAoptions = useSelector(
    (state) => state.userClient.defaultBCBAOptions
  );

  const updateDefaultBCBAHandler = () => {
    if (!userId || !clientId) return;
    const startDate = dateNormalizer(startDateValue);
    const endDate = dateNormalizer(today);
    const data: UpdateDefaultBCBAProperties = {
      clientId,
      data: { userId, startDate, endDate },
    };
    dispatch(updateDefaultBCBA(data))
      .then(unwrapResult)
      .then(() => {
        toast("Success");
        navigateToAllocateHandler();
      })
      .catch(errorNormalizer)
      .finally(() => onClose());
  };

  const startDateHandler = (date: Dayjs | null) => {
    if (!date) return;
    setStartDateValue(date);
  };

  const changeDefaultBCBAHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const { id } = event.target;
    setUserId(id);
  };

  const navigateToAllocateHandler = () => {
    if (!clientId) return;
    dispatch(clearTherapists());
    onClose();
    navigate(`/clients/client/allocate-authorization/${clientId}`);
  };

  return (
    <div>
      {defaultBCBAoptions?.map((x) => (
        <div style={{ display: "flex" }}>
          <CustomCheckBox
            item={{
              id: x.id,
              checked: !!userId ? userId === x.id : defaultBCBA?.id === x.id,
            }}
            onChange={changeDefaultBCBAHandler}
          />
          <Text
            title={x.fullName}
            textColor={defaultBCBA?.id === x.id ? red300 : black900}
          />
        </div>
      ))}
      <Picker.CustomDate
        label="Start Date"
        value={startDateValue}
        onChange={startDateHandler}
        disableFuture={true}
      />
      <ButtonWrapper className="marginTop16">
        <CustomButton
          title="Save"
          onClick={updateDefaultBCBAHandler}
          loading={loading}
          disabled={!userId || userId === defaultBCBA?.id}
        />
        <CustomButton
          secondaryButton
          title="Allocate Hours"
          onClick={navigateToAllocateHandler}
        />
      </ButtonWrapper>
    </div>
  );
};

export default DefaultBCBAConfirm;
