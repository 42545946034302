import { FetchAPI } from "../Main/fetchAPI";
import { CALENDAR_EVENTS, SESSIONS } from "../constants";
import { SessionDataProperties, UpdateSessionProperties } from "./sessionsProperties";

export const Sessions = {
    startEventSession: (eventId: string) =>
        FetchAPI.postJson({
            urlStr: `${CALENDAR_EVENTS}/${eventId}/start-session`,
        }),
    getSessionInfo: (sessionId: string) => FetchAPI.getJson({
        urlStr: `${SESSIONS}/${sessionId}`,
    }),
    getSupervisedSessionsInfo: (sessionId: string) => FetchAPI.getJson({
        urlStr: `${SESSIONS}/${sessionId}/supervised-sessions`,
    }),
    getSupervisorSessionsInfo: (sessionId: string) => FetchAPI.getJson({
        urlStr: `${SESSIONS}/${sessionId}/supervisor-sessions`,
    }),
    startSession: (data: SessionDataProperties) =>
        FetchAPI.postJson({
            urlStr: `${SESSIONS}/start-session`,
            data,
        }),
    // endSession: (sessionId: string) =>
    //     FetchAPI.postJson({
    //         urlStr: `${SESSIONS}/${sessionId}/end-session`,
    //     }),
    getSessionTypes: () =>
        FetchAPI.getJson({
            urlStr: `${SESSIONS}/types`,
        }),
    updateSession: (sessionId: string, data: UpdateSessionProperties) =>
        FetchAPI.putJson({
            urlStr: `${SESSIONS}/${sessionId}`,
            data
        }),
    activateSession: (sessionId: string) =>
        FetchAPI.postJson({
            urlStr: `${SESSIONS}/${sessionId}/activate`,
        }),
    createBehaviorTreatmentSession: (sessionId: string, userId: string) => {
        const query = new URLSearchParams();
        query.set('userId', userId);
        query.set('sessionId', sessionId);

        return FetchAPI.postJson({
            urlStr: `${SESSIONS}/behavior-treatment/supervised?${query.toString()}`,
        })
    },
}